import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const ComplianceCoachScorecardTableRow = ({ data, openModal, origin }) => {

  return (
    <tr>
      <td>
        <Link to={`/users/${data.CoachId}`}>{data.CoachName}</Link>
      </td>
      <td
        style={{ cursor: "pointer" }}
        onClick={() => {
          openModal({ type: "Total", coachId: data.CoachId, origin });
        }}
      >
        {data.TotalPatientsCount}
      </td>

      <td
        style={{ cursor: "pointer" }}
        onClick={() => {
          openModal({ type: "Compliant", coachId: data.CoachId, origin });
        }}
      >
        {data.CompliantPatientsCount}
      </td>

      <td
        style={{ cursor: "pointer" }}
        onClick={() => {
          openModal({ type: "Non-Compliant", coachId: data.CoachId, origin });
        }}
      >
        {data.NonCompliantPatientsCount}
      </td>


      <td
        style={{ cursor: "pointer" }}
        onClick={() => {
          openModal({ type: "In Progress", coachId: data.CoachId, origin });
        }}
      >
        {data.CompliantNullPatientsCount}
      </td>

      <td
        style={{ cursor: "pointer" }}
        onClick={() => {
          openModal({ type: "Resupply", coachId: data.CoachId, origin });
        }}
      >
        {data.ResupplyPatientsCount}
      </td>

      <td
        style={{ cursor: "pointer" }}
        onClick={() => {
          openModal({ type: "ResupplyOrders", coachId: data.CoachId, origin });
        }}
      >
        {data.OrderingPatientsCount}
      </td>

      <td>{Math.round(data.CompliancePercentage).toFixed(2)}</td>

      <td>{Math.round(data.AverageContactsCount).toFixed(2)}</td>

      <td>{Math.round(data.AverageComplianceDaysCount).toFixed(2)}</td>
    </tr>
  );
};

ComplianceCoachScorecardTableRow.propTypes = {
  data: PropTypes.array.isRequired,
  openModal: PropTypes.func.isRequired,
  origin: PropTypes.string.isRequired
};

export default ComplianceCoachScorecardTableRow;
