import React from "react";
import PropTypes from "prop-types";
import { useFormikContext, Field } from "formik";
import FormikField from "components/forms/formik-field";
import RadioGroupField from "components/forms/radio-group-field";
import PhoneNumberField from "components/forms/phone-number-field";
import { STATES } from "utils/enum";
import { isValidPhone, isValidZip, normalizePhone } from "utils/redux-form";
import FormElement from "./form-elements";
import { aLink } from "utils/styles";
import PatientPhysicianForm from "components/physician-search/patient-physician-form";
import { MdSearch } from "react-icons/lib/md";
import PureModal from "components/ui/modal/pure-modal";

const PhysicianForm = ({ physician, questions }) => {
  const { values, setFieldValue } = useFormikContext();

  // const last_saw_physician = questions.find(
  //   q => q.id === "last_seen_physician"
  // );
  const physician_change_question = questions.find(
    q => q.id === "physician_change"
  );

  const misc_physician_quesitons = questions.filter(
    x => x.category == "Physician" && x.id != "physician_change"
  );

  React.useEffect(() => {
    if (values.questions?.physician_change === false) {
      setFieldValue("physician", {
        ...physician,
        phone: normalizePhone(physician?.phone)
      });
    }
  }, [values.questions?.physician_change]);

  if (!physician_change_question) {
    return null;
  }
  const disabled = !values.questions?.physician_change;

  return (
    <div className="order-form-section">
      {misc_physician_quesitons.map(question => (
        <FormElement key={question.id} {...question} />
      ))}
      {physician_change_question && (
        <Field
          component={RadioGroupField}
          name="questions.physician_change"
          label={physician_change_question.text}
          value={values.questions.physician_change}
          options={[
            { value: true, text: "Yes" },
            { value: false, text: "No" }
          ]}
        />
      )}
      {values.questions?.physician_change == true && (
        <div
          style={{
            display: "flex",
            margin: 5,
            flex: 1,
            justifyContent: "space-between"
          }}
        >
          <div className="order-form-advice">
            Please enter as much information as you can.
          </div>
          <PureModal
            renderTrigger={({ openModal }) => (
              <span onClick={openModal} style={aLink}>
                <MdSearch /> Lookup Physician
              </span>
            )}
            renderContent={({ closeModal }) => (
              <PatientPhysicianForm
                physician={values.physician}
                updatePatientPhysician={values => {
                  setFieldValue("physician", values);
                  closeModal();
                }}
              />
            )}
          />
        </div>
      )}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: 10
        }}
      >
        <FormikField
          name="physician.name"
          label="My current sleep physician is"
          type="text"
          disabled={disabled}
        />
        <FormikField
          name="physician.npi"
          label="NPI #"
          type="text"
          disabled={disabled}
        />
      </div>
      <span>
        <FormikField
          label="Street Address"
          name="physician.address.street_address"
          type="text"
          disabled={disabled}
        />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridGap: 10
          }}
        >
          <FormikField
            label="City"
            name="physician.address.city"
            type="text"
            disabled={disabled}
          />
          <FormikField
            label="State"
            name="physician.address.state"
            component="select"
            disabled={disabled}
          >
            <option />
            {STATES.map(({ value, text }) => (
              <option key={value} value={value}>
                {text}
              </option>
            ))}
          </FormikField>
          <FormikField
            label="Zip"
            name="physician.address.zip"
            type="text"
            disabled={disabled}
            validate={isValidZip}
          />
        </div>
        <FormikField
          name="physician.phone"
          label="Phone Number:"
          type="tel"
          disabled={disabled}
          component={PhoneNumberField}
          validate={isValidPhone}
        />
        <FormikField
          name="physician.fax"
          label="Fax Number:"
          type="tel"
          disabled={disabled}
          component={PhoneNumberField}
        />
      </span>
    </div>
  );
};

export default PhysicianForm;

PhysicianForm.propTypes = {
  physician: PropTypes.object.isRequired,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired
};
