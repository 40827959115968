import React, { useEffect, useState, useContext } from "react";
import ComplianceTeamCoachesStatus from "./coaches/compliance-team-coaches-status";
import ComplianceTeamCoachContacts from "./coaches/contacts/index";
import { useFetch } from "hooks";
import { put } from "utils/api";
import { path } from "ramda";
import { errorMessage } from "actions/message";
import { Spinner } from "components/ui";
import ComplianceTeamDashboardContext from "./context";
import ComplianceCoachOrders from "./coach-orders/index";
import ComplianceTeamCoachLogins from "./coaches/compliance-team-coach-logins";

const ComplianceTeamCoaches = () => {
  const [coachesData, setCoachesData] = useState([]);
  const [coachContacts, setCoachContacts] = useState([]);
  const [coachOrders, setCoachOrders] = useState([]);
  const [coachesLoginData, setCoachesLoginData] = useState([]);
  
  const filters = useContext(ComplianceTeamDashboardContext);

  const { fetchData, isFetching } = useFetch({
    apiFn: () =>
      put(`compliance-team/coaches`, {
        TeamName: filters.selectedTeam,
        FromDate: filters.requestedDates.start_date,
        ToDate: filters.requestedDates.end_date
      }),
    defaultValue: {},
    transformError: path(["response", "body", "status"]),
    onError: err => errorMessage(`Failed to get coaches data: ${err}`)
  });

  const getUserData = async () => {
    const res = await fetchData();
    if (res) {
      setCoachesData(res);
    }
  };

  const { fetchData: fetchCoachContacts, isFetching: fetchingCoachContacts } =
    useFetch({
      apiFn: () =>
        put(`compliance-team/coach-contacts`, {
          ToDate: filters.requestedDates.end_date,
          FromDate: filters.requestedDates.start_date,
          TeamName: filters.selectedTeam
        }),
      defaultValue: [],
      transformError: path(["response", "body", "status"]),
      onError: err => {
        return errorMessage(`Failed to get coach contacts: ${err}`);
      }
    });

  const getCoachContacts = async () => {
    const res = await fetchCoachContacts();
    if (res) {
      setCoachContacts(res);
    }
  };

  const { fetchData: fetchCoachOrders, isFetching: isFetchingCoachOrders } =
    useFetch({
      apiFn: () =>
        put(`compliance-team/coach-orders`, {
          TeamName: filters.selectedTeam,
          FromDate: filters.requestedDates.start_date,
          ToDate: filters.requestedDates.end_date
        }),
      defaultValue: {},
      transformError: path(["response", "body", "status"]),
      onError: err => errorMessage(`Failed to get coaches data: ${err}`)
    });

  const getCoachOrders = async () => {
    const res = await fetchCoachOrders();
    if (res) setCoachOrders(res);
  };

  const { fetchData: fetchLoginHistory, isFetching: isFetchingLoginHistory } =
    useFetch({
      defaultValue: [],
      apiFn: () =>
        put("compliance-team/coaches-login", {
          ToDate: filters.requestedDates.end_date,
          FromDate: filters.requestedDates.start_date,
          TeamName: filters.selectedTeam
        }),
      transformError: path(["response", "body", "status"]),
      onError: error => {
        errorMessage(`Failed to pull breakdown: ${error.message}`);
      }
    });

  const getCoachesLoginHistory = async () => {
    const res = await fetchLoginHistory();
    if(res) setCoachesLoginData(res);
  };

  useEffect(() => {
    if (
      filters.requestedDates.start_date &&
      filters.requestedDates.end_date &&
      filters.selectedTeam
    ) {
      getUserData();
      getCoachContacts();
      getCoachOrders();
      getCoachesLoginHistory();
    }
  }, [
    filters.requestedDates.start_date,
    filters.requestedDates.end_date,
    filters.selectedTeam
  ]);

  return (
    <div className="compliance-team-dashboard-container">
      {!isFetching && coachesData ? (
        <ComplianceTeamCoachesStatus
          minimizeAll={filters.minimizeAll}
          data={coachesData}
        />
      ) : (
        <Spinner />
      )}
      {!isFetchingCoachOrders && coachOrders ? (
        <ComplianceCoachOrders
          data={coachOrders}
          isFetching={isFetchingCoachOrders}
          minimizeAll={filters.minimizeAll}
        />
      ) : (
        <Spinner />
      )}

      {!fetchingCoachContacts ? (
        <ComplianceTeamCoachContacts
          requestedDates={filters.requestedDates}
          minimizeAll={filters.minimizeAll}
          data={coachContacts}
        />
      ) : (
        <Spinner />
      )}

      { 
        !isFetchingLoginHistory ?  
        <ComplianceTeamCoachLogins
          requestedDates={filters.requestedDates}
          minimizeAll={filters.minimizeAll}
          data={coachesLoginData}
        />
        :
        <Spinner/>
      }
    </div>
  );
};

export default ComplianceTeamCoaches;
