import {
  delay,
  take,
  takeEvery,
  fork,
  select,
  race,
  call,
  put,
  cancel,
  all
} from "redux-saga/effects";
import { selectors } from "reducers";
import { getDialerAnswerInboundCall } from "reducers/selectors";
import {
  Types,
  setAutoDialerIsFetchingBatch,
  endAutoDialer,
  setDialerCallPromptStatus,
  setDialerCurrentPatient
} from "actions/auto-dialer";
import { Types as phoneTypes, phoneCall, answerQueue } from "actions/phone";
import { Types as orderFormTypes } from "actions/order-form";
import { formatPhone } from "utils/redux-form";
import { path } from "ramda";
import { WRAPUP_TIME, WRAPUP_TIME_EXTENSION } from "reducers/auto-dialer";
import { dialerPreChecks, getHasMicrophone } from "./dialer-pre-checks";
import * as Sentry from "@sentry/react";
import getDialerLock from "./dialer-lock";
import * as api from "utils/api";
import { startTwilioChat, Types as chatTypes } from "actions/twilio-chat";
/* eslint-disable no-console */
import { createTab, Types as systemTrayTypes } from "actions/system-tray";
import { message, errorMessage } from "actions/message";

const getErrorMessage = error =>
  typeof error == "string" ? error : error.message;

export default function* rootAutoDialerSaga() {
  yield all([
    call(watchAutoDialerInit),
    call(watchRenewAutoDialerPatients),
    call(watchRenewAutoDialerPatientsCompliance),
    call(watchAutoDialerCloseTab),
    call(watchOutreachAutoDialerInit),
    call(watchOutreachAutoDialerInitCompliance)
  ]);
}

export function* deallocateUserFromPatientsAndFetchNew() {
  const userID = yield select(state => selectors.getUserId(state));

  yield api.put(`patients/autodialer/deallocate/FE/${userID}`, null);
  const autoDialerFilters = yield select(state =>
    selectors.getAutoDialerFilters(state)
  );

  const patients = yield api.put(`patients/autodialer/${userID}`, {
    ...autoDialerFilters[0]
  });

  if (patients.length > 0) {
    yield put(message("Getting your next batch of patients"));
    yield put({
      type: Types.AUTO_DIALER_INIT,
      payload: { ids: patients, autoDialerType: "Outreach" }
    });

    yield put(
      createTab({
        type: "patient-outreach",
        key: "patient-outreach",
        label: `Patients (${patients.length})`,
        meta: { ids: patients }
      })
    );
  } else {
    yield put(
      errorMessage(
        "There are no more patients to call with these filters, please adjust your filters"
      )
    );
    throw new Error("Could not find patients");
  }
}

export function* deallocateUserFromPatientsAndFetchNewCompliance() {
  const userID = yield select(state => selectors.getUserId(state));

  yield api.put(`patients/autodialer/deallocate/FE/${userID}`, null);
  const autoDialerFilters = yield select(state =>
    selectors.getAutoDialerFilters(state)
  );

  const patients = yield api.put(`patients/compliance-patient-outreach-list`, {
    ...autoDialerFilters[0]
  });

  if (patients.length > 0) {
    yield put(message("Getting your next batch of patients"));
    yield put({
      type: Types.AUTO_DIALER_INIT,
      payload: { ids: patients, autoDialerType: "Outreach" }
    });

    yield put(
      createTab({
        type: "patient-outreach",
        key: "patient-outreach",
        label: `Patients (${patients.length})`,
        meta: { ids: patients }
      })
    );
  } else {
    yield put(
      errorMessage(
        "There are no more patients to call with these filters, please adjust your filters"
      )
    );
    throw new Error("Could not find patients");
  }
}

function* watchAutoDialerCloseTab() {
  while (true) {
    yield take(
      action =>
        action.type === systemTrayTypes.DESTROY_TAB &&
        action.payload.key === "patient-outreach"
    );
    yield put(endAutoDialer());
  }
}

function* watchOutreachAutoDialerInit() {
  while (true) {
    yield take(Types.OUTREACH_AUTO_DIALER_INIT);
    try {
      yield put(setAutoDialerIsFetchingBatch(true));
      yield call(deallocateUserFromPatientsAndFetchNew);
      yield put(setAutoDialerIsFetchingBatch(false));
    } catch (error) {
      yield put(setAutoDialerIsFetchingBatch(false));
      yield put({
        type: Types.AUTO_DIALER_ERROR,
        payload: getErrorMessage(error)
      });
    }
  }
}

function* watchOutreachAutoDialerInitCompliance() {
  while (true) {
    yield take(Types.COMPLIANCE_OUTREACH_AUTO_DIALER_INIT);
    try {
      yield put(setAutoDialerIsFetchingBatch(true));
      yield call(deallocateUserFromPatientsAndFetchNewCompliance);
      yield put(setAutoDialerIsFetchingBatch(false));
    } catch (error) {
      yield put(setAutoDialerIsFetchingBatch(false));
      yield put({
        type: Types.AUTO_DIALER_ERROR,
        payload: getErrorMessage(error)
      });
    }
  }
}

function* watchAutoDialerInit() {
  while (true) {
    yield take(Types.AUTO_DIALER_INIT);

    let releaseLock, lockError;
    try {
      const promiseLock = new Promise((res, rej) => {
        releaseLock = res;
        lockError = rej;
      });
      yield call(getDialerLock, promiseLock);
      yield race([
        call(startAutoDialer),
        call(watchOrderSubmit),
        take(Types.AUTO_DIALER_END)
      ]);
      releaseLock();
    } catch (error) {
      yield put({
        type: Types.AUTO_DIALER_ERROR,
        payload: getErrorMessage(error)
      });
      lockError();
      Sentry.withScope(scope => {
        scope.setTag("autodialer", "main");
        Sentry.captureException(error);
      });
    }
  }
}

function* startAutoDialer() {
  yield call(dialerPreChecks);
  yield put({ type: Types.AUTO_DIALER_STARTED });
  yield call(proccessNextPatient);
}

function* proccessNextPatient() {
  yield getHasMicrophone();
  // used to need timeout of 0 seconds to force next call to be put on event stack
  yield put({ type: Types.AUTO_DIALER_PROCCESS_NEXT });
  const shouldAnswerQueue = yield select(state =>
    getDialerAnswerInboundCall(state)
  );
  if (shouldAnswerQueue) {
    yield call(proccessInboundCall);
  }
  const nextPendingTextChat = yield getPendingTextChat();
  if (nextPendingTextChat) {
    yield call(proccessTextChat, nextPendingTextChat);
  } else {
    yield call(callNextPatient);
  }
}

function* handleCallEvents() {
  const { timeout } = yield race({
    callStarted: take(phoneTypes.TWILIO_EVENT_CONNECT),
    timeout: delay(15000)
  });
  if (timeout) {
    return {
      type: phoneTypes.TWILIO_EVENT_CALL_ERROR,
      message: "Call Timed Out"
    };
  }
  const { leftMessage } = yield race({
    leftMessage: take(phoneTypes.PLAY_AUTOMATED_VOICEMAIL_SUCCESS),
    ended: take(phoneTypes.TWILIO_EVENT_DISCONNECT)
  });
  if (leftMessage) yield take(phoneTypes.TWILIO_EVENT_DISCONNECT);
  return leftMessage;
}

function* proccessInboundCall() {
  try {
    yield put({ type: Types.AUTO_DIALER_ANSWER_QUEUE });
    const user = yield select(state => selectors.getUser(state));
    yield put(
      answerQueue({
        queue: user.team_name,
        from: user.id
      })
    );
    yield race([take(phoneTypes.PHONE_CALL_ERROR), call(handleCallEvents)]);
    yield call(startPatientWrapup, false);
    yield call(proccessNextPatient);
  } catch (error) {
    console.error(error);
    // Sentry.withScope(scope => {
    //   scope.setTag("autodialer", "inbound");
    //   Sentry.captureException(error);
    // });
    throw `${getErrorMessage(error)}`;
  }
}

function* getPendingTextChat() {
  const userIsTextChatCapable = yield select(state =>
    selectors.getUserIsTextChatCapable(state)
  );
  if (!userIsTextChatCapable) return null;
  try {
    const user = yield select(state => selectors.getUser(state));
    const result = yield api.get(`dme_portal/chats/${user.team_name}/chat_now`);
    return result;
  } catch (error) {
    return null;
  }
}

function* proccessTextChat(chatDetails) {
  yield put({ type: Types.AUTO_DIALER_START_TEXT_CHAT, payload: chatDetails });
  yield put(startTwilioChat(chatDetails.channel_sid));
  yield take(chatTypes.TWILIO_CHAT_ENDED);
  yield call(startPatientWrapup, false);
  yield put({ type: chatTypes.CLOSE_TWILIO_CHAT });
  yield call(proccessNextPatient);
}

function* markAsAttempted(autoDialerNextPatientId) {
  try {
    //mark patients as contacted in patient outreach table
    yield api.post(
      `patients/autodialer/markcontacted/${autoDialerNextPatientId}`
    );
  } catch (error) {
    yield put(
      errorMessage(
        `Contact the IT Team in Google Gmails Chat and say: Could not mark patient ${autoDialerNextPatientId} as having been called- Route Failed`,
        4000
      )
    );

    Sentry.withScope(scope => {
      scope.setTag("autodialer", "main");
      Sentry.captureException(error);
    });
  }
}

function* callNextPatient() {
  try {
    const autoDialerNextPatientId = yield select(state =>
      selectors.getAutoDialerCurrentPatientId(state)
    );
    yield put(setDialerCurrentPatient(autoDialerNextPatientId));
    const autodialerType = yield select(state =>
      selectors.getAutoDialerType(state)
    );

    if (autodialerType == "Outreach" || autodialerType == "ActivityOutreach") {
      yield call(markAsAttempted, autoDialerNextPatientId);
    }

    const userPhoneNumber = yield select(state =>
      selectors.getUserPhoneNumber(state)
    );
    const patientPhoneNumbers = yield call(
      getPatientPhoneNumbers,
      autoDialerNextPatientId
    );
    const patientInfo = yield select(state =>
      selectors.getPatientInfo(state, autoDialerNextPatientId)
    );

    if (!patientPhoneNumbers || patientPhoneNumbers.length === 0) {
      yield put({
        type: Types.AUTO_DIALER_SET_STATUS,
        payload: "Failed to fetch patient phone numbers"
      });

      yield call(startPatientWrapup, true);
      yield call(nextPage);
      return;
    }
    yield put({
      type: Types.AUTO_DIALER_SET_COMPANY,
      payload: patientInfo.company_name
    });

    const noPrimary = patientPhoneNumbers[0] === null;
    let callSecondary = false;

    if (!noPrimary) {
      yield put({
        type: Types.AUTO_DIALER_SET_STATUS,
        payload: `Calling patient. #${formatPhone(patientPhoneNumbers[0])}`
      });
      yield put(
        phoneCall({
          to: patientPhoneNumbers[0],
          from: userPhoneNumber,
          patientToCall: autoDialerNextPatientId,
          autodialer: true
        })
      );
      const { completed } = yield race({
        completed: call(handleCallEvents),
        error: take(phoneTypes.PHONE_CALL_ERROR)
      });
      if (completed?.type == phoneTypes.TWILIO_EVENT_CALL_ERROR) {
        yield put({
          type: Types.AUTO_DIALER_SET_STATUS,
          payload: completed.message
        });
      }
      callSecondary =
        completed?.type == phoneTypes.PLAY_AUTOMATED_VOICEMAIL_SUCCESS;
    }
    if (callSecondary || noPrimary) {
      yield put({
        type: Types.AUTO_DIALER_SET_STATUS,
        payload: ""
      });
      if (patientPhoneNumbers[1]) {
        yield put({
          type: Types.AUTO_DIALER_SET_STATUS,
          payload: `Calling patient. #${formatPhone(patientPhoneNumbers[1])}`
        });
        yield delay(2000);
        yield put(
          phoneCall({
            to: patientPhoneNumbers[1],
            from: userPhoneNumber,
            patientToCall: autoDialerNextPatientId,
            autodialer: true
          })
        );

        const { completed2 } = yield race({
          error: take(phoneTypes.PHONE_CALL_ERROR),
          completed2: call(handleCallEvents)
        });

        if (completed2?.type == phoneTypes.TWILIO_EVENT_CALL_ERROR) {
          yield put({
            type: Types.AUTO_DIALER_SET_STATUS,
            payload: completed2.message
          });
        } else {
          yield put({
            type: Types.AUTO_DIALER_SET_STATUS,
            payload: ""
          });
        }

        if (completed2?.type == phoneTypes.PLAY_AUTOMATED_VOICEMAIL_SUCCESS) {
          yield call(nextPage);
        } else {
          // called patients mobile phone number, no message
          yield call(startPatientWrapup, false);
        }
      }
    } else {
      /* called patients only phone number, no message */
      yield call(startPatientWrapup, false);
    }
    yield call(nextPage);
  } catch (error) {
    console.error(error);
    // Sentry.withScope(scope => {
    //   scope.setTag("autodialer", "outbound");
    //   Sentry.captureException(error);
    // });
    throw `${getErrorMessage(error)}`;
  }
}

function* nextPage() {
  const patientIds = yield select(state =>
    selectors.getAutoDialerPatientIds(state)
  );
  const curPage = yield select(state =>
    selectors.getAutoDialerCurrentPageIndex(state)
  );
  if (curPage < patientIds.length - 1) {
    let percentageDoneToTriggerFetchCall = 0.8;
    var pages = patientIds.length;
    let currentActualPage = curPage + 2; //hasn't incremented yet
    let currentPercent = currentActualPage / pages;

    const autoDialerType = yield select(state =>
      selectors.getAutoDialerType(state)
    );
    if (
      (autoDialerType == "Outreach" || autoDialerType == "ActivityOutreach") &&
      currentPercent >= percentageDoneToTriggerFetchCall
    ) {
      yield put({ type: Types.AUTO_DIALER_FETCH_NEXT_BATCH, autoDialerType });
    }

    yield put({ type: Types.AUTO_DIALER_NEXT_PATIENT });
    yield call(proccessNextPatient);
  } else {
    yield put({ type: Types.AUTO_DIALER_END });
  }
}

function* watchRenewAutoDialerPatients() {
  while (true) {
    const { autoDialerType } = yield take(Types.AUTO_DIALER_FETCH_NEXT_BATCH);
    if (autoDialerType == "Outreach") {
      yield call(fetchBatchOfAutodialerPatients);
    }
    if (autoDialerType == "ActivityOutreach") {
      yield call(fetchBatchOfActivityCenterPatients);
    }
  }
}

function* fetchBatchOfAutodialerPatients() {
  try {
    const autoDialerFilters = yield select(state =>
      selectors.getAutoDialerFilters(state)
    );
    const userID = yield select(state => selectors.getUserId(state));
    const patients = yield api.put(`patients/autodialer/${userID}`, {
      ...autoDialerFilters[0]
    });
    if (patients.length > 0) {
      yield put({
        type: Types.AUTO_DIALER_APPEND_NEXT_BATCH,
        payload: { ids: patients }
      });
      yield put(message("Getting your next batch of patients", 1000));
    }
  } catch (error) {
    yield put(
      errorMessage("Could not automatically renew your patients", 2000)
    );
    Sentry.withScope(scope => {
      scope.setTag("autodialer", "main");
      Sentry.captureException(error);
    });
  }
}

function* watchRenewAutoDialerPatientsCompliance() {
  while (true) {
    const { autoDialerType } = yield take(Types.AUTO_DIALER_FETCH_NEXT_BATCH);
    if (autoDialerType == "Outreach") {
      yield call(fetchBatchOfAutodialerPatientsCompliance);
    }
    if (autoDialerType == "ActivityOutreach") {
      yield call(fetchBatchOfActivityCenterPatients);
    }
  }
}

function* fetchBatchOfAutodialerPatientsCompliance() {
  try {
    const autoDialerFilters = yield select(state =>
      selectors.getAutoDialerFilters(state)
    );
    const patients = yield api.put(
      `patients/compliance-patient-outreach-list`,
      {
        ...autoDialerFilters[0]
      }
    );
    if (patients.length > 0) {
      yield put({
        type: Types.AUTO_DIALER_APPEND_NEXT_BATCH,
        payload: { ids: patients }
      });
      yield put(message("Getting your next batch of patients", 1000));
    }
  } catch (error) {
    yield put(
      errorMessage("Could not automatically renew your patients", 2000)
    );
    Sentry.withScope(scope => {
      scope.setTag("autodialer", "main");
      Sentry.captureException(error);
    });
  }
}

function* fetchBatchOfActivityCenterPatients() {
  try {
    const userID = yield select(state => selectors.getUserId(state));
    const meta = yield select(state =>
      selectors.getAutodialerProperties(state)
    );
    const data = yield api.put(`patients/activityCenter/${userID}`, {
      team_id: meta?.team_id
    });
    if (data.length > 0) {
      yield put({
        type: Types.AUTO_DIALER_APPEND_NEXT_BATCH,
        payload: { ids: data.map(x => x.patient_guid) }
      });
      yield put(message("Getting your next batch of patients", 1000));
    }
  } catch (error) {
    yield put(
      errorMessage("Could not automatically renew your patients", 2000)
    );
    Sentry.withScope(scope => {
      scope.setTag("autodialer", "main");
      Sentry.captureException(error);
    });
  }
}

function* wrapupTimeCountdown() {
  let seconds = yield select(state =>
    selectors.getAutoDialerWrapupLimit(state)
  );

  let dialerPaused = yield select(state =>
    selectors.getAutoDialerPaused(state)
  );

  const task = yield fork(function* () {
    yield take([
      orderFormTypes.ORDER_FORM_RESPONSE,
      orderFormTypes.ORDER_SUBMITTED_IN_OTHER_WINDOW
    ]);
    seconds = seconds + WRAPUP_TIME_EXTENSION;
  });
  yield call(function* () {
    while (seconds > 0 || dialerPaused) {
      //when "paused" - just wait a second and dont do anything else, so timer wont run
      if (!dialerPaused) {
        yield put({
          type: Types.AUTO_DIALER_UPDATE_WRAPUP_TIME,
          payload: { seconds }
        });
      }
      --seconds;
      yield delay(1000);
    }
  });
  yield cancel(task);
  yield put({ type: Types.AUTO_DIALER_WRAPUP_COMPLETED });
  //If the coach has not put in a call disposition by now yet, we need to mark that they didn't
  const dialerCallPromptStatus = yield select(state =>
    selectors.getAutoDialerCallPromptStatus(state)
  );
  //If dialer status is true, we can assume they didn't put the disposition yet
  if (dialerCallPromptStatus === true) {
    yield put(setDialerCallPromptStatus(false));
    const currentCallSid = yield select(state =>
      selectors.getTwilioCallSid(state)
    );

    var values = {
      twilio_call_sid: currentCallSid,
      call_disposition_reason: "Did Not Disposition",
      transfer_reason: null,
      extra_transfer_notes: null
    };
    try {
      yield api.post(`patients/call_disposition`, values);
    } catch (err) {
      console.error(err);
      // const callDirection = yield select(state =>
      //   selectors.getCallDirection(state)
      // );
      // Sentry.withScope(scope => {
      //   scope.setTag("disposition", "didNotDisposition");
      //   Sentry.captureException(err, `direction: ${callDirection}`);
      // });
    }
  }
}

function* startPatientWrapup(
  resetWrapupLimit = true,
  openDispositionWindow = true
) {
  //If patient placed an order during call, automatically disposition "Ordered Supplies"

  var orderPlaced = yield select(state =>
    selectors.getAutodialerPlacedOrder(state)
  );

  if (orderPlaced && openDispositionWindow) {
    const currentCallSid = yield select(state =>
      selectors.getTwilioCallSid(state)
    );
    var values = {
      twilio_call_sid: currentCallSid,
      call_disposition_reason: "Ordered Supplies",
      transfer_reason: null,
      extra_transfer_notes: null
    };
    try {
      yield api.post(`patients/call_disposition`, values);
    } catch (err) {
      // const callDirection = yield select(state =>
      //   selectors.getCallDirection(state)
      // );
      // Sentry.withScope(scope => {
      //   scope.setTag("disposition", "autoDispositionOrdered");
      //   Sentry.captureException(err, `direction: ${callDirection}`);
      // });
    }
  }

  //when dialer is paused, there is no need to do this bedause it overwrites some pause settings
  // if(!dialerPaused){
  yield put({
    type: Types.AUTO_DIALER_WRAPUP_STARTED,
    payload: { resetWrapupLimit }
  });
  // }
  const task = yield fork(wrapupTimeCountdown);
  const { madeCall, end, paused, skipWrapup } = yield race({
    finished: take(Types.AUTO_DIALER_WRAPUP_COMPLETED),
    skipWrapup: take(Types.AUTO_DIALER_SKIP_WRAPUP),
    madeCall: take(phoneTypes.TWILIO_EVENT_CONNECT),
    end: take(Types.AUTO_DIALER_CONTINUE),
    paused: take(Types.AUTO_DIALER_PAUSED)
  });

  if (madeCall) {
    yield cancel(task);
    yield take(phoneTypes.TWILIO_EVENT_DISCONNECT);
    yield call(startPatientWrapup, true);
  } else if (end || skipWrapup) {
    yield cancel(task);
    // yield call(refreshContactList);
  } else if (paused) {
    yield cancel(task);
    yield call(startPatientWrapup, true, false);
  }
}

function* getPatientPhoneNumbers(patientId) {
  // const userRole = yield select(selectors.getUserRole);
  // const isSassUser = userRole === "ServiceCoach" || userRole === "ServiceAdmin";
  // S3 Users alternate between primary and secondary phone number based on cycle
  const patientPhoneNumbers = yield select(state =>
    selectors.getPatientPhoneNumbers(state, patientId)
  );
  // const patientPhoneNumbers = isSassUser
  //   ? yield select(state => selectors.getPatientPhoneNumbers(state, patientId))
  //   : yield select(state =>
  //       selectors.getPatientPhoneNumbersToCall(state, patientId)
  //     );
  if (patientPhoneNumbers.length > 0) {
    return patientPhoneNumbers;
  }
  yield put({
    type: Types.AUTO_DIALER_SET_STATUS,
    payload: "Getting patient phone numbers."
  });
  yield race({
    timeout: delay(13000),
    fetched: take(
      action =>
        action.type === "RECEIVE_GET_PROFILE_INFO" &&
        path(["meta", "patientId"], action) === patientId
    )
  });
  return yield select(state =>
    selectors.getPatientPhoneNumbers(state, patientId)
  );
  // if (isSassUser) {
  //   return yield select(state =>
  //     selectors.getPatientPhoneNumbers(state, patientId)
  //   );
  // } else {
  //   return yield select(state =>
  //     selectors.getPatientPhoneNumbersToCall(state, patientId)
  //   );
  // }
}

function* watchOrderSubmit() {
  yield takeEvery(Types.AUTO_DIALER_PROCCESS_NEXT, function* () {
    const { extendWrapup } = yield race({
      extendWrapup: take([
        orderFormTypes.ORDER_FORM_RESPONSE,
        orderFormTypes.ORDER_SUBMITTED_IN_OTHER_WINDOW
      ]),
      wrapupCompleted: take(Types.AUTO_DIALER_WRAPUP_COMPLETED),
      next: take(Types.AUTO_DIALER_CONTINUE),
      end: take(Types.AUTO_DIALER_END)
    });
    if (extendWrapup) {
      yield put({
        type: Types.AUTO_DIALER_SET_WRAPUP_TIME_LIMIT,
        payload: WRAPUP_TIME_EXTENSION + WRAPUP_TIME,
        placed_order: true
      });
    }
  });
}
