import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { post, del } from "utils/api";
import { errorMessage, message as successMsg } from "actions/message";
import { formatDate, getApiErrorMessageCustomPathEnd } from "utils/misc";
import { Form, Formik } from "formik";
import FormikField from "components/forms/formik-field";
import { Spinner } from "components/ui";
import PropTypes from "prop-types";

export const TimeEditModal = ({
  coachName,
  coachGuid,
  connectionData,
  closeModal,
  eventType,
  getHistoryOfLogins
}) => {
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const validateEmpty = value => {
    let errorMessage;
    if (value === "" || value === 0) errorMessage = "Required";
    return errorMessage;
  };

  const isValidEndTime = (start, end) => {
    if (start > end) return "End time is before start time";
  };

  const updateSleepcoachTime = async values => {
    try {
      const response = await post(`users/update_time`, values);
      dispatch(successMsg(response));
      closeModal();
      getHistoryOfLogins();
    } catch (e) {
      dispatch(
        errorMessage(
          `Failed to update coach time. ${getApiErrorMessageCustomPathEnd(
            e,
            "message"
          )}`
        )
      );
      closeModal();
    }
  };

  const deleteSleepcoachTime = async () => {
    try {
      const response = await del(`users/delete_time/${connectionData.id}`);
      dispatch(successMsg(response));
      closeModal();
      getHistoryOfLogins();
    } catch (e) {
      dispatch(
        errorMessage(
          `Failed to delete coach time. ${getApiErrorMessageCustomPathEnd(
            e,
            "message"
          )}`
        )
      );
      closeModal();
    }
  };

  return (
    <Formik
      initialValues={{
        coach_name: coachName,
        connection_id: connectionData.id,
        start_time: connectionData.start_truncated,
        end_time: connectionData.end_truncated,
        coach_guid: coachGuid,
        event_type: eventType
      }}
      onSubmit={data => {
        updateSleepcoachTime(data);
      }}
    >
      {({ setFieldValue, isSubmitting, setSubmitting, values }) => (
        <Form style={{ padding: "20px" }}>
          <h3>
            {coachName} - {eventType} time for{" "}
            {formatDate(connectionData.start_truncated, "MMMM DD")}
          </h3>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              gap: 20
            }}
          >
            <div>
              <FormikField
                name="start_time"
                label="Start Time"
                type="datetime-local"
                value={values.start_time}
                validate={validateEmpty}
                disabled={isSubmitting}
                onChange={e => {
                  setFieldValue("start_time", e.target.value);
                }}
              />
              <FormikField
                name="end_time"
                label="End Time"
                type="datetime-local"
                value={values.end_time}
                validate={
                  (validateEmpty,
                  () => isValidEndTime(values.start_time, values.end_time))
                }
                disabled={isSubmitting}
                onChange={e => {
                  setFieldValue("end_time", e.target.value);
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              type="button"
              style={{ marginRight: 30 }}
              className="button secondary"
              disabled={isSubmitting}
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </button>
            {!deleting ? (
              <button
                type="button"
                className="button warning"
                disabled={isSubmitting}
                onClick={() => {
                  setDeleting(true);
                }}
              >
                Delete
              </button>
            ) : (
              <button
                type="button"
                className="button warning"
                disabled={isSubmitting}
                onClick={() => {
                  setSubmitting(true);
                  deleteSleepcoachTime();
                }}
              >
                Are you sure? This can&apos;t be undone.
              </button>
            )}
            <button type="submit" className="button" disabled={isSubmitting}>
              {isSubmitting ? <Spinner /> : "Save"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

TimeEditModal.propTypes = {
  coachName: PropTypes.string.isRequired,
  coachGuid: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  connectionData: PropTypes.shape({
    id: PropTypes.number,
    totalTime: PropTypes.date,
    start_truncated: PropTypes.date,
    end_truncated: PropTypes.date
  }),
  eventType: PropTypes.string.isRequired,
  getHistoryOfLogins: PropTypes.func.isRequired
};
