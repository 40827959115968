import React from "react";
import { OrderProfileContext } from "../context";
import DatePicker from "components/ui/date-picker";
import { formatDate } from "utils/dates";

const FollowUpDate = () => {
  const { order, isFetchingOrder, updateOrder } =
    React.useContext(OrderProfileContext);
  return (
    <div className="pending-delayed-container no-print">
      <span className="subheader-text">Follow Up Date:</span>
      <span className="pending-delayed-field">
        <DatePicker
          css={{
            display: "inline-block",
            float: "right"
          }}
          modifiers={{
            disabled: { before: new Date() }
          }}
          containerCss={{ left: -200, bottom: -10 }}
          onChange={day => {
            // if order has fulfillment_type can only clear date
            if (!day) {
              updateOrder({
                follow_up_date: ""
              });
            } else {
              updateOrder({
                follow_up_date: formatDate(day, "YYYY-MM-DD")
              });
            }
          }}
        />
        <span
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          {formatDate(order.follow_up_date)}
        </span>
      </span>
    </div>
  );
};

export default FollowUpDate;
