import React from "react";
import PropTypes from "prop-types";
import { OrderProfileContext } from "../context";
import { getPendingOrderTasksHoldingOrder } from "features/tasks/helpers";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import { selectors } from "reducers";
import { useSelector } from "react-redux";
import { isRequired } from "utils/redux-form";
import Header from "components/order-profile/header";
import MdInfo from "react-icons/lib/md/info-outline";
import { PureModal, InlineWaiting } from "components/ui";

const OrderStatus = ({ orderId }) => {
  const userRole = useSelector(selectors.getUserRole);

  const { order } = React.useContext(OrderProfileContext);

  const canUpdateStatus =
    userRole == "Administrator" ||
    ([
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceAdmin",
      "ServiceCoach"
    ].includes(userRole) &&
      !["Completed", "IVR Pending Work"].includes(order.order_status));

  return (
    <div>
      {canUpdateStatus 
        ? <PureModal
            renderTrigger={({ openModal }) => (
              <span
                onClick={openModal}
                className="order-status modal-link"
              >
                {order.order_status}
              </span>
            )}
              renderContent={({ closeModal }) => (
                <UpdateStatusModal orderId={orderId} closeModal={closeModal} />
            )}
          />
        : <span className="order-status">{order.order_status}</span>
      }
      
    </div>
  );
};

OrderStatus.propTypes = { orderId: PropTypes.string.isRequired };

export default OrderStatus;

const UpdateStatusModal = ({ orderId, closeModal }) => {
  const { order, tasks, updateOrder, createOrderNoteApi } =
    React.useContext(OrderProfileContext);
  const pendingOrderTasks = getPendingOrderTasksHoldingOrder(tasks, orderId);

  const rejectedStatusId = useSelector(state =>
    selectors.getOrderStatusId(state, "Rejected")
  );
  const orderStatusOptions = useSelector(selectors.getEnumOrderStatuses);
  const orderRejectedReasons = useSelector(
    selectors.getEnumOrderRejectedReasons
  );
  const userRole = useSelector(selectors.getUserRole);
  return (
    <div style={{ padding: 5 }}>
      <Header>Update Status</Header>
      <Formik
        onSubmit={async ({ status, rejectedReason, note }) => {
          if (status) {
            await updateOrder({
              status,
              rejected_reason: rejectedReason || null
            });
          }
          if (note) {
            await createOrderNoteApi.fetchData({
              note
            });
          }
          if (closeModal) {
            closeModal();
          }
        }}
        initialValues={{ status: "", rejectedReason: "", note: "" }}
      >
        {({ values, handleChange, setFieldValue, isSubmitting }) => (
          <Form>
            <FormikField
              label="Status"
              name="status"
              component="select"
              onChange={e => {
                handleChange(e);
                setFieldValue("rejectedReason", "");
              }}
              validate={isRequired}
            >
              <option value="" />
              {orderStatusOptions
                .filter(
                  // prevent order from moving to accepted, completed, pending fulfillment if hasUnresolvedHoldReasons
                  ({ text }) =>
                    !pendingOrderTasks ||
                    ![
                      "Accepted",
                      "Completed",
                      "Pending Fulfillment",
                      "Pending Shipment"
                    ].includes(text)
                )
                .filter(
                  ({ text }) =>
                    !(
                      text === "Coach Follow Up" &&
                      order?.creation_method === "Philips Import"
                    )
                )
                .filter(({ text }) => text !== "Voided")
                .filter(({ text }) => {
                  if (
                    [
                      "OfficeAdministrator",
                      "CompanyAdministrator",
                      "ServiceCoach"
                    ].includes(userRole) &&
                    order.order_status === "backordered" &&
                    !["Completed", "Rejected", "Coach Follow Up"].includes(text)
                  )
                    return false;
                  return true;
                })
                .map(({ value, text }) => (
                  <option key={value} value={value}>
                    {text}
                  </option>
                ))}
            </FormikField>
            {values.status === rejectedStatusId && (
              <FormikField
                label="Rejected Reason"
                name="rejectedReason"
                component="select"
                validate={value => {
                  if (values.status === rejectedStatusId)
                    return isRequired(value);
                }}
              >
                <option value="" />
                {orderRejectedReasons.map(({ value, text }) => (
                  <option key={value} value={value}>
                    {text}
                  </option>
                ))}
              </FormikField>
            )}
            <FormikField label="Notes" name="note" component="textarea" />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                alignItems: "center",
                padding: 5
              }}
            >
              {isSubmitting ? (
                <InlineWaiting />
              ) : (
                <button type="submit" style={{ margin: 0 }}>
                  Update Status
                </button>
              )}
              {pendingOrderTasks > 0 && (
                <label
                  style={{
                    flex: 1,
                    justifySelf: "center"
                  }}
                >
                  <MdInfo color="rgb(232, 145, 12)" size={18} />{" "}
                  <span>Order has unresolved tasks</span>
                </label>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

UpdateStatusModal.propTypes = {
  orderId: PropTypes.string,
  closeModal: PropTypes.func
};
