import React, { Component } from "react";
import { PageHeader } from "components/ui";
import OrderProfile from "components/order-profile-new";
import PatientProfile from "components/screens/patient/patient-profile";
import IVRTableContainer from "components/ivr-control-center/ivr-table-container";
import IVRTesting from "./ivr-testing";
import IVRUncompletedCalls from "./ivr-uncompleted-calls";

class IVRControlCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      orderId: "",
      patientId: ""
    };
    this.openTab = this.openTab.bind(this);
    this.loadOrder = this.loadOrder.bind(this);
    this.loadPatient = this.loadPatient.bind(this);
  }

  openTab(tabIndex) {
    this.setState({
      ...this.state,
      tabIndex: tabIndex,
      orderGuid: ""
    });
  }
  loadOrder(orderId) {
    this.setState(
      {
        ...this.state,
        orderId: orderId
      },
      () => this.openTab(2)
    );
  }

  loadPatient(patientId) {
    this.setState(
      {
        ...this.state,
        patientId: patientId
      },
      () => this.openTab(3)
    );
  }

  render() {
    const { tabIndex, orderId, patientId } = this.state;
    const patientParams = { id: patientId };
    return (
      <React.Fragment>
        <div>
          <PageHeader title="IVR Control Center" />
          <div className="s3Tab">
            <span
              className={tabIndex === 0 ? "s3TabLinkActive" : "s3TabLink"}
              onClick={() => this.openTab(0)}
            >
              IVR States
            </span>
            {orderId && (
              <span
                className={tabIndex === 2 ? "s3TabLinkActive" : "s3TabLink"}
                onClick={() => this.openTab(2)}
              >
                Order Profile
              </span>
            )}
            {patientId && (
              <span
                className={tabIndex === 3 ? "s3TabLinkActive" : "s3TabLink"}
                onClick={() => this.openTab(3)}
              >
                Patient Profile
              </span>
            )}
            <span
              className={tabIndex === 4 ? "s3TabLinkActive" : "s3TabLink"}
              onClick={() => this.openTab(4)}
            >
              Test IVR
            </span>
            <span
              className={tabIndex === 5 ? "s3TabLinkActive" : "s3TabLink"}
              onClick={() => this.openTab(5)}
            >
              Test Forms IVR
            </span>
            <span
              className={tabIndex === 6 ? "s3TabLinkActive" : "s3TabLink"}
              onClick={() => this.openTab(6)}
            >
              Uncontacted Patients
            </span>
          </div>
          <div
            className={tabIndex === 0 ? "s3TabContent" : "s3TabContentHidden"}
          >
            <IVRTableContainer
              loadOrder={this.loadOrder}
              loadPatient={this.loadPatient}
            />
          </div>
          {/* Component Removed: Any changes made to Order Form Questions in the FE
                    may require updating hard coded values in the API
                    Do not uncomment and change Order Form Question values without thoroughly testing BE  */}

          {/* <div
            className={tabIndex === 1 ? "s3TabContent" : "s3TabContentHidden"}
          >
            <IVROrderFromQuestions />
          </div> */}

          {orderId && (
            <div
              className={tabIndex === 2 ? "s3TabContent" : "s3TabContentHidden"}
            >
              <OrderProfile orderId={this.state.orderId} />
            </div>
          )}
          {patientId && (
            <div
              className={tabIndex === 3 ? "s3TabContent" : "s3TabContentHidden"}
            >
              <PatientProfile params={patientParams} />
            </div>
          )}
          <div
            className={tabIndex === 4 ? "s3TabContent" : "s3TabContentHidden"}
          >
            <IVRTesting isFormsIVR={false} />
          </div>
          <div
            className={tabIndex === 5 ? "s3TabContent" : "s3TabContentHidden"}
          >
            <IVRTesting isFormsIVR={true} />
          </div>
          <div
            className={tabIndex === 6 ? "s3TabContent" : "s3TabContentHidden"}
          >
            <IVRUncompletedCalls/>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default IVRControlCenter;

IVRControlCenter.propTypes = {};
