import { fromJS } from "immutable";
import { createSelector } from "reselect";
import reduce from "lodash/reduce";
import * as R from "ramda";

const entities = (state, action) => {
  const entities = action.response
    ? action.response.entities
    : action.payload && action.payload.entities;
  if (entities) {
    if (action.type === "RECEIVE_GET_PROFILE_INFO") {
      const { patientId } = action.meta;
      return state.setIn(
        ["patients", patientId],
        R.pathOr({}, ["patients", patientId], entities)
      );
    }
    return state.mergeDeep(fromJS(entities));
  }

  const deletions = action.response
    ? action.response.deletions
    : action.payload && action.payload.deletions;
  if (deletions) {
    return reduce(
      deletions,
      (entitiesStoreAcc, ids, entityType) =>
        entitiesStoreAcc.update(entityType, entities =>
          reduce(
            ids,
            (entityStoreAcc, id) => entityStoreAcc.delete(id.toString()),
            entities
          )
        ),
      state
    );
  }
  return state;
};

export default entities;

export const getEntity = (state, type, id) => {
  const entity = state.getIn([type, id]);
  const jsEntity = entity && entity.toJS();
  return jsEntity;
};

export const getEntities = (state, type) => {
  return state.get(type, fromJS({})).toJS();
};

const makeEntitiesSelector = type =>
  createSelector([state => state.get(type)], entities =>
    entities ? entities.toJS() : {}
  );

/**
 * Memoized selectors of common entity types. Use these in composed
 * selectors to avoid recomputation.
 */
export const getPatientEntities = makeEntitiesSelector("patients");
export const getPatientRowEntities = makeEntitiesSelector("patientRows");
export const getOrderRowEntities = makeEntitiesSelector("orderRows");
export const getFulfillmentRowEntities =
  makeEntitiesSelector("fulfillmentRows");
export const getCompanyEntities = makeEntitiesSelector("companies");
export const getBranchEntities = makeEntitiesSelector("branches");
export const getSleepCoachEntities = makeEntitiesSelector("sleepCoaches");
export const getInsuranceEntities = makeEntitiesSelector("insurance");
export const getTherapistEntities = makeEntitiesSelector("therapists");
export const getCompanyUserDmeEntities =
  makeEntitiesSelector("company_users_dme");
export const getCompanyUserS3Entities =
  makeEntitiesSelector("company_users_s3");

/* NOTE makeEntitiesSelector is impractical if you want to use reselect with multiple selectors
 * or if you want to export from selectors here(prevent cluttering root reducer)
 */
const getEquipmentTypeEntities = createSelector(
  [state => state.getIn(["entities", "equipmentTypes"])],
  equipmentTypes => (equipmentTypes ? equipmentTypes.toJS() : {})
);

const getManufacturerEntities = createSelector(
  [state => state.getIn(["entities", "manufacturers"])],
  manufacturers => (manufacturers ? manufacturers.toJS() : {})
);

// relations
const getManufacturerEquipment = createSelector(
  [state => state.getIn(["entities", "typeManufacturers"])],
  typeManufacturers => (typeManufacturers ? typeManufacturers.toJS() : {})
);

const getInventoryItemEntities = createSelector(
  [state => state.getIn(["entities", "inventoryItems"])],
  inventoryItems => (inventoryItems ? inventoryItems.toJS() : {})
);
const getInventoryItemOptions = createSelector(
  [getInventoryItemEntities],
  R.pipe(
    R.values,
    R.map(inv => ({
      ...inv,
      text: inv.name,
      value: inv.id
    }))
  )
);

const getInventoryMaskOptions = createSelector(
  [getInventoryItemEntities, (_, active_products) => active_products],
  (inventoryItems, active_products) =>
    R.pipe(
      R.values,
      R.filter(
        ({ id, product_type }) =>
          (product_type === "Mask" || product_type === "Mask with Headgear") &&
          R.includes(id, active_products)
      ),
      R.map(inv => ({
        text: inv.name,
        value: inv.id
      }))
    )(inventoryItems)
);

const getInventoryItemParentsById = createSelector(
  [getInventoryItemEntities, (_, id) => id],
  (inventoryItems, id) =>
    R.pipe(
      R.values,
      R.filter(({ children }) => R.includes(id, children))
    )(inventoryItems)
);

const getManufacturerOptions = createSelector(
  getManufacturerEntities,
  manufacturers =>
    R.pipe(
      R.values,
      R.map(m => ({ value: m.GUID, text: m.name, key: m.GUID }))
    )(manufacturers)
);

const getEquipmentTypeOptions = createSelector(
  getEquipmentTypeEntities,
  equipmentTypes =>
    R.pipe(
      R.values,
      R.map(({ type }) => ({ value: type, text: type, key: type }))
    )(equipmentTypes)
);

const getInventoryItems = createSelector(
  getInventoryItemEntities,
  inventoryItems => R.values(inventoryItems)
);

const getActiveInventoryItems = createSelector(
  getInventoryItemEntities,
  inventoryItems =>
    R.pipe(
      R.values,
      R.filter(item => item.productActive)
    )(inventoryItems)
);

const getInventoryItem = createSelector(
  [getInventoryItemEntities, (_, id) => id],
  (inventoryItems, id) => R.propOr({}, id, inventoryItems)
);

const getInventoryItemsByIds = createSelector(
  [getInventoryItemEntities, (_, ids = []) => R.uniq(ids)],
  (inventoryItems, ids) =>
    R.pipe(R.props(ids), R.values, R.reject(R.isNil))(inventoryItems)
);

// filtered by patients current equipment(optional), equipment type & and manufacturer
const getFilteredInventoryItems = createSelector(
  [getInventoryItemEntities, (_, props) => props.filter],
  (inventoryItems, filterState) => {
    const current_equip = R.propOr([], "compatible_equip", filterState);
    const parents = R.props(current_equip, inventoryItems);
    const compatibleEquipment = parents.reduce((acc, item) => {
      return [
        ...acc,
        ...R.props(R.propOr([], "children", item), inventoryItems),
        item
      ];
    }, []);
    const patient_active_products = R.prop("active_products", filterState);
    return R.pipe(
      R.values,
      R.filter(item =>
        R.includes(
          R.prop("product_type", item),
          R.propOr([], "product_type", filterState)
        )
      ),
      R.filter(item =>
        R.includes(
          R.prop("manufacturer_id", item),
          R.propOr([], "manufacturer_id", filterState)
        )
      ),
      R.filter(item => {
        if (!Array.isArray(patient_active_products)) return true;
        else return R.includes(R.prop("id", item), patient_active_products);
      }),
      R.uniqBy(R.prop("id")),
      R.sortBy(R.prop("name"))
    )(
      R.propOr(false, "compatibleEquipment", filterState)
        ? compatibleEquipment
        : inventoryItems
    );
  }
);

const getIsLineItemFlagged = createSelector(
  [
    (state, props) => getInventoryItem(state, R.prop("equipmentId", props)),
    (state, props) =>
      getInventoryItemsByIds(state, R.prop("orderEquipment", props))
  ],
  (equipmentToVerify, orderEquipment) => {
    if (R.isEmpty(orderEquipment)) return false;
    const isBilllingCode = R.propSatisfies(R.includes("billing code"), "name");
    const isMask = x => x.product_type === "Mask";
    const isSeal = x => x.product_type === "Seals";
    const isHeadgear = x => x.propduct_type === "Headgear";
    const isMaskHeadgear = x => x.product_type === "Mask with Headgear";

    if (isBilllingCode(equipmentToVerify)) return false;
    else if (
      !R.anyPass([isSeal, isMask, isHeadgear, isMaskHeadgear])(
        equipmentToVerify
      )
    ) {
      return false;
    } else {
      // has no other seal mask or headgear in order
      if (
        !R.any(R.anyPass([isSeal, isMask, isHeadgear, isMaskHeadgear]))(
          orderEquipment
        )
      ) {
        return false;
      }
    }
    const isAChildOrParent = a => b => {
      return (
        R.propOr([], "children", a).includes(b.id) ||
        R.propOr([], "children", b).includes(a.id)
      );
    };
    const sharesParent = a => b =>
      R.intersection(R.propOr([], "parents", a), R.propOr([], "parents", b)) >
      0;
    const hasCousin = R.pipe(
      R.map(sharesParent(equipmentToVerify)),
      R.any(v => v)
    )(orderEquipment);
    const hasDirectRelation = R.pipe(
      R.map(isAChildOrParent(equipmentToVerify)),
      R.any(v => v)
    )(orderEquipment);
    return !hasDirectRelation && !hasCousin;
  }
);

const getIsLineItemCompatibleWithPatientEq = createSelector(
  [
    (state, props) => getInventoryItem(state, R.prop("equipmentId", props)),
    (state, props) =>
      getInventoryItemsByIds(state, R.prop("patientEquipment", props))
  ],
  (equipmentToVerify, patientEquipment) => {
    if (R.isEmpty(equipmentToVerify)) return true;
    const compatibleEquipment = patientEquipment.reduce((acc, item) => {
      return [
        ...acc,
        ...R.propOr([], "children", item),
        ...R.propOr([], "parents", item),
        item.id
      ];
    }, []);
    return compatibleEquipment.includes(equipmentToVerify.id);
  }
);

const getIsLineItemTypeFlagged = createSelector(
  [
    (state, props) => getInventoryItem(state, R.prop("equipmentId", props)),
    (state, props) =>
      getInventoryItemsByIds(state, R.prop("orderEquipment", props))
  ],
  (equipmentToVerify, orderEquipment) => {
    if (R.isEmpty(orderEquipment)) return false;
    // Nondisposable Filter or Disposable Filter
    const isFilter = R.pipe(R.propOr("", "product_type"), R.includes("Filter"));
    // Mask or Mask With Headgear
    const isMask = R.pipe(R.propOr("", "product_type"), R.includes("Mask"));
    if (isFilter(equipmentToVerify)) {
      return false;
    } else if (isMask(equipmentToVerify)) {
      return R.any(isMask)(orderEquipment);
    } else {
      const hasSameType = R.propEq("product_type");
      return R.any(hasSameType(equipmentToVerify.product_type))(orderEquipment);
    }
  }
);
const getTeams = createSelector(
  [state => state.getIn(["entities", "team_names"])],
  teams => (teams ? teams.toJS() : {})
);

const getTeamNameOptions = createSelector(
  [getTeams],
  R.pipe(
    R.values,
    R.map(({ GUID, name }) => ({
      text: name,
      value: name,
      key: GUID
    })),
    R.sortBy(R.prop("text"))
  )
);
const getTeamNameOptionsKeyAsValue = createSelector(
  [getTeams],
  R.pipe(
    R.values,
    R.map(({ GUID, name }) => ({
      text: name,
      value: GUID,
      key: GUID
    })),
    R.sortBy(R.prop("text"))
  )
);

const getFormOptionsCompany = createSelector(
  [state => state.getIn(["entities", "companies"])],
  companies =>
    R.pipe(
      R.values,
      R.map(c => ({ ...c, text: c.name, value: c.GUID })),
      R.sortBy(R.prop("formAttributeIndex"))
    )(companies ? companies.toJS() : {})
);

const getFormOptionsCompanyUserDme = createSelector(
  [state => state.getIn(["entities", "company_users_dme"])],
  company_users_dme =>
    R.pipe(
      R.values,
      R.sortBy(R.prop("text"))
    )(company_users_dme ? company_users_dme.toJS() : {})
);

const getFormOptionsCompanyUserS3 = createSelector(
  [state => state.getIn(["entities", "company_users_s3"])],
  company_users_s3 =>
    R.pipe(
      R.values,
      R.sortBy(R.prop("formAttrtextibuteIndex"))
    )(company_users_s3 ? company_users_s3.toJS() : {})
);

const getFormOptionsFulfillmentCompany = createSelector(
  [state => state.getIn(["entities", "fulfillment_companies"])],
  fulfillment_companies =>
    R.pipe(R.values)(fulfillment_companies ? fulfillment_companies.toJS() : {})
);

const getOrderHoldReasonOptions = createSelector(
  [state => state.getIn(["entities", "hold_reasons"])],
  reasons =>
    R.pipe(
      R.values,
      R.sortBy(R.prop("priority"))
    )(reasons ? reasons.toJS() : {})
);

const getTaskActionsOptions = createSelector(
  [state => state.getIn(["entities", "task_actions"])?.toJS() ?? {}],
  R.pipe(R.values, R.sortBy(R.prop("priority")))
);

const getNoMaskReasonOptions = createSelector(
  [state => state.getIn(["entities", "no_mask_reasons"])],
  reasons => (reasons ? reasons.toJS() : [])
);

const getHcpcsOptions = createSelector(
  [state => state.getIn(["entities", "hcpcs_list"])],
  hcpcs => (hcpcs ? hcpcs.toJS() : [])
);
const getCallDispositionReasons = createSelector(
  [state => state.getIn(["entities", "call_disposition_reasons"])],
  hcpcs => (hcpcs ? hcpcs.toJS() : [])
);
const getTransferReasons = createSelector(
  [state => state.getIn(["entities", "transfer_reasons"])],
  hcpcs => (hcpcs ? hcpcs.toJS() : [])
);
const getCountriesList = createSelector(
  [state => state.getIn(["entities", "countries_list"])],
  countries => (countries ? countries.toJS() : [])
);
const getPatientTYpes = createSelector(
  [state => state.getIn(["entities", "patient_types"])],
  types =>
    R.pipe(
      R.values,
      R.map(t => ({ ...t })),
      R.sortBy(R.prop("sDescription"))
    )(types ? types.toJS() : [])
);

export const selectors = {
  getInventoryItem,
  getInventoryItems,
  getActiveInventoryItems,
  getInventoryItemsByIds,
  getEquipmentTypeOptions,
  getManufacturerOptions,
  getFilteredInventoryItems,
  getInventoryItemParentsById,
  getManufacturerEquipment,
  getOrderHoldReasonOptions,
  getTaskActionsOptions,
  getIsLineItemCompatibleWithPatientEq,
  getIsLineItemFlagged,
  getIsLineItemTypeFlagged,
  getTeamNameOptions,
  getTeamNameOptionsKeyAsValue,
  getFormOptionsCompany,
  getFormOptionsCompanyUserDme,
  getFormOptionsCompanyUserS3,
  getFormOptionsFulfillmentCompany,
  getInventoryItemOptions,
  getInventoryMaskOptions,
  getNoMaskReasonOptions,
  getHcpcsOptions,
  getCallDispositionReasons,
  getTransferReasons,
  getPatientTYpes,
  getCountriesList
};
