import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { selectors } from "reducers";
import { Rows } from "components/ui";
import Header from "./header";
import PhysicianInfo from "./physicianInfo";

const insuranceChangeRegEx =
  /(The\spatient's\sprimary\sinsurance\scompany).*?(?=\s+(This patient requested).*)/gs;

const getInsuranceChangeFromNotes = notes => {
  try {
    console.log(notes)
    var orderNote = notes[0] ?? "";
    const matches = orderNote?.note.match(insuranceChangeRegEx);
    return matches[0];
  } catch (err) {
    return null;
  }
};

const Updates = ({
  isFetching,
  physicianInfo,
  compliance,
  orderInfo,
  notes
}) => {
  return (
    <div>
      <Header showSpinner={isFetching}>
        Physician, Insurance, and Machine Updates
      </Header>
      {compliance.last_saw_physician && (
        <Rows>
          <div>
            I last saw my physician regarding sleep therapy{" "}
            {compliance.last_saw_physician} ago.
          </div>
        </Rows>
      )}
      <Rows>
        {compliance.physician_changed ? (
          <div>Updated Physician information.</div>
        ) : (
          <div>No updates made to physician.</div>
        )}
        <PhysicianInfo {...physicianInfo} />
      </Rows>
      {compliance.issues ? (
        <Rows>
          <div>
            I am having issues with my PAP device. My current issue is regarding{" "}
            {compliance.issue_note}
          </div>
        </Rows>
      ) : (
        <Rows>
          <div>I am currently having no issues with my PAP therapy.</div>
        </Rows>
      )}
      {compliance.insurance_changed ? (
        <Rows>
          <div>
            I have changed insurance companies since my last order. My new
            insurance is:
          </div>
          <div style={{ margin: 10 }}>{getInsuranceChangeFromNotes(notes)}</div>
        </Rows>
      ) : (
        <Rows>
          No changes have been made to my insurance since the last order.
        </Rows>
      )}
      {compliance.changes_since_last_order != "" && (
        <Rows>
          <div>{compliance.changes_since_last_order}</div>
        </Rows>
      )}
      {compliance.address_changed && (
        <Rows>
          <div>
            My shipping address has{" "}
            {compliance.address_chang_type == "T"
              ? "temporarily"
              : "permanently"}{" "}
            changed since my last order.
          </div>
        </Rows>
      )}
      {orderInfo.inpatient_facility === true && (
        <Rows>
          I am currently in an inpatient care facility such as Hospital, Nursing
          Home, or other inpatient facilities.
        </Rows>
      )}
      {orderInfo.washes_supplies === true && (
        <Rows>
          To extend the use of supplies in between replacement periods, I am
          washing my supplies with warm water and soap
        </Rows>
      )}
    </div>
  );
};

Updates.defaultProps = {
  compliance: {},
  physicianInfo: {},
  orderInfo: {}
};

Updates.propTypes = {
  isFetching: PropTypes.bool,
  physicianInfo: PropTypes.object,
  notes: PropTypes.arrayOf(PropTypes.object),
  compliance: PropTypes.object,
  orderInfo: PropTypes.object
};

export default connect((state, props) => {
  const { data: notes } = selectors.getOrderNotes(state, props);
  return {
    notes
  };
})(Updates);
