import { combineReducers } from "redux";
import { createReducer, makeFetchReducer } from "utils/reducer";
import C from "action-types";
import { Types } from "actions/company";
import { createSelector } from "reselect";
import * as R from "ramda";

const profile = createReducer(
  {},
  {
    [Types.GET_COMPANY_PROFILE]: (state, action) => R.prop("response", action),
    [Types.GET_COMPANY_PROFILE_REQUEST]: () => ({}),
    [Types.TOGGLE_PAUSE_COMPANY_IVR_RESPONSE]: (state, action) =>
      R.assoc("pause_ivr", R.prop("response", action), state)
  }
);

const emailForm = createReducer(
  {},
  {
    [Types.GET_COMPANY_EMAIL_TEMPLATE]: (state, action) =>
      Object.assign({}, state.emailForm, action.response),
    [Types.SET_COMPANY_EMAIL_TEMPLATE_FORM]: (state, action) =>
      Object.assign({}, state, action.emailForm)
  }
);

const patientReport = createReducer(
  {
    insurance: [],
    inactive: {
      deactivatedInRange: {},
      newPatientsInRange: {}
    },
    machineCount: [],
    maskCount: [],
    patientInsuranceCount: [],
    query: { start_date: "", end_date: "" }
  },
  {
    [Types.GET_COMPANY_PATIENT_REPORT_REQUEST]: (state, action) => ({
      ...state,
      query: { ...action.payload }
    }),
    [Types.GET_COMPANY_PATIENTS_INACTIVATION_COUNT_RESPONSE]: (
      state,
      action
    ) => ({
      ...state,
      inactive: { ...action.response }
    }),
    [Types.GET_COMPANY_PATIENTS_MASK_COUNT_RESPONSE]: (state, action) => ({
      ...state,
      maskCount: [...action.response]
    }),
    [Types.GET_COMPANY_PATIENTS_MACHINE_COUNT_RESPONSE]: (state, action) => ({
      ...state,
      machineCount: [...action.response]
    }),
    [Types.GET_COMPANY_PHYSICIAN_INSURANCE_REPORT_RESPONSE]: (
      state,
      action
    ) => ({
      ...state,
      insurance: [...action.response]
    }),
    [Types.GET_COMPANY_PATIENTS_INSURANCE_COUNT_RESPONSE]: (state, action) => ({
      ...state,
      patientInsuranceCount: [...action.response]
    })
  }
);

const patientDemographicLogs = createReducer(
  {
    isFetching: false,
    logs: [],
    total: 0,
    indexParams: {
      filter_by: [
        { by: "startDate", value: "" },
        { by: "endDate", value: "" }
      ],
      sort: { by: "", direction: "" },
      pagination: { page: 0, per: 0 },
      search_for: {},
      search_for_array: []
    }
  },
  {
    [Types.GET_COMPANY_PATIENT_DEMOGRAPHIC_LOGS_REQUEST]: (state, action) => ({
      ...state,
      isFetching: true,
      indexParams: { ...action.payload.indexParams }
    }),
    [Types.GET_COMPANY_PATIENT_DEMOGRAPHIC_LOGS_RESPONSE]: (state, action) => ({
      ...state,
      isFetching: false,
      logs: [...action.response.demographic_changes]
    }),
    [Types.GET_COMPANY_PATIENT_DEMOGRAPHIC_LOGS_FAILURE]: state => ({
      ...state,
      isFetching: false
    }),
    [Types.GET_COMPANY_PATIENT_DEMOGRAPHIC_LOGS_COUNT_RESPONSE]: (
      state,
      action
    ) => ({
      ...state,
      total: action.response
    })
  }
);

const contactsReport = createReducer(
  {},
  {
    [C.COMPANY.GET_CONTACTS_REPORT]: (_, action) =>
      R.propOr({}, "response", action),
    [C.COMPANY.GET_COMPANY_CONTACTS_REPORT_FAILURE]: () => ({})
  }
);

const orderReport = createReducer(
  {
    status_breakdown: [],
    revenue: 0,
    sleep_coach_numbers: []
  },
  {
    [C.COMPANY.GET_ORDERS_REPORT]: (_, action) =>
      R.propOr(
        {
          status_breakdown: [],
          revenue: 0,
          sleep_coach_numbers: []
        },
        "response",
        action
      ),
    [C.COMPANY.GET_COMPANY_ORDERS_REPORT_FAILURE]: () => ({
      status_breakdown: [],
      revenue: 0,
      sleep_coach_numbers: []
    })
  }
);

const fetching = combineReducers({
  orderReport: makeFetchReducer(
    [
      C.COMPANY.GET_COMPANY_ORDERS_REPORT_REQUEST,
      C.COMPANY.GET_COMPANY_ORDERS_REPORT_SUCCESS,
      C.COMPANY.GET_COMPANY_ORDERS_REPORT_FAILURE
    ],
    true
  ),
  contactReport: makeFetchReducer(
    [
      C.COMPANY.GET_COMPANY_CONTACTS_REPORT_REQUEST,
      C.COMPANY.GET_COMPANY_CONTACTS_REPORT_SUCCESS,
      C.COMPANY.GET_COMPANY_CONTACTS_REPORT_FAILURE
    ],
    true
  ),
  patientReport: makeFetchReducer(
    [
      Types.GET_COMPANY_PATIENT_REPORT_REQUEST,
      Types.GET_COMPANY_PATIENT_REPORT_RESPONSE,
      Types.GET_COMPANY_PATIENT_REPORT_FAILURE
    ],
    true
  )
});

const activeInventory = createReducer(
  {
    isFetching: false,
    inventory: []
  },
  {
    [Types.GET_COMPANY_ACTIVE_INVENTORY_REQUEST]: state => ({
      isFetching: true,
      inventory: state.inventory
    }),
    [Types.GET_COMPANY_ACTIVE_INVENTORY_RESPONSE]: (_, { payload }) => ({
      isFetching: false,
      inventory: payload.inventory
    }),
    [Types.GET_COMPANY_ACTIVE_INVENTORY_FAILURE]: state => ({
      isFetching: false,
      inventory: state.inventory
    }),
    [Types.UPDATE_COMPANY_ACTIVE_INVENTORY_REQUEST]: (state, { payload }) => {
      const stateInventory = state.inventory;
      const payloadInventory = payload.inventory;

      stateInventory.forEach(item => {
        const payloadItem = payloadInventory.find(
          x => x.item_id === item.item_id
        );
        if (payloadItem) {
          item.active = payloadItem.active ?? item.active;
          item.autofit_eligible =
            payloadItem.autofit_eligible ?? item.autofit_eligible;
        }
      });

      return {
        ...state,
        inventory: stateInventory
      };
    }
  }
);

const manufacturerWeights = createReducer(
  {
    isFetching: false,
    weights: []
  },
  {
    [Types.GET_COMPANY_MANUFACTURER_WEIGHTS_REQUEST]: state => ({
      ...state,
      isFetching: true
    }),
    [Types.GET_COMPANY_MANUFACTURER_WEIGHTS_RESPONSE]: (_, { payload }) => ({
      isFetching: false,
      weights: payload.weights
    }),
    [Types.GET_COMPANY_MANUFACTURER_WEIGHTS_FAILURE]: state => ({
      ...state,
      isFetching: false
    }),
    [Types.UPDATE_COMPANY_MANUFACTURER_WEIGHTS_REQUEST]: (
      state,
      { payload }
    ) => {
      var manuWeights = state.weights;

      payload.weights.forEach(item => {
        var entry = manuWeights.find(
          x => x.manufacturer_id === item.manufacturer_id
        );
        entry.weight = item.weight;
      });

      return {
        ...state,
        weights: manuWeights
      };
    }
  }
);

const company = combineReducers({
  profile,
  emailForm,
  fetching,
  patientReport,
  patientDemographicLogs,
  contactsReport,
  orderReport,
  activeInventory,
  manufacturerWeights
});

export default company;

const getIsFetchingCompanyContactsReport = createSelector(
  [state => state.get("company")],
  R.path(["fetching", "contactReport"])
);

const getIsFetchingCompanyOrderReport = createSelector(
  [state => state.get("company")],
  R.path(["fetching", "orderReport"])
);
const getIsFetchingPatientReport = createSelector(
  [state => state.get("company")],
  R.path(["fetching", "patientReport"])
);

const getCompanyPhysicianReportInsurance = createSelector(
  [state => state.get("company")],
  R.pathOr([], ["patientReport", "insurance"])
);

const getCompanyPatientDemographicLogs = createSelector(
  [state => state.get("company")],
  R.pathOr([], ["patientDemographicLogs"])
);

const getCompanyPatientReportInactive = createSelector(
  [state => state.get("company")],
  R.pathOr({ deactivatedInRange: {}, newPatientsInRange: {} }, [
    "patientReport",
    "inactive"
  ])
);

const getCompanyPatientReportMachineCount = createSelector(
  [state => state.get("company")],
  R.pathOr([], ["patientReport", "machineCount"])
);

const getCompanyPatientReportMaskCount = createSelector(
  [state => state.get("company")],
  R.pathOr([], ["patientReport", "maskCount"])
);

const getCompanyPatientReportQuery = createSelector(
  [state => state.get("company")],
  R.pathOr({}, ["patientReport", "query"])
);

const getCompanyPatientInsuranceCount = createSelector(
  [state => state.get("company")],
  R.pathOr([], ["patientReport", "patientInsuranceCount"])
);

const getCompanyContactsReport = createSelector(
  [state => state.get("company")],
  R.pathOr([], ["contactsReport"])
);

const getCompanyOrderReport = createSelector(
  [state => state.get("company")],
  R.propOr(
    {
      status_breakdown: [],
      revenue: 0,
      sleep_coach_numbers: []
    },
    "orderReport"
  )
);

const getCompanyOrderReportStatuses = createSelector(
  [getCompanyOrderReport],
  R.propOr([], "status_breakdown")
);

const getCompanyOrderReportStatusCounts = createSelector(
  [getCompanyOrderReportStatuses],
  R.reduce((cur, item) => ({ ...cur, [item.name]: item.count }), {})
);

const getCompanyOrdersByFormOfContact = createSelector(
  [getCompanyOrderReport],
  R.prop("patient_form_of_contact_order_breakdown")
);
const getCompanyOrderCycleReport = createSelector(
  [getCompanyOrdersByFormOfContact],
  data => {
    if (!data) return [];

    const cycleLens = (cycle, prop) =>
      R.pipe(
        R.path([prop, "order_count_by_cycle"]),
        R.nth(cycle - 1),
        R.prop("count")
      )(data);

    const getCycleData = cycle => ({
      name: `Cycle ${cycle}`,
      autoship: cycleLens(cycle, "autoship"),
      email: cycleLens(cycle, "email"),
      ivr: cycleLens(cycle, "ivr"),
      manual_call: cycleLens(cycle, "manual_call"),
      push_notification: cycleLens(cycle, "push_notification"),
      text_message: cycleLens(cycle, "text_message")
    });
    return R.map(getCycleData, Array.from({ length: 75 }, (value, index) => index + 1));
  }
);

const getCompanyOrdersByFormOfContactReport = createSelector(
  [getCompanyOrdersByFormOfContact],
  data => {
    if (!data) return [];
    const getSubType = R.pick([
      "orders_from_web_app",
      "orders_from_ivr",
      "orders_from_mobile_app",
      "orders_from_patient_portal",
      "orders_from_patient_portal_email",
      "orders_from_text_message",
      "orders_from_external_portal",
      "total"
    ]);
    return [
      {
        name: "Manual Call",
        ...getSubType(data.manual_call)
      },
      {
        name: "Email",
        ...getSubType(data.email)
      },
      {
        name: "Text Message",
        ...getSubType(data.text_message)
      },
      {
        name: "IVR",
        ...getSubType(data.ivr)
      },
      {
        name: "Push Notification",
        ...getSubType(data.push_notification)
      },
      {
        name: "Autoship",
        ...getSubType(data.autoship)
      }
    ];
  }
);

const getCompanyProfile = createSelector(
  [state => state.get("company")],
  R.prop("profile")
);

const getCompanyProfileContacts = createSelector(
  [getCompanyProfile],
  R.prop("contacts")
);

const getCompanyBranch = createSelector(
  [getCompanyProfile, (_, branchId) => branchId],
  (company, branchId) => {
    const companyBranches = R.propOr([], "branches", company);
    if (companyBranches) {
      const branch = companyBranches.find(p => p.id == branchId);
      return branch;
    }
    return null;
  }
);

// const getCompanyActiveInventory = createSelector(
//   [state => state.get("company")],
//   R.prop("activeInventory")
// );

const getCompanyManufacturerWeights = createSelector(
  [state => state.get("company")],
  R.prop("manufacturerWeights")
);

const getCompanyAutofitManufacturers = createSelector(
  [state => state.get("company")],
  R.pipe(
    R.path(["activeInventory", "inventory"]),
    R.filter(x => x && x.autofit_eligible),
    R.map(x => x.manufacturer_id),
    R.uniq
  )
);

export const selectors = {
  getCompanyProfile,
  getCompanyProfileContacts,
  getCompanyBranch,
  getIsFetchingCompanyContactsReport,
  getIsFetchingCompanyOrderReport,
  getIsFetchingPatientReport,
  getCompanyOrderReport,
  getCompanyOrderReportStatuses,
  getCompanyOrderReportStatusCounts,
  getCompanyPhysicianReportInsurance,
  getCompanyPatientDemographicLogs,
  getCompanyPatientReportInactive,
  getCompanyPatientReportMachineCount,
  getCompanyPatientReportMaskCount,
  getCompanyPatientReportQuery,
  getCompanyPatientInsuranceCount,
  getCompanyContactsReport,
  getCompanyOrdersByFormOfContact,
  getCompanyOrderCycleReport,
  getCompanyOrdersByFormOfContactReport,
  // getCompanyActiveInventory,
  getCompanyManufacturerWeights,
  getCompanyAutofitManufacturers
};
