import { createReducer } from "utils/reducer";
import { createSelector } from "reselect";
import { Types } from "./actions";
import * as R from "ramda";

const bonafideIntegrationReducer = createReducer(
  {},
  {
    [Types.BONAFIDE_SUBMIT_ORDER_REQUEST]: (state, action) =>
      R.dissoc(action.orderId, state),
    [Types.BONAFIDE_SUBMIT_ORDER_RESPONSE]: (state, action) =>
      R.assoc(action.orderId, R.prop("response", action), state),
    [Types.BONAFIDE_SUBMIT_ORDER_FAILURE]: (state, action) =>
      R.assoc(
        action.orderId,
        { error: R.pathOr(true, ["error", "status"], action) },
        state
      ),
    [Types.BONAFIDE_ORDER_RESULT_REQUEST]: (state, action) =>
      R.dissoc(action.orderId, state),
    [Types.BONAFIDE_ORDER_RESULT_RESPONSE]: (state, action) =>
      R.assoc(action.orderId, R.prop("response", action), state),
    [Types.BONAFIDE_ORDER_RESULT_FAILURE]: (state, action) =>
      R.assoc(
        action.orderId,
        { error: R.pathOr(true, ["error", "status"], action) },
        state
      )
  }
);

export default bonafideIntegrationReducer;

const getBonafideOrderResult = createSelector(
  [state => state.getIn(["bonafide"]), (_, { orderId }) => orderId],
  (integrationResult, orderId) => R.propOr([], orderId, integrationResult)
);

// const getIsValidBonafideResult = createSelector(
//   [getBonafideOrderResult, (a, b, lineItems) => lineItems],
//   (results, orderLineItems = []) => {
//     var result = results?.[0];
//     if (result?.response?.status == "error") {
//       return false;
//     }
//     if (result?.response?.status == "failsed") {
//       return false;
//     }
//     const a = R.sortWith(
//       [R.ascend(R.prop("hcpcs")), R.ascend(R.prop("id"))],
//       JSON.parse(R.propOr(null, "requestBody", result)) ?? []
//     );
//     const b = R.sortWith(
//       [R.ascend(R.prop("hcpcs")), R.ascend(R.prop("id"))],
//       orderLineItems.map(c => ({
//         id: c.line_item_id,
//         hcpcs: c.HCPCS.trim(),
//         quantity: c.quantity,
//         zeroBilled: c.zeroChargeItem
//       }))
//     );
//     return R.equals(a, b);
//   }
// );

// const getIsBonafideResultConfirmed = createSelector(
//   [getBonafideOrderResult],
//   results => {
//     var result = results?.[0];
//     return (
//       R.path(["response", "status"], result) == "success" &&
//       R.prop("isConfirmed", result) == true
//     );
//   }
// );
//
// // step1 create preauth - if not valid
// // step2 auth form/create order - valid, not confirmed
// // step3 completed - valid? confirmed & status = success
// const getBonafideCurrentStep = createSelector(
//   [getIsBonafideResultConfirmed, getIsValidBonafideResult],
//   (isConfirmed, isValid) => {
//     return isConfirmed === true ? 3 : !isValid ? 1 : 2;
//   }
// );
//
// // bypass step 1 if failed insurance verification
// const getCanOverrideOrderCreation = createSelector(
//   [getBonafideCurrentStep, getBonafideOrderResult],
//   currentStep => {
//     // (currentStep, result) => {
//     // if (currentStep == 0) return true;
//     if (currentStep == 1) return true;
//     return false;
//   }
// );

export const selectors = {
  getBonafideOrderResult,
  //getIsValidBonafideResult,
  //getIsBonafideResultConfirmed,
  //getBonafideCurrentStep,
  //getCanOverrideOrderCreation
};
