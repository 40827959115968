import React from "react";
import PropTypes from "prop-types";

const InsuranceSelect = ({
  selectedInsurance,
  setSelectedInsurance,
  order
}) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <label
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <span>Primary Insurance</span>
        <input
          type="radio"
          id="insurance1"
          value={1}
          checked={selectedInsurance === 1}
          onChange={() => setSelectedInsurance(1)}
          style={{ margin: 0, marginLeft: 10 }}
        />
      </label>
      {order.eligible_for_secondary_insurance_verification && (
        <label
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <span>Secondary Insurance</span>
          <input
            type="radio"
            id="insurance2"
            value={2}
            checked={selectedInsurance === 2}
            onChange={() => setSelectedInsurance(2)}
            style={{ margin: 0, marginLeft: 10 }}
          />
        </label>
      )}
    </div>
  );
};

export default InsuranceSelect;

InsuranceSelect.propTypes = {
  selectedInsurance: PropTypes.number.isRequired,
  setSelectedInsurance: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired
}
