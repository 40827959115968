import React from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { withProps } from "recompose";
import compose from "lodash/fp/compose";
import compact from "lodash/compact";
import moment from "moment";
import {
  withQuery,
  withTableHandlers,
  withApi,
  withHeaderFooter
} from "features/query";
import { setOrderBatchIds, setPatientBatchIds } from "actions/batch";
import { clearOrderTableSelected } from "actions/sleep-coach-dashboard";
import { getFormOptionsSleepCoachWithUserFirst, selectors } from "reducers";
import Table from "components/ui/impl/table";
import { createTab } from "actions/system-tray";
import { IoBriefcase, IoPerson } from "react-icons/lib/io";
import PureModal from "components/ui/modal/pure-modal";
import PatientProfile from "components/screens/patient/patient-profile";
import OrderProfileNew from "components/order-profile-new";
import TeamCoachFilter from "../patients-table/team-sleepcoach-filter";
import AutodialerBtn from "../autodialer-btn";
import { platformOptions } from "utils/enum";
import AssignmentFilter from "../patients-table/sleepcoach-assignemtn-filter";

/* eslint-disable react/prop-types */

const formatLastUpdate = ({ last_updated }) =>
  moment(last_updated).format("MM / DD / YYYY");

const formatSubmitted = ({ submitted }) =>
  moment(submitted).format("MM / DD / YYYY");

const formatStatusChanged = ({ status_changed }) =>
  moment(status_changed).format("MM / DD / YYYY");

const BatchHeader = ({ rows }) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch({ type: "CLEAR_SELECTED" });
  }, [rows]);
  const scDashboardSelected = useSelector(state =>
    state.getIn(["sleepCoachDashboard"])
  );
  return (
    <th>
      <span
        onClick={() => {
          if (rows.length > scDashboardSelected.orders.length) {
            dispatch({
              type: "SELECT_ALL",
              patients: rows.map(o => o.patient_id),
              orders: rows.map(o => o.id)
            });
          } else {
            dispatch({ type: "CLEAR_SELECTED" });
          }
        }}
      >
        All
      </span>
    </th>
  );
};
const BatchCell = ({ patient_id, id }) => {
  const dispatch = useDispatch();
  // should add beter selector fn
  const scDashboardSelected = useSelector(state =>
    state.getIn(["sleepCoachDashboard"])
  );
  return (
    <td>
      <input
        checked={scDashboardSelected.orders.includes(id)}
        onChange={() => {
          dispatch({ type: "TOGGLE_ITEM", patientId: patient_id, orderId: id });
        }}
        type="checkbox"
      ></input>
    </td>
  );
};

const OrderStatusTable = ({ ...props }) => (
  <div className="order-status-table">
    <Table {...props} />
  </div>
);

export default compose(
  connect(
    state => ({
      companyOptions: selectors.getFormOptionsCompany(state),
      sleepCoaches: getFormOptionsSleepCoachWithUserFirst(state),
      admins: selectors.getEnumAdmins(state),
      orderStatusOptions: selectors.getOrderStatusTextOptions(state),
      selectedItems: state.getIn(["sleepCoachDashboard"]),
      teamNameOptions: selectors
        .getTeamNameOptions(state)
        .map(tno => ({ key: tno.text, text: tno.text, value: tno.text })),
      query: selectors.getQueryFilters(state, "follow-up-orders-table")
    }),
    {
      createTab,
      setPatientBatchIds,
      setOrderBatchIds,
      clearOrderTableSelected
    }
  ),
  withQuery({}),
  withApi({
    api: {
      route: "orders",
      responseKey: "orders",
      idKey: "id",
      entityKey: "orderRows"
    }
  }),
  withProps(
    ({
      selectedItems,
      createTab,
      setPatientBatchIds,
      setOrderBatchIds,
      companyOptions,
      clearOrderTableSelected,
      requestData,
      teamNameOptions
    }) => {
      return {
        columns: compact([
          {
            header: "",
            HeaderCell: BatchHeader,
            RowCell: BatchCell,
            field: "order_id"
          },
          {
            header: "Patient",
            field: "patient",
            format: ({ patient_id }) => (
              <PureModal
                windowProps={{ large: "true" }}
                renderTrigger={({ openModal }) => (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "center"
                    }}
                  >
                    <IoPerson
                      onClick={openModal}
                      size={20}
                      style={{ color: "#249c91" }}
                    />
                  </div>
                )}
              >
                <PatientProfile patientId={patient_id} />
              </PureModal>
            )
          },
          {
            header: "Order",
            field: "invoice_number",
            format: ({ id }) => (
              <PureModal
                windowProps={{ large: true }}
                renderTrigger={({ openModal }) => (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "center"
                    }}
                  >
                    <IoBriefcase
                      onClick={openModal}
                      size={20}
                      style={{ color: "#249c91" }}
                    />
                  </div>
                )}
              >
                <OrderProfileNew orderId={id} />
              </PureModal>
            )
          },
          {
            header: "Company",
            field: "company",
            filterKey: "company_id",
            filters: companyOptions
          },
          {
            header: "Sleep Coach",
            field: "sleep_coach",
            renderFilter: props => (
              <TeamCoachFilter {...props} teamNameOptions={teamNameOptions} />
            ),
            filterKey: "sleep_coach",
            filterDownSelect: true
          },
          {
            header: "Assignment",
            field: "sleep_coach_assignment",
            renderFilter: props => (
              <AssignmentFilter {...props} teamNameOptions={teamNameOptions} />
            ),
            filterKey: "sleep_coach_assignment",
            filterDownSelect: true
          },
          {
            header: "Team",
            field: "team_name"
          },
          {
            header: "Submitted",
            field: "submitted",
            format: formatSubmitted
          },
          {
            header: "Status changed",
            field: "status_changed",
            format: formatStatusChanged
          },
          {
            header: "Last Updated",
            field: "last_updated",
            format: formatLastUpdate
          },
          {
            header: "Platform",
            field: "platform",
            filters: platformOptions
          },
          {
            header: "Follow Up Reason",
            field: "followUpReason"
          },
          {
            header: "Pending Info",
            field: "pending_info"
          }
        ]),
        customDisplay: [
          () =>
            selectedItems.orders.length || selectedItems.patients.length ? (
              <div
                style={{
                  color: "white",
                  display: "flex",
                  flex: 1,
                  justifyContent: "center"
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  Selected patients: {selectedItems.patients.length}
                </div>
                <div>Selected orders: {selectedItems.orders.length}</div>
              </div>
            ) : null
        ],
        customButtons: [
          {
            component: () => (
              <>
                <span className="action-buttons-sleepcoach-dashboard">
                  <span
                    className="icon-button-sleepcoach-dashboard"
                    onClick={() => {
                      // alert(selectedItems.patients);
                      if (selectedItems.patients.length) {
                        setPatientBatchIds(selectedItems.patients);
                        createTab({
                          type: "patients",
                          key: "patients",
                          label: `Patients (${selectedItems.patients.length})`,
                          meta: { ids: selectedItems.patients }
                        });
                      }
                    }}
                  >
                    <IoPerson />
                  </span>
                  <span
                    className="icon-button-sleepcoach-dashboard"
                    onClick={() => {
                      if (selectedItems.orders.length) {
                        setOrderBatchIds(selectedItems.orders);
                        createTab({
                          type: "orders",
                          key: "orders",
                          label: `Orders (${selectedItems.orders.length})`,
                          meta: { ids: selectedItems.orders }
                        });
                      }
                    }}
                  >
                    <IoBriefcase />
                  </span>
                  <AutodialerBtn
                    selectedPatients={selectedItems.patients}
                    batchClear={clearOrderTableSelected}
                    refreshData={requestData}
                    setSelectedPatients={clearOrderTableSelected}
                  />
                </span>
              </>
            )
          }
        ],
        showCount: true
      };
    }
  ),
  withHeaderFooter({
    perPageOptions: [25, 50, 150, 300, 500],
    forceBatchReset: true,
    customFilters: [
      {
        options: [
          {
            value: "all",
            text: "All Orders"
          },
          {
            value: "mine",
            text: "My Orders"
          }
        ],
        field: "order"
      }
    ],
    scrollable: true
  }),
  withTableHandlers()
)(OrderStatusTable);

OrderStatusTable.propTypes = {
  orderStatus: PropTypes.string.isRequired,
  markedForBatch: PropTypes.arrayOf(PropTypes.string),
  onBulkUpdateSuccess: PropTypes.func
};
