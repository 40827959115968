import React from "react";
import PropTypes from "prop-types";
import { useSort } from "hooks";
import { Link } from "react-router-dom";
import TableSortableHeader from "components/ui/table-sortable-header";
import { FaGlobe } from "react-icons/lib/fa";

const TeamCoachOrdersTable = ({ data }) => {
  const { ascending, sortBy, getSortedArray, toggleSortDirection, setSortBy } =
    useSort({ by: "Coach", ascending: false });

  return (
    <table>
      <TableSortableHeader
        columns={[
          { key: "Coach", text: "Name" },
          { key: "EnteredOrders", text: "Total Orders" },
        ]}
        sortBy={sortBy}
        ascending={ascending}
        setSortBy={setSortBy}
        toggleSortDirection={toggleSortDirection}
      />
      <tbody>
        {getSortedArray(data).map(d => (
          <tr key={d.COACH_GUID}>
            <td>
              <Link to={`/users/${d.COACH_GUID}`}>
                {d.Coach} {d?.contracted_employee ? <FaGlobe /> : ""}
              </Link>
            </td>
            <td>{d.total_orders_count}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

TeamCoachOrdersTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default TeamCoachOrdersTable;
