import * as R from "ramda";
import { createSelector } from "reselect";
import { createReducer } from "utils/reducer";
import { Types } from "actions/phone-queues";
import { getTimeEllapsedInSeconds } from "utils/dates";
import { selectors as rootSelectors } from "reducers";

export default createReducer([], {
  [Types.SET_PHONE_QUEUES]: (state, { payload }) => payload,
  [Types.CALL_ENQUEUED]: (state, { payload }) =>
    R.assoc(
      payload.team,
      {
        team: payload.team,
        calls: R.pathOr(0, [payload.team, "calls"], state) + 1
      },

      state
    ),
  [Types.CALL_DEQUEUED]: (state, { payload }) =>
    R.assoc(
      payload.team,
      {
        team: payload.team,
        calls: R.pathOr(1, [payload.team, "calls"], state) - 1
      },

      state
    )
});

// const new_getPhoneQueues = createSelector(
//   [state => state.get("phone-queues")],
//   R.values
// );

const new_getPhoneQueues = createSelector(
  [
    state => state.get("phone-queues"),
    state => state.getIn(["entities", "team_names"])?.toJS()
  ],
  (queues, teamNameOptions) => {
    const teams = R.pipe(
      R.values,
      R.map(({ name }) => name)
    )(teamNameOptions);
    const result = R.pipe(
      R.values,
      R.filter(q => teams.includes(q.friendly_name)),
      R.uniqBy(q => q.friendly_name),
      R.sortBy(R.prop("friendly_name"))
    )(queues);
    return result;
  }
);

const new_getPhoneQueueByTeam = createSelector(
  [state => state.get("phone-queues"), (_, team) => team],
  (queues, team) => R.prop(team, queues)
);

const new_getPhoneQueueStats = createSelector([new_getPhoneQueues], queues => ({
  totalInQueue: queues.reduce(
    (accum, { current_size }) => accum + current_size,
    0
  ),
  longestInQueue: queues.reduce(
    (accum, { average_wait_time }) =>
      accum < average_wait_time ? average_wait_time : accum,
    0
  ),
  average_wait_time: R.pipe(
    R.map(R.prop("listCallTimes")),
    R.flatten,
    R.map(getTimeEllapsedInSeconds),
    call_times => {
      if (call_times && call_times.length > 0) {
        const sum = call_times.reduce((acc, curr) => acc + curr);
        return (sum / call_times.length).toFixed(0);
      } else return 0;
    }
  )(queues)
}));

export const selectors = {
  new_getPhoneQueues,
  new_getPhoneQueueByTeam,
  new_getPhoneQueueStats
};
