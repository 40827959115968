import React from "react";
import { PureModal } from "components/ui";
import RescheduleAppintmentModal from './index.jsx'
import PropTypes from "prop-types";

export default function CardRescheduleAppointmentButton({ appointmentId, timeRange, onSuccess, buttonClassname }) {

    return (
        <div>
            <PureModal
                maxWidth="sm"
                fullWidth
                renderTrigger={({ openModal }) => (
                    <button
                        className={buttonClassname ? buttonClassname : "appointment-action-button reschedule-button"}
                        style={{ marginLeft: 10 }}
                        onClick={() => { openModal() }}
                    >
                        Reschedule
                    </button>
                )
                }
                renderContent={({ closeModal }) => (
                    <RescheduleAppintmentModal
                        appointmentId={appointmentId}
                        timeRange={timeRange}
                        onSuccess={onSuccess}
                        closeModal={closeModal}
                    />
                )}
            />
        </div>
    )
}

CardRescheduleAppointmentButton.propTypes = {
    appointmentId: PropTypes.number,
    timeRange: PropTypes.object,
    onSuccess: PropTypes.func,
    slot_id: PropTypes.string,
    buttonClassname: PropTypes.string
}