import React from "react";
import { selectors } from "reducers";
import { useSelector, useDispatch } from "react-redux";
import { Portal } from "react-portal";
import PureWindow from "components/ui/modal/pure-window";
import {
  autoSkipWrapupTime,
  setDialerCallPromptStatus
} from "actions/auto-dialer";
import PropTypes from "prop-types";
import { errorMessage } from "actions/message";
import { Formik, Form } from "formik";
import { useFetch } from "hooks";
import { post } from "utils/api";
import { getApiErrorMessage } from "utils/misc";
import { path } from "ramda";
import "./style.scss";
import { Spinner } from "components/ui";

const DialerActions = () => {
  const dispatch = useDispatch();
  const dialerCallPromptStatus = useSelector(
    selectors.getAutoDialerCallPromptStatus
  );
  // const callDirection = useSelector(selectors.getCallDirection);
  const currentPatientId = useSelector(selectors.getAutoDialerCurrentPatient);
  const callSid = useSelector(selectors.getTwilioCallSid);

  const callDispositionReasons = useSelector(
    selectors.getCallDispositionReasons
  );
  const transferReasons = useSelector(selectors.getTransferReasons);

  const disabled = values => {
    if (
      values.call_disposition_reason == "Transferred" &&
      values.transfer_reason == ""
    )
      return true;
    return false;
  };

  const submitDispositionApi = useFetch({
    apiFn: ({ payload }) => post(`patients/call_disposition`, payload),
    transformError: path(["response", "body", "status"]),
    onError: error => {
      // Sentry.withScope(scope => {
      //   scope.setTag("disposition", "autoDispositionOrdered");
      //   Sentry.captureException(error, `direction: ${callDirection}`);
      // });
      dispatch(
        errorMessage(`Failed submit disposition: ${getApiErrorMessage(error)}`)
      );
    }
  });

  return (
    dialerCallPromptStatus && (
      <Portal>
        <PureWindow
          // onXClick={() => dispatch(setDialerCallPromptStatus(false))}
          closeable={false}
          hidden={!dialerCallPromptStatus}
          // large
        >
          <div className="dialer-actions-container">
            <div className="dialer-actions-submit">
              <div className="patient-container-info">
                {currentPatientId && <div>Patient: {currentPatientId}</div>}
                <div>Call sid: {callSid}</div>
              </div>
              {/* <button disabled={!selectedAction}>Submit</button> */}
            </div>

            <Formik
              initialValues={{
                twilio_call_sid: callSid,
                call_disposition_reason: "Declined Supplies",
                transfer_reason: "",
                extra_transfer_notes: ""
              }}
              onSubmit={async values => {
                submitDispositionApi.fetchData({
                  payload: values
                });
                if (values.call_disposition_reason === "Declined Supplies") {
                  dispatch(autoSkipWrapupTime());
                }
                dispatch(setDialerCallPromptStatus(false));
              }}
            >
              {({ values, isSubmitting, setFieldValue }) => (
                <div>
                  <Form className="call-disposition-form">
                    <h2>Call Dispositions</h2>

                    <div className="disposition-options">
                      {callDispositionReasons.map(valueInSnap => (
                        <div
                          className={
                            values.call_disposition_reason == valueInSnap.value
                              ? `card selected`
                              : "card"
                          }
                          key={valueInSnap.value}
                          onClick={() => {
                            // toggleAction(valueInSnap.value);
                            setFieldValue(
                              "call_disposition_reason",
                              valueInSnap.value
                            );
                            if (valueInSnap.value !== "Transferred")
                              setFieldValue("transfer_reason", "");
                          }}
                        >
                          <div className="card-wrapper">
                            <div className="action-name">
                              {valueInSnap.value}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div>
                      {values.call_disposition_reason === "Transferred" && (
                        <div>
                          <h2>Reason For Transfer</h2>
                          <div className="disposition-options">
                            {transferReasons.map(valueInSnap => (
                              <div
                                className={
                                  values.transfer_reason == valueInSnap.value
                                    ? `card selected`
                                    : "card"
                                }
                                key={valueInSnap.value}
                                onClick={() => {
                                  setFieldValue(
                                    "transfer_reason",
                                    valueInSnap.value
                                  );
                                }}
                              >
                                <div className="card-wrapper">
                                  <div className="action-name">
                                    {valueInSnap.value}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>

                    <label>Additional Notes</label>
                    <input
                      type="text"
                      className="inputView"
                      value={values.extra_transfer_notes}
                      style={{ width: "50%" }}
                      onChange={e => {
                        setFieldValue("extra_transfer_notes", e.target.value);
                      }}
                    ></input>
                    <button
                      disabled={disabled(values) || isSubmitting}
                      type="submit"
                    >
                      Submit
                    </button>
                    {isSubmitting && <Spinner />}
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </PureWindow>
      </Portal>
    )
  );
};
export default DialerActions;

DialerActions.propTypes = {
  callSid: PropTypes.string
};
