import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectors } from "reducers";
import { Field, FieldArray, useFormikContext } from "formik";
import FormikField from "components/forms/formik-field";
import { formatDate } from "utils/misc";
import RadioGroupField from "components/forms/radio-group-field";
import SearchableDropdown from "components/searchable-dropdown";

const getInsuranceDisplayName = index => {
  switch (index) {
    case 0:
      return "Primary";
    case 1:
      return "Secondary";
    case 2:
      return "Tertiary";
    default:
      return "";
  }
};

const InsuranceForm = ({ insuranceOptions, patientInsurances, questions }) => {
  const guarantorOptions = useSelector(
    selectors.getInsuranceRelationshipOptions
  );
  const insurnace_change_question = questions.find(
    q => q.id === "insurance_change"
  );

  const { values, errors, setFieldValue } = useFormikContext();

  const disabled = !values.questions?.insurance_change;

  React.useEffect(() => {
    if (values.questions?.insurance_change === false) {
      setFieldValue("insurances", patientInsurances);
    }
  }, [values.questions?.insurance_change]);
  if (!insurnace_change_question) {
    return null;
  }
  return (
    <div>
      <Field
        component={RadioGroupField}
        name="questions.insurance_change"
        label="My insurance information has changed since my last order."
        value={values.questions.insurance_change}
        validate={x => (typeof x === "boolean" ? undefined : "Required")}
        options={[
          { value: true, text: "Yes" },
          { value: false, text: "No" }
        ]}
      />
      <FieldArray
        name="insurances"
        render={arrayHelpers => (
          <div>
            {values.insurances?.map((_, index) => (
              <div key={index} style={{ padding: 5 }}>
                <label>{getInsuranceDisplayName(index) + " Insurance"}</label>
                {errors.insurances && errors.insurances[index] && (
                  <div style={{ color: "red" }}>
                    {errors.insurances[index].insurance_company_id}
                  </div>
                )}
                <SearchableDropdown
                  options={[
                    { text: "", value: "", key: "" },
                    ...insuranceOptions
                  ]}
                  selected={values.insurances[index]?.insurance_company_id}
                  disabled={disabled}
                  onSelect={value => {
                    const insuranceCompany = insuranceOptions?.find(
                      x => x?.value === value
                    );
                    setFieldValue(`insurances.${index}`, {
                      ...values.insurances[index],
                      insurance_company_id: value,
                      insurance_company_name: insuranceCompany?.text,
                      primary_order: index + 1,
                      requires_dysfunctions:
                        insuranceCompany?.requires_dysfunctions
                    });
                  }}
                />
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    alignItems: "center",
                    gridGap: 10
                  }}
                >
                  <FormikField
                    name={`insurances.${index}.policy_number`}
                    type="text"
                    label="Policy Number"
                    disabled={disabled}
                  />
                  <FormikField
                    name={`insurances.${index}.group_number`}
                    type="text"
                    label="Group Number"
                    disabled={disabled}
                  />
                  <FormikField
                    name={`insurances.${index}.guarantor_relation`}
                    component="select"
                    label="Guarantor Relationship"
                    disabled={disabled}
                  >
                    <option />
                    {guarantorOptions.map(({ text, value, key }) => (
                      <option key={key} value={value}>
                        {text}
                      </option>
                    ))}
                  </FormikField>
                  <FormikField
                    name={`insurances.${index}.policy_start_date`}
                    type="date"
                    label="Policy Start Date"
                    disabled={disabled}
                    value={formatDate(
                      values.insurances[index].policy_start_date,
                      "YYYY-MM-DD"
                    )}
                  />
                  {/* <FormikField */}
                  {/*   name={`insurances.${index}.policy_end_date`} */}
                  {/*   type="date" */}
                  {/*   label="Policy End Date" */}
                  {/*   InputLabelProps={{ shrink: true }} */}
                  {/*   value={formatDate( */}
                  {/*     values.insurances[index].policy_end_date, */}
                  {/*     "YYYY-MM-DD" */}
                  {/*   )} */}
                  {/*   disabled={disabled} */}
                  {/* /> */}
                  {index > 0 && !disabled && (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <button onClick={() => arrayHelpers.remove(index)}>
                        Delete
                      </button>
                    </div>
                  )}
                </div>
                {index == 0 && <hr style={{ margin: "5px 0px" }} />}
              </div>
            ))}
            {values.insurances.length == 1 && !disabled && (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <button
                  type="button"
                  style={{ margin: 0, padding: "5px 15px" }}
                  onClick={() =>
                    arrayHelpers.push({
                      insurance_company_id: "",
                      plan_number: "",
                      group_number: "",
                      guarantor_relation: "",
                      policy_start_date: "",
                      primary_order: values.insurances.length + 1
                    })
                  }
                >
                  Add Secondary Insurance
                </button>
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
};

InsuranceForm.propTypes = {
  patientInsurances: PropTypes.arrayOf(PropTypes.object).isRequired,
  insuranceOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default InsuranceForm;
