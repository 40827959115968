import * as api from "utils/api";
import * as msg from "actions/message";
import { createTab } from "actions/system-tray";

export const Types = {
  AUTO_DIALER_INIT: "AUTO_DIALER_INIT",
  OUTREACH_AUTO_DIALER_INIT: "OUTREACH_AUTO_DIALER_INIT",
  COMPLIANCE_OUTREACH_AUTO_DIALER_INIT: "COMPLIANCE_OUTREACH_AUTO_DIALER_INIT",
  AUTO_DIALER_END: "AUTO_DIALER_END",
  AUTO_DIALER_SET_PAGE: "AUTO_DIALER_SET_PAGE",
  AUTO_DIALER_SET_STATUS: "AUTO_DIALER_SET_STATUS",
  AUTO_DIALER_NEXT_PATIENT: "AUTO_DIALER_NEXT_PATIENT",
  AUTO_DIALER_ANSWER_QUEUE: "AUTO_DIALER_ANSWER_QUEUE",
  AUTO_DIALER_START_TEXT_CHAT: "AUTO_DIALER_START_TEXT_CHAT",
  AUTO_DIALER_WRAPUP_STARTED: "AUTO_DIALER_WRAPUP_STARTED",
  AUTO_DIALER_UPDATE_WRAPUP_TIME: "AUTO_DIALER_UPDATE_WRAPUP_TIME",
  AUTO_DIALER_WRAPUP_COMPLETED: "AUTO_DIALER_WRAPUP_COMPLETED",
  AUTO_DIALER_SKIP_WRAPUP: "AUTO_DIALER_SKIP_WRAPUP",
  AUTO_DIALER_PAUSED: "AUTO_DIALER_PAUSED",
  AUTO_DIALER_CONTINUE: "AUTO_DIALER_CONTINUE",
  AUTO_DIALER_PROCCESS_NEXT: "AUTO_DIALER_PROCCESS_NEXT",
  AUTO_DIALER_SET_WRAPUP_TIME_LIMIT: "AUTO_DIALER_SET_WRAPUP_TIME_LIMIT",
  AUTO_DIALER_STARTED: "AUTO_DIALER_STARTED",
  AUTO_DIALER_ERROR: "AUTO_DIALER_ERROR",
  AUTO_DIALER_SET_FILTERS: "AUTO_DIALER_SET_FILTERS",
  AUTO_DIALER_SET_FILTERS_INDEX: "AUTO_DIALER_SET_FILTERS_INDEX",
  AUTO_DIALER_FETCH_NEXT_BATCH: "AUTO_DIALER_FETCH_NEXT_BATCH",
  AUTO_DIALER_SET_IS_FETCHING_BATCH: "AUTO_DIALER_SET_IS_FETCHING_BATCH",
  AUTO_DIALER_APPEND_NEXT_BATCH: "AUTO_DIALER_APPEND_NEXT_BATCH",
  AUTO_DIALER_RESET_PATIENTS: "AUTO_DIALER_RESET_PATIENTS",
  AUTO_DIALER_RECORD_END_REASON: "AUTO_DIALER_RECORD_END_REASON",
  AUTO_DIALER_SET_COMPANY: "AUTO_DIALER_SET_COMPANY",
  AUTO_DIALER_CALL_PROMPT: "AUTO_DIALER_CALL_PROMPT",
  AUTO_DIALER_SET_CURRENT_PATIENT: "AUTO_DIALER_SET_CURRENT_PATIENT"
};
export const setAutoDialerPaused = paused => ({
  type: Types.AUTO_DIALER_PAUSED,
  payload: { paused: paused }
});

export const resetAutoDialerPatients = () => ({
  type: Types.AUTO_DIALER_RESET_PATIENTS
});

export const setAutoDialerIsFetchingBatch = isFetching => ({
  type: Types.AUTO_DIALER_SET_IS_FETCHING_BATCH,
  payload: { isFetching }
});

export const setAutoDialerFilters = (propertyName, value, index) => ({
  type: Types.AUTO_DIALER_SET_FILTERS,
  payload: { propertyName, value, index }
});

export const setAutoDialerFiltersIndex = index => ({
  type: Types.AUTO_DIALER_SET_FILTERS_INDEX,
  payload: { index }
});

export const enableAutoDialer = ids => ({
  type: Types.AUTO_DIALER_INIT,
  payload: { ids }
});

export const enableOutreachAutoDialer = () => ({
  type: Types.OUTREACH_AUTO_DIALER_INIT
});

export const enableComplianceOutreachAutoDialer = () => ({
  type: Types.COMPLIANCE_OUTREACH_AUTO_DIALER_INIT
});

export const activityCenterStart = (user_id, team_id) => async dispatch => {
  try {
    await api.put(`patients/autodialer/deallocate/FE/${user_id}`, null);

    const assigned_patients = await api.put(
      `patients/activityCenter/${user_id}`,
      {
        team_id
      }
    );
    if (assigned_patients?.length == 0) {
      dispatch(msg.errorMessage("No eligible patients could be found"));
      return;
    }

    dispatch({
      type: Types.AUTO_DIALER_INIT,
      payload: {
        ids: assigned_patients.map(x => x.patient_guid),
        autoDialerType: "ActivityOutreach"
      },
      meta: { team_id }
    });

    dispatch(
      createTab({
        type: "patient-outreach",
        key: "patient-outreach",
        label: `Patients (${assigned_patients.length})`,
        meta: { ids: assigned_patients.map(x => x.patient_guid) }
      })
    );
  } catch (e) {
    dispatch(
      msg.errorMessage("Something went wrong, failed to start activity center")
    );
  }
};

export const endAutoDialer = () => ({
  type: Types.AUTO_DIALER_END
});

export const completeWrapupTime = () => ({
  type: Types.AUTO_DIALER_WRAPUP_COMPLETED
});

export const autoSkipWrapupTime = () => ({
  type: Types.AUTO_DIALER_SKIP_WRAPUP
});

export const autoDialerSetActivePage = pageIndex => ({
  type: Types.AUTO_DIALER_SET_PAGE,
  payload: { pageIndex }
});

export const autoDialerProcessPatient = patientId => ({
  type: Types.AUTO_DIALER_PROCESS_PATIENT,
  payload: { patientId }
});

export const autoDialerProcessNext = () => ({
  type: Types.AUTO_DIALER_CONTINUE
});

export const setDialerCallPromptStatus = isOpen => ({
  type: Types.AUTO_DIALER_CALL_PROMPT,
  payload: isOpen
});
export const setDialerCurrentPatient = patientId => ({
  type: Types.AUTO_DIALER_SET_CURRENT_PATIENT,
  patientId
});

export const recordEndAutodialerReason = autoDialerEndReason => ({
  type: Types.AUTO_DIALER_RECORD_END_REASON,
  payload: { autoDialerEndReason }
});
