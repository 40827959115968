import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import Pages from "features/pages";
import OrderProfile from "components/order-profile-new";
import OrderTitle from "./order-title";

function BatchOrderView({ orderIds, orderTitles, userRole }) {
  return (
    <Pages
      showNext
      showPageNumber
      showBack={userRole !== "SleepCoach"}
      showSelect={userRole !== "SleepCoach"}
      pages={orderIds.map((orderId, index) => ({
        title:
          orderTitles?.length > 0
            ? orderTitles[index]
            : () => <OrderTitle orderId={orderId} index={index} />,
        view: <OrderProfile orderId={orderId} batchView={true} />
      }))}
    />
  );
}

export default connect(state => ({
  orderIds: selectors.getOrderBatchIds(state),
  orderTitles: selectors.getOrderBatchTitles(state),
  userRole: selectors.getUserRole(state)
}))(BatchOrderView);

BatchOrderView.propTypes = {
  orderIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  orderTitles: PropTypes.arrayOf(PropTypes.string),
  userRole: PropTypes.string
};
