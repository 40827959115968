import React from "react";
import PropTypes from "prop-types";
import { formatPhone } from "utils/misc";

const styles = {
  container: {
    padding: 5,
    marginLeft: 40,
    marginRight: 20,
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "10"
  },
  label: { fontWeight: 800, marginRight: 10 }
};

const PhysicianInfo = ({ physician }) => {
  if (!physician) return null;
  return (
    <div style={styles.container}>
      <div>
        <div>
          <span style={styles.label}>Name:</span>
          {physician.name}
        </div>
        <div>
          <span style={styles.label}>NPI #:</span>
          {physician.npi}
        </div>
        <div>
          <span style={styles.label}>Phone:</span>
          {formatPhone(physician.phone)}
        </div>
        <div>
          <span style={styles.label}>Fax:</span>
          {formatPhone(physician.fax)}
        </div>
      </div>
      <div>
        <div>
          <span style={styles.label}>Address:</span>
          {physician.address.street_address}
        </div>
        <div>
          <span style={styles.label}>City:</span>
          {physician.address.city}
        </div>
        <div>
          <span style={styles.label}>Zip:</span>
          {physician.address.zip}
        </div>
        <div>
          <span style={styles.label}>State:</span>
          {physician.address.state}
        </div>
      </div>
    </div>
  );
};

PhysicianInfo.propTypes = {
  physician: PropTypes.shape({
    name: PropTypes.string,
    npi: PropTypes.number,
    phone: PropTypes.string,
    fax: PropTypes.string,
    address: PropTypes.shape({
      street_address: PropTypes.string,
      city: PropTypes.string,
      zip: PropTypes.string,
      state: PropTypes.string
    })
  })
};

export default PhysicianInfo;
