import React from "react";
import PropTypes from "prop-types";
import { ChartHeader } from "components/ui";
import FaFileExcelO from "react-icons/lib/fa/file-excel-o";
import ReactTooltip from 'react-tooltip';
import {
  ResponsiveContainer,
  LineChart,
  Tooltip,
  Legend,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Brush
} from "recharts";
import { graphColors } from "utils/styles";

const OrdersByOutreachCycle = ({ data, onExport }) => {
  const [selectedLine, setSelectedLine] = React.useState();

  const handleMouseEnter = ({ dataKey }) => {
    setSelectedLine(dataKey);
  };

  const handleMouseLeave = () => {
    setSelectedLine();
  };
  return (
    <div
      style={{
        border: "1px solid #DDD",
        padding: "16px 20px 20px 20px",
        position: "relative"
      }}
    >
      <ReactTooltip />
      {onExport && (<div className="stat-export-icon" data-tip="Export All" onClick={onExport}><FaFileExcelO /></div>)}
      <ChartHeader title="Orders By Outreach Cycle" />
      <ResponsiveContainer width="100%" height={275}>
        <LineChart
          data={data}
          margin={{
            top: 20,
            right: 20,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" padding={{ left: 25 }} />
          <YAxis padding={{ bottom: 10 }} />
          <Tooltip />
          <Brush dataKey='name' height={25} stroke="#4b4e4f" endIndex={9} />
          <Legend
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <Line
            type="monotone"
            dataKey="autoship"
            stroke={graphColors[3]}
            strokeWidth={selectedLine === "autoship" ? 3 : 1}
          />
          <Line
            type="monotone"
            dataKey="email"
            stroke={graphColors[4]}
            strokeWidth={selectedLine === "email" ? 3 : 1}
          />
          <Line
            type="monotone"
            dataKey="ivr"
            stroke={graphColors[5]}
            strokeWidth={selectedLine === "ivr" ? 3 : 1}
          />
          <Line
            type="monotone"
            dataKey="manual_call"
            stroke={graphColors[6]}
            strokeWidth={selectedLine === "manual_call" ? 3 : 1}
          />
          <Line
            type="monotone"
            dataKey="push_notification"
            stroke={graphColors[7]}
            strokeWidth={selectedLine === "push_notification" ? 3 : 1}
          />
          <Line
            type="monotone"
            dataKey="text_message"
            stroke={graphColors[8]}
            strokeWidth={selectedLine === "text_message" ? 3 : 1}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default OrdersByOutreachCycle;

OrdersByOutreachCycle.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      autoship: PropTypes.number,
      email: PropTypes.number,
      manual_call: PropTypes.number,
      push_notification: PropTypes.number,
      text_message: PropTypes.number
    })
  ).isRequired,
  onExport: PropTypes.func.isRequired
};
