import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MdArrowDropdown from "react-icons/lib/md/arrow-drop-down";
import MdArrowUp from "react-icons/lib/md/arrow-drop-up";
import CoachLoginsTable from "components/screens/team-dashboard/coach-logins/coach-logins-table";

const ComplianceTeamCoachLogins = ({ data, minimizeAll, requestedDates }) => {
  const [isMinimized, setMinimized] = useState(false);

  useEffect(() => {
    setMinimized(minimizeAll);
  }, [minimizeAll, setMinimized]);
  
  return (
    <div className="block open-orders">
      <div className="block-header">
        <span onClick={() =>{
           setMinimized(!isMinimized)}}>
          Logins
          <i className="flat-icon-button">
            {isMinimized ? <MdArrowDropdown /> : <MdArrowUp />}
          </i>
        </span>
      </div>
      {!isMinimized && (
        <div className="block-content">
          <CoachLoginsTable data={data} dates={requestedDates}/>
        </div>
      )}
    </div>
  );
};

ComplianceTeamCoachLogins.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  minimizeAll: PropTypes.bool,
  requestedDates: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string
  })
};

export default ComplianceTeamCoachLogins;
