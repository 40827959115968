import { useDispatch, connect } from "react-redux";
import { post } from "utils/api";
import { errorMessage, message as successMsg } from "actions/message";
import { getApiErrorMessageCustomPathEnd } from "utils/misc";
import { Form, Formik } from "formik";
import FormikField from "components/forms/formik-field";
import { Spinner } from "components/ui";
import PropTypes from "prop-types";
import React from "react";
import { selectors } from "reducers";
import moment from "moment";

export const TimeAddModal = ({ closeModal, data, getHistoryOfLogins }) => {
  const dispatch = useDispatch();
  const validateEmpty = value => {
    let errorMessage;
    if (value === "" || value === 0) errorMessage = "Required";
    return errorMessage;
  };
  const defaultStart = moment(new Date())
    .hours(8)
    .minutes(0)
    .seconds(0)
    .format("YYYY-MM-DDTHH:mm:ss");

  const defaultEnd = moment(new Date())
    .hours(17)
    .minutes(0)
    .seconds(0)
    .format("YYYY-MM-DDTHH:mm:ss");

  const isValidEndTime = (start, end) => {
    if (start >= end) return "End time is before start time";
  };

  const updateSleepcoachTime = async values => {
    try {
      const response = await post(`users/add_time`, values);
      dispatch(successMsg(response));
      closeModal();
      getHistoryOfLogins();
    } catch (e) {
      dispatch(
        errorMessage(
          `Failed to add coach time. ${getApiErrorMessageCustomPathEnd(
            e,
            "message"
          )}`
        )
      );
      closeModal();
    }
  };

  return (
    <Formik
      initialValues={{
        start_time: defaultStart,
        end_time: defaultEnd,
        coach_guid: null,
        event_type: "Connection"
      }}
      onSubmit={data => {
        updateSleepcoachTime(data);
      }}
    >
      {({ setFieldValue, isSubmitting, values }) => (
        <Form style={{ padding: "20px" }}>
          <h3>Add Time</h3>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              gap: 20
            }}
          >
            <div>
              <FormikField
                label="Sleep Coach"
                name="coach_guid"
                component="select"
                validate={validateEmpty}
              >
                <option value="" />
                {data.map(({ Coach, COACH_GUID }) => (
                  <option key={COACH_GUID} value={COACH_GUID}>
                    {Coach}
                  </option>
                ))}
              </FormikField>

              <FormikField label="Type" name="event_type" component="select">
                <option value="Connection">Connection</option>
                <option value="AutoDialer">AutoDialer</option>
              </FormikField>

              <FormikField
                name="start_time"
                label="Start Time"
                type="datetime-local"
                value={values.start_time}
                validate={validateEmpty}
                disabled={isSubmitting}
                onChange={e => {
                  setFieldValue("start_time", e.target.value);
                }}
              />
              <FormikField
                name="end_time"
                label="End Time"
                type="datetime-local"
                value={values.end_time}
                validate={
                  (validateEmpty,
                  () => isValidEndTime(values.start_time, values.end_time))
                }
                disabled={isSubmitting}
                onChange={e => {
                  setFieldValue("end_time", e.target.value);
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              type="button"
              style={{ marginRight: 30 }}
              className="button secondary"
              disabled={isSubmitting}
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </button>

            <button
              type="submit"
              className="button"
              disabled={isSubmitting || values.coach_guid == null}
            >
              {isSubmitting ? <Spinner /> : "Save"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

TimeAddModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  getHistoryOfLogins: PropTypes.func.isRequired
};

export default connect(state => ({
  data: selectors.getTeamCoachInsights(state)
}))(TimeAddModal);
