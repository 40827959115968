import React, { useEffect, useState, useContext } from "react";
import CompanyPatients from "./company-patients";
import ComplianceTeamDashboardContext from "../context";
import ComplianceTeamCompanyContacts from "./contacts";
import { useFetch } from "hooks";
import { put } from "utils/api";
import { path } from "ramda";
import { errorMessage } from "actions/message";
import { Spinner } from "components/ui";

const ComplianceTeamCompanies = () => {
  const filters = useContext(ComplianceTeamDashboardContext);
  const [patientsByCompany, setPatientsByCompany] = useState([]);
  const [companyContacts, setCompanyContacts] = useState([]);

  const {
    fetchData: fetchCompanyPatients,
    isFetching: fetchingCompanyPatients
  } = useFetch({
    apiFn: () =>
      put(`compliance-team/companies/patients`, {
        TeamName: filters.selectedTeam,
        FromDate: filters.requestedDates.start_date,
        ToDate: filters.requestedDates.end_date
      }),
    defaultValue: [],
    transformError: path(["response", "body", "status"]),
    onError: err => errorMessage(`Failed to get coaches data: ${err}`)
  });

  const getElegiblePatientsByCompany = async () => {
    const res = await fetchCompanyPatients();
    if (res) setPatientsByCompany(res);
  };

  const {
    fetchData: fetchCompanyContacts,
    isFetching: fetchingCompanyContacts
  } = useFetch({
    apiFn: () =>
      put(`compliance-team/company-contacts`, {
        ToDate: filters.requestedDates.end_date,
        FromDate: filters.requestedDates.start_date,
        TeamName: filters.selectedTeam
      }),
    defaultValue: [],
    transformError: path(["response", "body", "status"]),
    onError: err => {
      return errorMessage(`Failed to get coach contacts: ${err}`);
    }
  });

  const getCompanyContacts = async () => {
    const res = await fetchCompanyContacts();
    if (res) {
      setCompanyContacts(res);
    }
  };

  useEffect(() => {
    if (filters.selectedTeam) getElegiblePatientsByCompany();
  }, [filters.selectedTeam]);

  useEffect(() => {
    if (filters.requestedDates.start_date && filters.requestedDates.end_date) {
      getCompanyContacts();
    }
  }, [filters.requestedDates.start_date, filters.requestedDates.end_date]);

  return (
    <div className="compliance-team-dashboard-container">
      {!fetchingCompanyPatients ? (
        <CompanyPatients
          minimizeAll={filters.minimizeAll}
          data={patientsByCompany}
        />
      ) : (
        <Spinner />
      )}
      {!fetchingCompanyContacts ? (
        <ComplianceTeamCompanyContacts
          requestedDates={filters.requestedDates}
          minimizeAll={filters.minimizeAll}
          data={companyContacts}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
};

ComplianceTeamCompanies.defaultProps = {};

ComplianceTeamCompanies.propTypes = {};

export default ComplianceTeamCompanies;
