import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { InlineWaiting } from "components/ui";
import { useSelector, useDispatch } from "react-redux";
import { message, errorMessage } from "actions/message";
import { selectors } from "reducers";
import { MdSend, MdUpdate } from "react-icons/lib/md";
import { OrderProfileContext } from "./context";
import { useFetch } from "hooks";
import { post } from "utils/api";

export const NikoHealthLink = ({ niko_info, account_number }) => {
  return (
    <div style={{ fontSize: "medium", fontWeight: "bold" }}>
      <a
        href={
          niko_info?.niko_order_id != ""
            ? `${niko_info?.niko_url}v2/#/orders/order/${niko_info?.niko_order_id}/details`
            : `${niko_info?.niko_url}v2/#/patients/${account_number}/demographics`
        }
        target="_blank"
        rel="noreferrer"
      >
        {niko_info?.niko_order_id != ""
          ? "Niko Health Order Details"
          : "Niko Health User Profile"}
      </a>
    </div>
  );
};

NikoHealthLink.propTypes = {
  niko_info: PropTypes.object,
  account_number: PropTypes.string
};

export const SendOrderToNHButton = ({ orderId }) => {
  const userId = useSelector(selectors.getUserId);
  const [isFetching, setIsFetching] = useState(false);

  const { fetchOrderProfile } = useContext(OrderProfileContext);
  const dispatch = useDispatch();

  const initiateSendOrderToNikoHealthApi = useFetch({
    apiFn: ({ userId, orderId }) =>
      post(`Niko/OrderExport/${userId}/${orderId}`),
    onSuccess: () => {
      dispatch(message("Order Exported Successfully"));
    },
    onError: error => {
      dispatch(errorMessage(`Order export failed, ${error}`));
    }
  });

  return (
    <div
      className={`copy-to-clipboard-button print-none`}
      title={"Send order to Niko Health"}
    >
      {isFetching ? (
        <InlineWaiting />
      ) : (
        <MdSend
          color="grey"
          size="28px"
          onClick={async () => {
            try {
              if (userId !== "" && orderId !== "") {
                setIsFetching(true);
                await initiateSendOrderToNikoHealthApi.fetchData({
                  userId,
                  orderId
                });
                fetchOrderProfile(orderId);
              } else {
                window.alert("Please wait for order to finish loading.");
              }
            } finally {
              setIsFetching(false);
            }
          }}
        />
      )}
    </div>
  );
};

SendOrderToNHButton.propTypes = {
  orderId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  initiateSendOrderToNikoHealth: PropTypes.func.isRequired
};

export const UpdateOrderFromNHButton = ({ orderId }) => {
  const [isFetching, setIsFetching] = useState(false);
  const userId = useSelector(selectors.getUserId);
  const { fetchOrderProfile, fetchOrderLineItemsApi } =
    useContext(OrderProfileContext);
  const dispatch = useDispatch();

  const initiateRetrieveOrderFromNikoHealth = useFetch({
    apiFn: ({ userId, orderId }) =>
      post(`Niko/OrderImport/${userId}/${orderId}`),
    onSuccess: () => {
      dispatch(message("Order Updated Successfully"));
    },
    onError: error => {
      dispatch(errorMessage(`Order update failed, ${error}`));
    }
  });
  return (
    <span
      className={`copy-to-clipboard-button print-none`}
      title={"Update order from Niko Health"}
    >
      {isFetching ? (
        <InlineWaiting />
      ) : (
        <MdUpdate
          color="grey"
          size="28px"
          onClick={async () => {
            try {
              if (userId !== "" && orderId !== "") {
                setIsFetching(true);
                await initiateRetrieveOrderFromNikoHealth.fetchData({
                  userId,
                  orderId
                });
                fetchOrderProfile(orderId);
                fetchOrderLineItemsApi.fetchData(orderId);
              } else {
                window.alert("Please wait for order to finish loading.");
              }
            } finally {
              setIsFetching(false);
            }
          }}
        />
      )}
    </span>
  );
};

UpdateOrderFromNHButton.propTypes = {
  orderId: PropTypes.string.isRequired
};
