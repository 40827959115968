import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CoachEnteredOrdersTable from "./entered-table";
import MdArrowDropdown from "react-icons/lib/md/arrow-drop-down";
import MdArrowUp from "react-icons/lib/md/arrow-drop-up";

const ComplianceCoachOrders = ({ minimizeAll, data }) => {
  const [isMinimized, setMinimized] = useState(false);

  useEffect(() => {
    setMinimized(minimizeAll);
  }, [minimizeAll, setMinimized]);

  return (
    <div className="block coach-orders">
      <div className="block-header">
        <span onClick={() => setMinimized(!isMinimized)}>
          Coach Orders
          <i className="flat-icon-button">
            {isMinimized ? <MdArrowDropdown /> : <MdArrowUp />}
          </i>
        </span>
      </div>
      {!isMinimized && (
        <div className="block-content">
          <CoachEnteredOrdersTable data={data} />
        </div>
      )}
    </div>
  );
};

ComplianceCoachOrders.propTypes = {
  minimizeAll: PropTypes.bool,
  data: PropTypes.array,
};

export default ComplianceCoachOrders;
