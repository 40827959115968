import React from "react";
import PropTypes from "prop-types";
import { Waiting } from "../../../../ui/waiting";

export const CoachContactModal = ({
  modalType,
  breakdown,
  modalTotal,
  isFetching
}) => {
  return (
    <>
      <div className="modalContainer">
        <div className="modalTitle">
          {breakdown.length ? breakdown[0].coach : ""}
        </div>
        <div className="breakdownContainer--listItem modalHeader">
          <div>Company</div> <div>{modalType}</div>
        </div>
        {isFetching ? (
          <Waiting size={"80px"} />
        ) : breakdown.length ? (
          breakdown.map((bd, index) => {
            return (
              <div
                key={bd.company}
                className={
                  (index + 1) % 2 === 0
                    ? "breakdownContainer--listItem"
                    : "breakdownContainer--listItem modalRow"
                }
              >
                <div>{bd.company}:</div> <div>{bd.calls}</div>
              </div>
            );
          })
        ) : (
          <div className="breakdownContainer--listItem">No Data To Display</div>
        )}
        <div className="breakdownContainer--listItem borderTop">
          <div>Total:</div>
          <div>{modalTotal}</div>
        </div>
      </div>
    </>
  );
};

CoachContactModal.propTypes = {
  modalType: PropTypes.string,
  breakdown: PropTypes.arrayOf(PropTypes.object),
  modalTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isFetching: PropTypes.bool
};
