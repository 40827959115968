import React from "react";
import PropTypes from "prop-types";
import InlineWaiting from "components/ui/inline-waiting";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";

const validateNumber = value => {
  let errorMessage;
  if (value < 0) errorMessage = "the field must be greater than zero";
  return errorMessage;
};

const AddInventoryItemByIdForm = ({
  addItemByID,
  withQuantity,
  disabled
}) => (
  <Formik
    initialValues={{
      product_id: "",
      addQuantity: ""
    }}
    validate={values => {
      let errors = {};
      if (!values.product_id) {
        errors.product_id = "Required";
      }
      if (withQuantity && !values.addQuantity) {
        errors.addQuantity = "Required";
      }
      return errors;
    }}
    onSubmit={async (values, { resetForm }) => {
      if (values.addQuantity > 9) {
        if (
          window.confirm(
            `Are you sure you want to set quantity to ${values.addQuantity} for this item?`
          )
        ) {
          await addItemByID(values);
          resetForm();
        }
      } else {
        await addItemByID(values);
        resetForm();
      }
    }}
  >
    {({ isValid, isSubmitting }) => (
      <Form>
        <div className="add-equipmentById-form">
          <FormikField name="product_id" label="Product Id*" type="text" disabled={disabled} />
          {withQuantity && (
            <FormikField
              name="addQuantity"
              label="Quantity"
              type="number"
              disabled={disabled}
              validate={validateNumber}
            />
          )}
          {isSubmitting ? (
            <InlineWaiting />
          ) : (
            <button
              className="submit-button"
              type="submit"
              disabled={disabled || !isValid}
            >
              Add Item By Id
            </button>
          )}
        </div>
      </Form>
    )}
  </Formik>
);

export default AddInventoryItemByIdForm;

AddInventoryItemByIdForm.propTypes = {
  addItemByID: PropTypes.func.isRequired,
  withQuantity: PropTypes.bool,
  disabled: PropTypes.bool
};

AddInventoryItemByIdForm.defaultProps = {
  disabled: false
};
