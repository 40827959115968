import React from "react";
import PropTypes from "prop-types";
import { path, isNil } from "ramda";
import { Link } from "react-router-dom";
import { FieldTable, Rows } from "components/ui";
import Subheader from "components/order-profile/subheader";
import Updates from "components/order-profile/updates";
import { formatPhone, capitalize } from "utils/misc";
import { ShippingCard } from "components/order-profile/shipping";
import BarcodeDisplay from "components/screens/order/barcode-display";
import { getEquipmentQuestions } from "utils/order-equipment-questions";
import { formatDate } from "utils/dates";

const PrintOrder = ({ order }) => {
  const physicianInfo = {
    ...(order.patient_info?.physician ?? {}),
    ...(order.patient_info?.physician?.address ?? {})
  };
  return (
    <div
      className="order-profile-print-page"
      style={{ pageBreakAfter: "always" }}
    >
      <div className="order-profile-print-page">
        <div className="profile-header">
          <div className="thirty-day">{`Patient requested ${order.order_info?.supply_period}-day supplies.`}</div>
          <div className="main-header">
            <h1>
              <Link
                to={`/patients/${path(["patient_info", "patient_id"], order)}`}
              >
                {path(["patient_info", "patient_name"], order)}
              </Link>
            </h1>
          </div>
          <div className="order-status">
            {capitalize(order.order_info?.status_text, true)}
          </div>
        </div>
        <div className="order-information">
          <div className="order-information-header">
            <div className="order-profile-header">
              <h4>Order Information</h4>
            </div>
          </div>
          <FieldTable
            columnCount={2}
            rows={[
              [
                "Sales Order",
                path(["order_info", "sales_order_number"], order)
              ],
              ["Account Number", path(["order_info", "account_number"], order)],
              ["Invoice Number", path(["order_info", "invoice_number"], order)],
              ["Street Address", path(["order_info", "street_address"], order)],
              [
                "Street Address Line Two",
                path(["order_info", "street_address2"], order)
              ],
              ["City", path(["order_info", "city"], order)],
              ["State", path(["order_info", "state"], order)],
              ["ZIP", path(["order_info", "zip"], order)],
              [
                "Order Date",
                formatDate(
                  path(["order_info", "order_date"], order),
                  "MMMM Do YYYY, h:mm a"
                )
              ],
              ["Company", path(["order_info", "company"], order)],
              [
                "Sleep Coach",
                path(["order_info", "sleep_coach_patient_current"], order)
              ],
              [
                "Assignment",
                path(["order_info", "sleep_coach_assignment_name"], order)
              ],
              ["Admin", path(["order_info", "adminName"], order)],
              [
                "Phone Number",
                formatPhone(path(["order_info", "phone_number"], order))
              ],
              ["Insurance", path(["order_info", "insurance"], order)],
              ["Submitted By", path(["order_info", "submitted_by"], order)],
              [
                "Quantity Requested",
                isNil(path(["order_info", "supply_period"], order))
                  ? ""
                  : `${path(["order_info", "supply_period"], order)} day supply `
              ]
            ].map(([field, value]) => ({ field, value }))}
          />
        </div>
        <Updates
          physicianInfo={physicianInfo}
          compliance={order.compliance}
          orderInfo={order.order_info}
        />
        <div className="order-information">
          <div className="order-information-header">
            <div className="order-profile-header">
              <h4>Requested Equipment &amp; Dysfunctions</h4>
            </div>
          </div>
          {(order?.dysfunctional_equipment?.requested_equipment ?? []).map(
            (equipment, index) => (
              <div key={index}>
                <Subheader key={equipment}>{capitalize(equipment)}</Subheader>
                <Rows>
                  {getEquipmentQuestions(equipment)
                    .filter(q => q.equipment === equipment)
                    .map(({ key, text }) => (
                      <div key={key}>{text}</div>
                    ))}
                </Rows>
              </div>
            )
          )}
          {order.order_info.other_issues &&
            order.order_info.other_issues.trim() && [
              <Subheader key="issues-prompt">
                Please include any additional information/concerns regarding
                your PAP therapy:
              </Subheader>,
              <p key="issues-answer">{order.order_info.other_issues}</p>
            ]}
          {order.order_info.supplies_on_hand &&
            order.order_info.supplies_on_hand.trim() && [
              <Subheader key="supplies-prompt">
                Insurance requires us to document the amount of supplies you
                have on hand:
              </Subheader>,
              <p key="supplies-answer">{order.order_info.supplies_on_hand}</p>
            ]}
        </div>
      </div>
      <div
        className="order-profile-print-page"
        style={{ pageBreakBefore: "always" }}
      >
        <div className="packing-slip-header">
          <h2>Packing Slip</h2>
          <h5>{path(["order_info", "invoice_number"], order)}</h5>
        </div>
        <div className="order-information-header">
          <div className="order-profile-header">
            <h4>Shipping</h4>
          </div>
        </div>
        <div className="package-shipping">
          <ShippingCard
            header="Ship To:"
            name={path(["patient_info", "patient_name"], order)}
            street_address={path(["order_info", "street_address"], order)}
            street_address2={path(["order_info", "street_address2"], order)}
            city={path(["order_info", "city"], order)}
            state={path(["order_info", "state"], order)}
            zip={path(["order_info", "zip"], order)}
          />
          <ShippingCard
            header="Ship From:"
            name={path(["company_info", "company_name"], order)}
            street_address={path(["company_info", "street_address"], order)}
            city={path(["company_info", "city"], order)}
            state={path(["company_info", "state"], order)}
            zip={path(["company_info", "zip"], order)}
          />
        </div>
        <div className="packing-slip-body">
          <div className="equipment-requested">
            <h4>Equipment Requested</h4>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Manufacturer ID</th>
                  <th>Company ID</th>
                  <th>Manufacturer</th>
                  <th>HCPCS</th>
                  <th>Product</th>
                  <th>Qty</th>
                </tr>
              </thead>
              <tbody>
                {order?.equipment_requested?.map(equipment => (
                  <tr key={equipment.id}>
                    <td>{equipment.type}</td>
                    <td>{equipment.id}</td>
                    <td>{equipment.company_product_id ?? equipment.id}</td>
                    <td>{equipment.manufacturer}</td>
                    <td>{equipment.HCPCS}</td>
                    <td>{equipment.Product}</td>
                    <td>{equipment.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="equipment-requested">
              <h4>Tracking</h4>
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Product ID</th>
                    <th>Ship Date</th>
                    <th>Carrier</th>
                    <th>Tracking Number</th>
                  </tr>
                </thead>
                <tbody>
                  {order?.equipment_requested?.map(equipment => (
                    <tr key={equipment.id}>
                      <td>{equipment.id}</td>
                      <td>
                        {path(["shippingDetails", "shipDate"], equipment)}
                      </td>
                      <td>{path(["shippingDetails", "carrier"], equipment)}</td>
                      <td>
                        {path(
                          ["shippingDetails", "tracking_number"],
                          equipment
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <BarcodeDisplay
              orderSalesNumber={order?.order_info?.sales_order_number}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

PrintOrder.propTypes = {
  order: PropTypes.object.isRequired
};

export default PrintOrder;
