import React, { useContext } from "react";
import { OrderProfileContext } from "../context";
import { formatDate } from "utils/dates";
import { MdContentCopy } from "react-icons/lib/md";

const CopyToClipboard = () => {
  const [animate, setAnimate] = React.useState(false);
  const { order, fetchOrderNotesApi, fetchOrderLineItemsApi } =
    useContext(OrderProfileContext);
  const lineItems = fetchOrderLineItemsApi.response ?? [];
  const notes = fetchOrderNotesApi.response ?? [];

  function copyToClipboard() {
    const copyArea = document.getElementById(`copyArea-${order.order_id}`);
    if (document.body.createTextRange) {
      const range = document.body.createTextRange();
      range.moveToElementText(copyArea);
      range.select();
      document.execCommand("Copy");
    } else if (window.getSelection) {
      // other browser support
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(copyArea);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("Copy");
    }
    setAnimate(true);
  }

  return (
    <span
      className={`copy-to-clipboard-button print-none ${
        animate ? "copied-animation" : ""
      }`}
      id="copyToClipboard"
      onClick={() => {
        copyToClipboard();
      }}
      title="Copy To Clipboard"
      onAnimationEnd={() => setAnimate(false)}
    >
      <MdContentCopy size="30px" color="grey" />
      <div
        id={`copyArea-${order.order_id}`}
        contentEditable="true"
        suppressContentEditableWarning
        style={{
          position: "fixed",
          left: "-10000px",
          top: "-10000px",
          opacity: 0
        }}
      >
        <h4>{order.patient_name}</h4>
        <pre>Sales Order Number: {order.sales_order_number}</pre>
        <pre>Account Number: {order.account_number}</pre>
        <pre>Invoice Number: {order.invoice_number}</pre>
        <pre>
          Order Date: {formatDate(order.created_date, "MMMM Do YYYY, h:mm a")}
        </pre>
        <pre>Sleep Coach: {order.sleep_coach_assignment_name}</pre>
        <pre>Submitted By: {order.submitted_by_name}</pre>
        <pre>Quantity Requested: {`${order.supply_period} day supply`}</pre>
        <pre>
          Address:{" "}
          <p>
            {order.shipping_address?.street_address}
            <br />
            {order.shipping_address?.street_address2}
            {order.shipping_address?.street_address2 && <br />}
            {order.shipping_address?.city}, {order.shipping_address?.state},{" "}
            {order.shipping_address?.zip}
          </p>
        </pre>
        <pre>
          Physician: {order.patient_physician?.name}
          <p>
            {order.patient_physician?.street_address},{" "}
            {order.patient_physician?.city}, {order.patient_physician?.state},{" "}
            {order.patient_physician?.zip}
          </p>
        </pre>
        {/*
        <h4>Physician, Insurance, and Machine Updates</h4>
        {compliance.last_saw_physician && (
          <pre>
            I last saw my physician regarding sleep therapy{" "}
            {compliance.last_saw_physician} ago.
          </pre>
        )}
        {compliance.physician_changed ? (
          <pre>I have changed my physician since my last order</pre>
        ) : (
          <pre>
            No changes have been made to my physician since the last order.
          </pre>
        )}
        {compliance.issues ? (
          <pre>
            I am having issues with my PAP device. My current issue is
            regarding {compliance.issue_note}
          </pre>
        ) : (
          <pre>I am currently having no issues with my PAP therapy.</pre>
        )}
        {compliance.insurance_changed ? (
          <React.Fragment>
            <pre>
              I have changed insurance companies since my last order. My new
              insurance is:
            </pre>
            <pre>Insurance: {compliance.insurance_company}</pre>
            <pre> Subscriber Id: {compliance.insurance_subscriber_id}</pre>
          </React.Fragment>
        ) : (
          <pre>
            No changes have been made to my insurance since the last order
          </pre>
        )}
      */}
        <h4>Requested Equipment</h4>
        {order.equipment_requested?.map(equipment => (
          <span key={equipment}>
            <div>{equipment}</div>
          </span>
        ))}
        <br />
        {/*info.other_issues && info.other_issues.trim() && (
          <pre>Other Issues: {info.other_issues}</pre>
        )*/}
        {/*info.supplies_on_hand && info.supplies_on_hand.trim() && (
          <pre>Supplies On Hand: {info.supplies_on_hand}</pre>
        )*/}
        <h5>Line Items</h5>
        {lineItems.map(item => (
          <React.Fragment key={item.line_item_id}>
            <pre>Type: {item.product_type}</pre>
            <pre>Id: {item.company_item_id || item.manufacturer_id}</pre>
            <pre>Manufacturer: {item.manufacturer}</pre>
            <pre>HCPCS: {item.hcpc}</pre>
            <pre>Product: {item.item_name}</pre>
            <br />
          </React.Fragment>
        ))}
        <h5>Additional Notes</h5>
        {notes.map(({ id, creator, created_at, note }) => (
          <pre key={id}>
            {creator} {formatDate(created_at, "MMMM Do YYYY, h:mm a")}
            <br />
            {note}
          </pre>
        ))}
      </div>
    </span>
  );
};

export default CopyToClipboard;
