import React, { useEffect, useState } from "react";
import ComplianceCoachScorecardFilters from "./compliance-coach-scorecard-filters";
import ComplianceCoachScorecardByRangeFilters from "./compliance-coach-scorecard-by-range-filters";
import ComplianceCoachScorecardOverallDisplay from "./compliance-coach-scorecard-overall-display";
import { useFetch } from "hooks";
import { path } from "ramda";
import { put, post } from "../../../../../utils/api";
import { getApiErrorMessage } from "utils/misc";
import * as msg from "actions/message";
import ComplianceCoachScorecardByRangeDisplay from "./compliance-coach-scorecard-by-range-display";
import { PureModal } from "components/ui";
import ComplianceCoachScorecardPatients from "./compliance-coach-scorecard-patients";
import { browserHistory } from "browser-history";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

const ComplianceCoachScorecardTab = ({ companyId }) => {
  const today = new Date();
  const priorDate = new Date(new Date().setDate(today.getDate() - 120));
  const dispatch = useDispatch();

  const [modalFilters, setModalFilters] = useState({});
  const [isFetchingOverallData, setIsFetchingOverallData] = useState(false);
  const [isFetchingByRangeData, setIsFetchingByRangeData] = useState(false);

  const [overallCoachesData, setOverallCoachesData] = useState([]);
  const [byRangeCoachesData, setByRangeCoachesData] = useState([]);

  const [overallCoachesFilters, setOverallCoachesFilters] = useState({
    TeamId: "",
    CoachId: "",
    FromDate: "",
    ToDate: "",
    DateType: "",
    CompanyId: companyId
  });
  const [byRangeCoachesFilters, setByRangeCoachesFilters] = useState({
    ...overallCoachesFilters,
    FromDate: priorDate,
    ToDate: today,
    DateType: "SetupDate"
  });

  const { fetchData, isFetching } = useFetch({
    apiFn: payload => put(`compliance-dashboard/coach-scorecard`, payload),
    defaultValue: {},
    transformError: path(["response", "body", "status"]),
    onError: error => {
      dispatch(
        msg.errorMessage(
          `Failed to get compliance coaches scorecard: ${getApiErrorMessage(
            error
          )}`
        )
      );
    }
  });

  const { fetchData: exportCoachesAPI, isFetching: exportingCoaches } =
    useFetch({
      apiFn: payload =>
        post(`compliance-dashboard/coach-scorecard/export`, payload),
      defaultValue: null,
      transformError: path(["response", "body", "status"]),
      onError: error => {
        dispatch(
          errorMessage(
            `Error when trying to export patients by category. ${getApiErrorMessage(
              error
            )}`
          )
        );
      }
    });

  const fetchCoachesData = async (payload, source) => {
    const isByRange = source == "by-range";
    if (isByRange) {
      setIsFetchingByRangeData(true);
      setByRangeCoachesFilters(payload);
      await fetchByRange(payload);
      setIsFetchingByRangeData(false);
    } else {
      setIsFetchingOverallData(true);
      setIsFetchingByRangeData(true);

      await fetchOverall(payload);
      const newFromDate =
        payload.DateType == "" && payload.FromDate != ""
          ? payload.FromDate
          : byRangeCoachesFilters.FromDate;
      const newToDate =
        payload.DateType == "" && payload.ToDate != ""
          ? payload.ToDate
          : byRangeCoachesFilters.ToDate;

      const overallPlusDateRange = {
        ...payload,
        DateType: byRangeCoachesFilters.DateType,
        FromDate: newFromDate,
        ToDate: newToDate
      };
      await fetchByRange(overallPlusDateRange);
      setOverallCoachesFilters(payload);
      setByRangeCoachesFilters(overallPlusDateRange);

      setIsFetchingOverallData(false);
      setIsFetchingByRangeData(false);
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const fetchAll = async () => {
    setIsFetchingOverallData(true);
    setIsFetchingByRangeData(true);

    await fetchOverall(overallCoachesFilters);
    await fetchByRange(byRangeCoachesFilters);

    setIsFetchingOverallData(false);
    setIsFetchingByRangeData(false);
  };

  const fetchOverall = async filters => {
    const overallPts = await fetchData(filters);
    setOverallCoachesData(overallPts);
  };

  const fetchByRange = async filters => {
    const overallPts = await fetchData(filters);
    setByRangeCoachesData(overallPts);
  };

  const triggerModal = ({ type, coachId, origin }) => {
    const filters =
      origin == "Overall" ? overallCoachesFilters : byRangeCoachesFilters;
    setModalFilters({ ...filters, type, coachId });
    document.getElementById(`chartItemClicked`).click();
  };

  const exportCoaches = async table => {
    const payload =
      table == "overall" ? overallCoachesFilters : byRangeCoachesFilters;
    const exportSuccessful = await exportCoachesAPI(payload);
    if (exportSuccessful) {
      browserHistory.push("/exports");
    }
  };

  useEffect(() => {
    browserHistory.replace(
      `${browserHistory.location.pathname}?tab=CoachScorecard`
    );
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "5px",
          marginRight: "10px",
          borderBottom: "1px solid lightgrey"
        }}
      >
        <ComplianceCoachScorecardFilters
          fetchData={fetchCoachesData}
          isFetching={isFetching || exportingCoaches}
          companyId={companyId}
        />
      </div>
      {overallCoachesData && (
        <ComplianceCoachScorecardOverallDisplay
          isFetching={isFetchingOverallData}
          data={overallCoachesData}
          openModal={type => triggerModal(type)}
          exportCoaches={exportCoaches}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "5px",
          marginRight: "10px",
          borderBottom: "1px solid lightgrey"
        }}
      >
        <ComplianceCoachScorecardByRangeFilters
          fetchData={fetchCoachesData}
          isFetching={isFetching || exportingCoaches}
          companyId={companyId}
          selectedCoach={overallCoachesFilters.CoachId}
          selectedTeam={overallCoachesFilters.TeamId}
        />
      </div>
      {byRangeCoachesData && (
        <ComplianceCoachScorecardByRangeDisplay
          isFetching={isFetchingByRangeData}
          data={byRangeCoachesData}
          openModal={type => triggerModal(type)}
          exportCoaches={exportCoaches}
        />
      )}

      <PureModal
        windowProps={{ large: true, modalZIndex: 6, modalBehindModal: true }}
        renderTrigger={({ openModal }) => (
          <div id="chartItemClicked" onClick={openModal}></div>
        )}
        renderContent={({ closeModal }) => (
          <ComplianceCoachScorecardPatients
            filters={modalFilters}
            closeModal={closeModal}
          />
        )}
      />
    </div>
  );
};

ComplianceCoachScorecardTab.propTypes = {
  companyId: PropTypes.string
};

export default ComplianceCoachScorecardTab;
