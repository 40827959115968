import React, { useEffect } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { browserHistory } from "browser-history";
import ProtectedRoute from "./routes/protected-route";
import { useSelector, useDispatch } from "react-redux";
import { selectors } from "reducers";
import Login from "components/screens/login";
import {
  TwoFactorRequired,
  TwoFactorApproved
} from "components/two-factor-auth";
import SsoAuthenticate from "components/sso-authenticate";
import PasswordReset from "components/password-reset";
import PasswordExpired from "components/password-expired";
import Thanks from "components/screens/thanks";
import Phoenix from "./phoenix";
import SystemNotification from "components/ui/system-notification";
import AppRoutes from "./routes";
import { isUserTokenExpired } from "utils/misc";
import { messaging } from "./../init-fcm";
import { requestLoadUser } from "actions/authentication";

const registerFirebaseDevice = () => {
  try {
    messaging
      .requestPermission()
      .then(async function () {
        const token = await messaging.getToken();
        console.log("firebase token app");
        console.log(token);
        window.device_token = token;
      })
      .catch(function (err) {
        console.log("Unable to get permission to notify.", err);
      });
  } catch (err) {
    console.log(err);
  }
};

const App = () => {
  React.useEffect(() => {
    registerFirebaseDevice();
  }, []);

  return (
    <Router history={browserHistory}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/mfa/required" component={TwoFactorRequired} />
        <Route path="/mfa/approve" component={TwoFactorApproved} />
        <Route path="/password-reset/:token*" component={PasswordReset} />
        <Route path="/password-expired" component={PasswordExpired} />
        <Route path="/thank-you" component={Thanks} />
        <Route path="/sso*" component={SsoAuthenticate} />
        <MainApp />
      </Switch>
      <SystemNotification />
    </Router>
  );
};

export default App;

function MainApp() {
  const user = useSelector(selectors.getUser);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    dispatch(requestLoadUser());
    setIsLoading(false);
  }, []);
  if (isLoading) return null;
  return (
    <ProtectedRoute
      path="/"
      allowed={!isUserTokenExpired(user)}
      redirectPath="/login"
    >
      <Phoenix>
        <Switch>
          {AppRoutes.map(
            ({ component: Component, allowedRoles, allowedFn, ...route }) =>
              !allowedRoles ? (
                <Route key={route.path} {...route}>
                  <Component />
                </Route>
              ) : (
                <ProtectedRoute
                  key={route.path}
                  allowed={
                    allowedRoles.includes(user.role) ||
                    (typeof allowedFn == "function" && allowedFn(user) === true)
                  }
                  redirectPath="/dashboard"
                  {...route}
                >
                  <Component />
                </ProtectedRoute>
              )
          )}
          <Redirect from="/" to="/dashboard" exact />
        </Switch>
      </Phoenix>
    </ProtectedRoute>
  );
}
