import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectors } from "reducers";
import { OrderProfileContext } from "../context";
import { IoCheckmark, IoClose } from "react-icons/lib/io";
import { MdReplay } from "react-icons/lib/md";
// import { getPendingOrderTasksHoldingOrder } from "features/tasks/helpers";

const OrderActions = () => {
  const { order, isFetchingOrder, updateOrder, isUpdatingOrder } =
    React.useContext(OrderProfileContext);

  // const pendingOrderTasks = getPendingOrderTasksHoldingOrder(
  //   tasks,
  //   order.order_id
  // );
  const userRole = useSelector(selectors.getUserRole);

  const canAcceptOrder =
    (userRole == "Administrator" &&
      ["New", "Pending Buildout", "Ready For Review"].includes(
        order.order_status
      )) || (userRole == "SleepCoach" && "New" === order.order_status);

  const canSendToPending =
    userRole == "SleepCoach" && order.order_status == "Coach Follow Up";

  const canDeclineOrder =
    (userRole == "Administrator" &&
      ["Pending Buildout", "Ready For Review"].includes(order.order_status)) ||
    (userRole == "SleepCoach" && order.status == "Ready For Review");

  const canSendTofollowUp =
    (userRole == "SleepCoach" && order.order_status == "New") ||
    (userRole == "Administrator" &&
      ![
        "Coach Follow Up",
        "Accepted",
        "Voided",
        "Completed",
        "Pending Shipment",
        "Rejected"
      ].includes(order.order_status));

  if (isFetchingOrder || isUpdatingOrder)
    return <div className="accept-decline-container" />;
  return (
    <>
      <div className="accept-decline-container">
        {canAcceptOrder && (
          <span
            className="accept-order"
            title="Accept Order"
            onClick={() => updateOrder({ status: "orderAccepted" })}
          >
            <IoCheckmark />
          </span>
        )}
        {canSendToPending && (
          <span
            className="accept-order"
            title="Accept Order"
            onClick={() => updateOrder({ status: "pending" })}
          >
            <IoCheckmark />
          </span>
        )}
        {order.order_status === "Order Loading" && (
          <span>
            <span
              className="accept-order"
              title="Accept Order"
              onClick={() => updateOrder({ status: "orderAccepted" })}
            >
              <IoCheckmark />
            </span>
            <span
              className="decline-order"
              onClick={() => updateOrder({ status: "orderDeclined" })}
              title="Decline Order"
            >
              <IoCheckmark />
            </span>
          </span>
        )}

        {canSendTofollowUp && (
          <span
            className="send-follow-up"
            title="Send To Coach Follow Up"
            onClick={() => updateOrder({ status: "Coach Follow Up" })}
          >
            <MdReplay />
          </span>
        )}
        {canDeclineOrder && (
          <span
            className="decline-order"
            onClick={() => updateOrder({ status: "orderDeclined" })}
            title="Decline Order"
          >
            <IoClose />
          </span>
        )}
      </div>
    </>
  );
};

OrderActions.propTypes = { orderId: PropTypes.string.isRequired };

export default OrderActions;
