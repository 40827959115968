import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { Switch, Route } from "react-router-dom";
import { requestTeamInsights } from "actions/team-dashboard";
import { Waiting, TabList } from "components/ui";
import TeamDashboardHeader from "../team-dashboard/team-dashboard-header";
import ComplianceTeamDashboardContext from "./context";
import ComplianceTeamCompanies from "./companies/index";
import ComplianceTeamCoaches from "./compliance-team-coaches";
import "./styles.css";

const TeamDashboardCompliance = ({
  userTeam,
  role,
  teamNameOptions,
  requestTeamInsights,
  isFetchingInsights
}) => {
  const [selectedTeam, setSelectedTeam] = useState(
    userTeam === "Not Assigned" ? "" : userTeam
  );
  const [minimizeAll, setMinimizeAll] = useState(false);
  const [requestedDates, setRequestedDates] = useState({
    start_date: "",
    end_date: ""
  });

  return (
    <div className="team-dashboard-page">
      <TeamDashboardHeader
        selectedTeam={selectedTeam}
        setSelectedTeam={setSelectedTeam}
        setUserRole
        teamNameOptions={teamNameOptions}
        handleSubmit={(selectedTeam, { start_date, end_date }) => {
          setRequestedDates({ start_date, end_date });
          requestTeamInsights(selectedTeam, {
            start_date,
            end_date
          });
        }}
        toggleTableMinification={() => setMinimizeAll(!minimizeAll)}
        minimizeAll={minimizeAll}
        isFetchingInsights={isFetchingInsights}
        isComplianceDashboard={true}
      />
      <div
        style={{ display: "flex", padding: "10px 0px", alignItems: "center" }}
      >
        <TabList
          routes={
            role === "ContractorAdministrator"
              ? [
                  {
                    path: "/compliance-team-dashboard",
                    label: "Coaches",
                    exact: true
                  }
                ]
              : [
                  {
                    path: "/compliance-team-dashboard",
                    label: "Coaches",
                    exact: true
                  },
                  {
                    path: "/compliance-team-dashboard/companies",
                    label: "Companies"
                  }
                ]
          }
        />
      </div>
      <div
        className="team-dashboard-container"
        style={{
          opacity: isFetchingInsights ? 0.2 : 1
        }}
      >
        <div
          className="team-dashboard-container"
          style={{
            opacity: isFetchingInsights ? 0.2 : 1
          }}
        >
          <ComplianceTeamDashboardContext.Provider
            value={{ selectedTeam, minimizeAll, requestedDates }}
          >
            <Switch>
              <Route
                path="/compliance-team-dashboard/companies"
                component={ComplianceTeamCompanies}
              />
              <Route
                path="/compliance-team-dashboard"
                component={ComplianceTeamCoaches}
              />
            </Switch>
          </ComplianceTeamDashboardContext.Provider>
        </div>
      </div>
      {isFetchingInsights && <Waiting size="50px" />}
    </div>
  );
};

TeamDashboardCompliance.propTypes = {
  userTeam: PropTypes.string,
  teamNameOptions: PropTypes.arrayOf(PropTypes.object),
  requestTeamInsights: PropTypes.func.isRequired,
  isFetchingInsights: PropTypes.bool,
  role: PropTypes.string
};

export default connect(
  state => ({
    userTeam: selectors.getUserTeam(state),
    role: selectors.getUserRole(state),
    teamNameOptions: selectors.getTeamNameOptions(state),
    isFetchingInsights: selectors.getIsFetchingTeamInsights(state)
  }),
  { requestTeamInsights }
)(TeamDashboardCompliance);
