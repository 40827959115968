import { createContext, useEffect } from "react";
import useTableSettings, { cleanQuery } from "hooks/useTableSettings";
import isEqual from "lodash/isEqual";

export const PatientsTableSettingsContext = createContext();

const usePatientsTableSettings = (query, queryDispatcher) => {
  const columns = [
    {
      header: "all",
      field: "all",
      active: true
    },
    {
      header: "Active",
      field: "active",
      active: true
    },
    {
      header: "Name",
      field: "last_name",
      active: true
    },
    {
      header: "Account #",
      field: "account_number",
      active: true
    },
    {
      header: "Contact Type",
      field: "form_of_contact",
      active: true
    },
    {
      header: "Insurance",
      field: "insurance_company",
      active: true
    },
    {
      header: "Doctor",
      field: "doctorName",
      active: true
    },
    {
      header: "Sleep Coach",
      field: "sleep_coach",
      active: true
    },
    {
      header: "Compliance Coach",
      field: "compliance_coach",
      active: true
    },
    {
      header: "Is Compliant",
      field: "compliance_met",
      active: true
    },
    {
      header: "Company",
      field: "company",
      active: true
    },
    {
      header: "Branch",
      field: "branch",
      active: false
    },
    {
      header: "Insurance Eligibility Date",
      field: "patient_insurance_eligible",
      active: false
    },
    {
      header: "Outreach Date",
      field: "dateEligibleForAutomation",
      active: true
    },
    {
      header: "Outreach Cycle",
      field: "cycle_count",
      active: true
    },
    {
      header: "Delayed Date",
      field: "delayed_activation_date",
      active: true
    },

    {
      header: "Date Created",
      field: "date_added",
      active: true
    },
    {
      header: "Last Updated",
      field: "last_updated",
      active: true
    },
    {
      header: "Language",
      field: "language",
      active: true
    },
    {
      header: "Ordered",
      field: "has_order_after_added",
      active: true
    },
    {
      header: "New Setup",
      field: "new_setup",
      active: true
    },
    {
      header: "Setup Date",
      field: "setup_date",
      active: true
    },
    {
      header: "Remote Setup",
      field: "remote_setup",
      active: true
    },
    {
      header: "On Hold",
      field: "on_hold",
      active: true
    },
    {
      header: "Active Form",
      field: "active_form",
      active: true
    },
    {
      header: "Stage",
      field: "patient_stage",
      active: true
    },
    {
      header: "Has Recalled Machine",
      field: "has_recalled_machine",
      active: false
    },
    {
      header: "Recall Acknowledged",
      field: "has_acknowledged_recall",
      active: false
    },
    {
      header: "Recall status",
      field: "recall_action_status",
      active: false
    },
    {
      header: "Repap Eligible",
      field: "repap_eligible",
      active: false
    },
    {
      header: "Repap Requested",
      field: "wants_to_repap",
      active: false
    },
    {
      header: "Inactivation Reason",
      field: "compliance_inactivation_reason",
      active: true
    }
  ];
  const {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    visibleColumns,
    resetTableSettings
  } = useTableSettings("compliance-patients-table-settings", columns);

  // remove sorting and filters when column is removed
  useEffect(() => {
    const newQuery = cleanQuery(visibleColumns, query);
    if (!isEqual(newQuery, query)) {
      queryDispatcher({ type: "updateQuery", ...newQuery });
    }
  }, [visibleColumns, queryDispatcher]);
  return {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    resetTableSettings,
    columns
  };
};

export default usePatientsTableSettings;
