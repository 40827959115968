import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import {
  getBonafideInsuranceInfo,
  submitBonafideOrder
} from "features/bonafide/actions";
import BonafideOrderStatus from "./bonafide-order-status";
import BonafideOrderInfo from "./bonafide-order-info";
import BonafideFooter from "./bonafide-footer";
import AuthForm from "./auth-form";
import { getPendingOrderTasksHoldingOrder } from "features/tasks/helpers";
import { OrderProfileContext } from "components/order-profile-new/context";
import { formatDate } from "utils/dates";
import { useFetch } from "hooks";
import { post } from "utils/api";
// import * as R from "ramda";

// const getOrderHasChanged = (result, orderLineItems) => {
//   if (result?.response?.status == "error") {
//     return false;
//   }
//   if (result?.response?.status == "failed") {
//     return false;
//   }
//   const a = R.sortWith(
//     [R.ascend(R.prop("hcpcs")), R.ascend(R.prop("id"))],
//     JSON.parse(R.propOr(null, "requestBody", result)) ?? []
//   );
//
//   const b = R.sortWith(
//     [R.ascend(R.prop("hcpc")), R.ascend(R.prop("line_item_id"))],
//     orderLineItems?.map(c => ({
//       id: c.line_item_id,
//       hcpcs: c.hcpc?.trim(),
//       quantity: c.quantity,
//       zeroBilled: c.zero_billed
//     }))
//   );
//
//   return R.equals(a, b);
// };
//
const BonafidePanel = ({
  visible,
  getBonafideInsuranceInfo,
  submitBonafideOrder,
  orderId,
  verificationHistory
}) => {
  const {
    tasks,
    order,
    fetchOrderLineItemsApi,
    getPatientTasks,
    fetchOrderProfile
  } = React.useContext(OrderProfileContext);

  const patientId = order.patient_id;
  const orderLineItems = fetchOrderLineItemsApi.response;

  const hasPendingTask = getPendingOrderTasksHoldingOrder(tasks, orderId) > 0;
  const [isFetching, setIsFetching] = React.useState(false);
  const [editAuthScreen, setEditAuth] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const initData = async () => {
    await getBonafideInsuranceInfo(orderId);
  };

  React.useEffect(() => {
    initData();
    setIndex(0);
  }, [orderId]);

  const bonafideLastResult = verificationHistory?.[0] ?? {};
  const bonafideSelectedResult = verificationHistory?.[index] ?? {};

  // const orderHasChanged = getOrderHasChanged(
  //   bonafideLastResult,
  //   orderLineItems
  // );
  let currentStep = 1;
  if (bonafideLastResult?.isConfirmed === true) {
    currentStep = 3;
  } else if (bonafideLastResult?.response) {
    currentStep = 2;
  }

  const { fetchData: checkCmnNeeded } = useFetch({
    apiFn: ({ orderId }) => post(`bonafide/check-cmn-needed`, { orderId })
  });


  return (
    <div className={`bonafide-panel ${visible ? "" : "hidden"}`}>
      <div
        className="bonafide-panel-header"
        style={{
          padding: 5,
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          gridGap: 10,
          alignItems: "center",
          borderBottom: "1px solid #ccc"
        }}
      >
        <label>History: </label>
        <select onChange={e => setIndex(e.target.value)} style={{ margin: 0 }}>
          {verificationHistory?.map((x, index) => (
            <>
              {index == 1 && (
                <option disabled key={index}>
                  ----------------------------------------------------------------
                </option>
              )}
              <option key={index + x.id} value={index}>
                {formatDate(x.requestedAt, "L hh:mm A")} -{" "}
                {x.isConfirmed ? "Order Submit" : "Order Verification"} -{" "}
                {x.response.status}{" "}
              </option>
            </>
          ))}
        </select>
      </div>
      {editAuthScreen ? (
        <AuthForm
          orderId={orderId}
          orderLineItems={orderLineItems}
          responseBody={bonafideSelectedResult?.responseBody ?? {}}
          currentStep={currentStep}
          canOverrideOrderCreation={currentStep == 1}
          handleCancel={() => setEditAuth(false)}
          hasPendingTask={hasPendingTask}
          submitOrder={async () => {
            try {
              setEditAuth(false);
              if (isFetching) return;
              setIsFetching(true);
              const orderSummary = document.getElementById(
                `copyArea-${orderId}`
              );
              await submitBonafideOrder(
                orderId,
                orderSummary.innerText,
                true,
                true
              );
              fetchOrderProfile(orderId);
            } finally {
              setIsFetching(false);
            }
          }}
        />
      ) : (
        <div className="bonafide-result-container">
          <BonafideOrderStatus
            response={bonafideSelectedResult?.response}
            requestedAt={bonafideSelectedResult.requestedAt}
            requestedBy={bonafideSelectedResult.requestedBy}
          />
          <BonafideOrderInfo
            response={bonafideSelectedResult.response}
            responseBody={bonafideSelectedResult.responseBody}
          />
        </div>
      )}
      <BonafideFooter
        isFetching={isFetching}
        hasPendingTask={hasPendingTask}
        canOverrideOrderCreation={currentStep == 1}
        currentStep={currentStep}
        handleRefresh={async () => {
          try {
            setIsFetching(true);
            await getBonafideInsuranceInfo(orderId);
          } finally {
            setIsFetching(false);
          }
        }}
        handleCreatePreOrder={async () => {
          try {
            if (isFetching) return;
            setIsFetching(true);

            const cmnTaskAdded = await checkCmnNeeded({ orderId });
            if (cmnTaskAdded) await getPatientTasks(patientId);

            const orderSummary = document.getElementById(`copyArea-${orderId}`);
            await submitBonafideOrder(orderId, orderSummary.innerText, false);
            fetchOrderProfile(orderId);
          } finally {
            setIsFetching(false);
          }
        }}
        handleCreateOrderWithAuth={() => {
          if (hasPendingTask) {
            alert(
              "Order has pending task, resolve hold before sending order for processing"
            );
            return;
          }
          setEditAuth(true);
        }}
        handleCreateOrder={async () => {
          if (hasPendingTask) {
            alert(
              "Order has pending task, resolve hold before sending order for processing"
            );
            return;
          } else {
            try {
              setEditAuth(false);
              if (isFetching) return;
              setIsFetching(true);
              const orderSummary = document.getElementById(
                `copyArea-${orderId}`
              );
              await submitBonafideOrder(
                orderId,
                orderSummary.innerText,
                true,
                false
              );
              fetchOrderProfile(orderId);
            } finally {
              setIsFetching(false);
            }
          }
        }}
        editAuthScreen={editAuthScreen}
      />
    </div>
  );
};

BonafidePanel.propTypes = {
  orderId: PropTypes.string.isRequired,
  getBonafideInsuranceInfo: PropTypes.func.isRequired,
  submitBonafideOrder: PropTypes.func.isRequired,
  canOverrideOrderCreation: PropTypes.bool,
  verificationHistory: PropTypes.arrayOf(PropTypes.object),
  // isValid: PropTypes.bool,
  //patientId: PropTypes.string,
  visible: PropTypes.bool
};

export default connect(
  (state, { orderId }) => {
    // const { data: orderLineItems } = selectors.getOrderLineItems(state, {
    //   orderId
    // });

    return {
      //orderLineItems,
      // isValid: selectors.getIsValidBonafideResult(
      //   state,
      //   {
      //     orderId
      //   },
      //   orderLineItems
      // ),
      verificationHistory: selectors.getBonafideOrderResult(state, { orderId })
      // canOverrideOrderCreation: selectors.getCanOverrideOrderCreation(state, {
      //   orderId
      // })
    };
  },
  {
    getBonafideInsuranceInfo,
    submitBonafideOrder
    //requestOrderHoldReasons
  }
)(BonafidePanel);
