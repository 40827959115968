import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { answerQueue } from "actions/phone";
import { Dropdown, PageHeader } from "components/ui";
import Queue from "./queue";
import MdFilterList from "react-icons/lib/md/filter-list";

const PhoneQueues = ({ queues, userId, answerQueue, phoneIsReady }) => {
  const [hiddenQueues, setHiddenQueues] = useState([]);
  return (
    <div className="queue-list-page">
      <PageHeader title="Phone Queue" />
      <div className="queue-list">
        <div className="queue-header">
          <span>
            Team
            <Dropdown
              trigger={
                <span style={{ paddingLeft: 5, margin: "0 5px" }}>
                  <MdFilterList />
                </span>
              }
            >
              <ul className="dropdown-list" onClick={e => e.stopPropagation()}>
                <li
                  className="dropdown-command"
                  onClick={() => {
                    setHiddenQueues(() => [
                      ...queues.map(q => q.friendly_name)
                    ]);
                  }}
                >
                  Clear Selections
                </li>
                <li
                  className="dropdown-command"
                  onClick={() => {
                    setHiddenQueues(() => [
                      ...queues
                        .map(q => q.friendly_name)
                        .filter(q => hiddenQueues.indexOf(q) === -1)
                    ]);
                  }}
                >
                  Invert Selections
                </li>
                {queues.map(q => (
                  <li
                    className="dropdown-item"
                    key={q.friendly_name}
                    onClick={() => {
                      if (hiddenQueues.indexOf(q.friendly_name) === -1)
                        setHiddenQueues(() => [
                          ...hiddenQueues,
                          q.friendly_name
                        ]);
                      else
                        setHiddenQueues(() =>
                          hiddenQueues.filter(name => name !== q.friendly_name)
                        );
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={hiddenQueues.indexOf(q.friendly_name) === -1}
                      onChange={() => {}}
                    />
                    <label key={q.friendly_name}>{q.friendly_name}</label>
                  </li>
                ))}
              </ul>
            </Dropdown>
          </span>
          <span>Waiting</span>
          <span>Longest Wait Time</span>
        </div>
        {queues
          .filter(q => hiddenQueues.indexOf(q.friendly_name) == -1)
          .map(q => (
            <Queue
              queue={q}
              key={q.friendly_name}
              phoneIsReady={phoneIsReady}
              answer={() => {
                answerQueue({
                  queue: q.friendly_name,
                  from: userId
                });
              }}
            />
          ))}
      </div>
    </div>
  );
};

export default connect(
  state => {
    return {
      queues: selectors.new_getPhoneQueues(state),
      userId: selectors.getUserId(state),
      phoneIsReady: selectors.getIsPhoneReady(state)
    };
  },
  { answerQueue }
)(PhoneQueues);

PhoneQueues.propTypes = {
  queues: PropTypes.array.isRequired,
  answerQueue: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  phoneIsReady: PropTypes.bool
};
