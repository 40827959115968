import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSort, useFetch } from "hooks";
import TableSortableHeader from "components/ui/table-sortable-header";
import PureModal from "components/ui/modal/pure-modal";
import { CompanyContactModal } from "./company-contacts-modal";
import { put } from "utils/api";
import { path } from "ramda";
import { aLink } from "utils/styles";

const CompanyContactsTable = ({ data, dates }) => {
  const [modalTotal, setModalTotal] = useState(0);
  const [companyBreakdown, setCompanyBreakdown] = useState([]);

  const { ascending, sortBy, getSortedArray, toggleSortDirection, setSortBy } =
    useSort({ by: "company_name", ascending: true });

  const {
    response: companyBreakdownResponse,
    fetchData: getBreakdownCompanyCategory,
    isFetching: isFetchingCompany
  } = useFetch({
    defaultValue: [],
    apiFn: payload =>
      put("compliance-team/company-breakdown-by-category", payload),
    transformError: path(["response", "body", "status"])
  });

  const {
    response: companyBreakdownResponseType,
    fetchData: getCompanyBreakdownType,
    isFetching: isFetchingCompanyType
  } = useFetch({
    defaultValue: [],
    apiFn: payload => put("compliance-team/company-breakdown-by-type", payload),
    transformError: path(["response", "body", "status"])
  });

  const {
    response: companyBreakdownResponseTotal,
    fetchData: getCompanyBreakdownTotal,
    isFetching: isFetchingCompanyTotal
  } = useFetch({
    defaultValue: [],
    apiFn: payload => put("compliance-team/company-breakdown-total", payload),
    transformError: path(["response", "body", "status"])
  });

  const getCompanyBreakdownByCategory = (category, company) => {
    const payload = {
      type_Category: category,
      companyName: company,
      startDate: dates.start_date,
      endDate: dates.end_date
    };
    getBreakdownCompanyCategory(payload);
  };
  const getCompanyBreakdownByType = (type, company) => {
    const payload = {
      type_Category: type,
      companyName: company,
      startDate: dates.start_date,
      endDate: dates.end_date
    };
    getCompanyBreakdownType(payload);
  };

  const getCompanyBreakdownByTotal = company => {
    const payload = {
      companyName: company,
      startDate: dates.start_date,
      endDate: dates.end_date
    };
    getCompanyBreakdownTotal(payload);
  };

  useEffect(() => {
    setCompanyBreakdown(companyBreakdownResponse);
  }, [companyBreakdownResponse]);

  useEffect(() => {
    setCompanyBreakdown(companyBreakdownResponseType);
  }, [companyBreakdownResponseType]);

  useEffect(() => {
    setCompanyBreakdown(companyBreakdownResponseTotal);
  }, [companyBreakdownResponseTotal]);

  useEffect(() => {
    setModalTotal(
      companyBreakdown.reduce((total, element) => {
        return total + element.calls;
      }, 0)
    );
  }, [companyBreakdown]);

  return (
    <table>
      <TableSortableHeader
        columns={[
          { key: "company_name", text: "Company" },
          {
            key: "phone_outgoing_call_successes",
            text: "Outgoing Calls"
          },
          {
            key: "phone_incoming_call_successes",
            text: "Inbound Calls"
          },
          {
            key: "phone_left_voicemail_successes",
            text: "Voicemails"
          },
          { key: "email_success_total", text: "Emails" },
          { key: "sms_text_message_successes", text: "Texts" },
          {
            key: "sms_push_notification_successes",
            text: "Notifications"
          },
          { key: "exposed_contacts_success_total", text: "Total" }
        ]}
        sortBy={sortBy}
        ascending={ascending}
        setSortBy={setSortBy}
        toggleSortDirection={toggleSortDirection}
      />
      <tbody>
        {getSortedArray(data).map(d => (
          <tr key={d.company_name}>
            <td>{d.company_name}</td>

            <PureModal
              renderTrigger={({ openModal }) => (
                <td
                  style={aLink}
                  onClick={() => {
                    getCompanyBreakdownByCategory(
                      "Outgoing Call",
                      d.company_name
                    );
                    openModal();
                  }}
                >
                  {d.phone_outgoing_call_successes}
                </td>
              )}
            >
              <CompanyContactModal
                modalTotal={modalTotal}
                modalType="Outgoing Calls"
                companyBreakdown={companyBreakdown}
                isFetching={isFetchingCompany}
              />
            </PureModal>
            <PureModal
              renderTrigger={({ openModal }) => (
                <td
                  style={aLink}
                  onClick={() => {
                    getCompanyBreakdownByCategory(
                      "Incoming Call",
                      d.company_name
                    );
                    openModal();
                  }}
                >
                  {d.phone_incoming_call_successes}
                </td>
              )}
            >
              <CompanyContactModal
                modalTotal={modalTotal}
                modalType="Incoming Calls"
                companyBreakdown={companyBreakdown}
                isFetching={isFetchingCompany}
              />
            </PureModal>

            <PureModal
              renderTrigger={({ openModal }) => (
                <td
                  style={aLink}
                  onClick={() => {
                    getCompanyBreakdownByCategory(
                      "Left Voicemail",
                      d.company_name
                    );
                    openModal();
                  }}
                >
                  {d.phone_left_voicemail_successes}
                </td>
              )}
            >
              <CompanyContactModal
                modalTotal={modalTotal}
                modalType="Voicemails"
                companyBreakdown={companyBreakdown}
                isFetching={isFetchingCompany}
              />
            </PureModal>
            <PureModal
              renderTrigger={({ openModal }) => (
                <td
                  style={aLink}
                  onClick={() => {
                    getCompanyBreakdownByType("Email", d.company_name);
                    openModal();
                  }}
                >
                  {d.email_success_total}
                </td>
              )}
            >
              <CompanyContactModal
                modalTotal={modalTotal}
                modalType="Emails"
                companyBreakdown={companyBreakdown}
                isFetching={isFetchingCompanyType}
              />
            </PureModal>
            <PureModal
              renderTrigger={({ openModal }) => (
                <td
                  style={aLink}
                  onClick={() => {
                    getCompanyBreakdownByCategory(
                      "Text Message",
                      d.company_name
                    );
                    openModal();
                  }}
                >
                  {d.sms_text_message_successes}
                </td>
              )}
            >
              <CompanyContactModal
                modalTotal={modalTotal}
                modalType="Text Messages"
                companyBreakdown={companyBreakdown}
                isFetching={isFetchingCompany}
              />
            </PureModal>
            <PureModal
              renderTrigger={({ openModal }) => (
                <td
                  style={aLink}
                  onClick={() => {
                    getCompanyBreakdownByCategory(
                      "Push Notification",
                      d.company_name
                    );
                    openModal();
                  }}
                >
                  {d.sms_push_notification_successes}
                </td>
              )}
            >
              <CompanyContactModal
                modalTotal={modalTotal}
                modalType="Push Notifications"
                companyBreakdown={companyBreakdown}
                isFetching={isFetchingCompany}
              />
            </PureModal>
            <PureModal
              renderTrigger={({ openModal }) => (
                <td
                  style={aLink}
                  onClick={() => {
                    getCompanyBreakdownByTotal(d.company_name);
                    openModal();
                  }}
                >
                  {d.exposed_contacts_success_total}
                </td>
              )}
            >
              <CompanyContactModal
                modalTotal={modalTotal}
                modalType="Total"
                companyBreakdown={companyBreakdown}
                isFetching={isFetchingCompanyTotal}
              />
            </PureModal>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

CompanyContactsTable.propTypes = {
  dates: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string
  }).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default CompanyContactsTable;
