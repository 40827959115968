import React, { useEffect, useState } from "react";
import SelectDropdown from "components/forms/select-dropdown";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { Spinner } from "components/ui";
import PropTypes from "prop-types";

const ComplianceCoachScorecardFilters = ({
  fetchData,
  teamNameOptions,
  complianceCoahes,
  isFetching,
  companyId
}) => {
  const [selectedTeam, setSelectedTeam] = useState("");
  const [selectedCoach, setSelectedCoach] = useState("");
  const [filteredComplianceCoaches, setFilteredComplianceCoaches] = useState(
    []
  );

  useEffect(() => {
    setFilteredComplianceCoaches(complianceCoahes);
  }, []);


  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "5px",
        marginRight: "10px"
      }}
    >
      <span style={{ marginRight: "10px", fontSize: "15px" }}>
        Team:
        <SelectDropdown
          options={[{ text: "", value: "" }, ...teamNameOptions]}
          value={selectedTeam}
          onChange={team => {
            setSelectedTeam(team);

            if (team == "") setFilteredComplianceCoaches(complianceCoahes);
            else
              setFilteredComplianceCoaches(
                complianceCoahes.filter(x => x.team == team)
              );
          }}
        />
      </span>
      <span style={{ marginRight: "10px", fontSize: "15px" }}>
        Coach:
        <SelectDropdown
          options={[{ text: "", value: "" }, ...filteredComplianceCoaches]}
          value={selectedCoach}
          onChange={team => {
            setSelectedCoach(team);
          }}
        />
      </span>
      <button
        type="buttpm"
        style={{
          padding: "0.5rem 2rem",
          marginTop: "35px",
          borderRadius: "3px"
        }}
        onClick={() => {
          const body = {
            TeamId: selectedTeam,
            CoachId: selectedCoach,
            CompanyId: companyId
          };
          fetchData(body, "not-by-range");
        }}
        disabled={isFetching}
      >
        Submit
      </button>
      {isFetching && <Spinner />}
    </div>
  );
};

ComplianceCoachScorecardFilters.propTypes = {
  fetchData: PropTypes.func.isRequired,
  teamNameOptions: PropTypes.array.isRequired,
  complianceCoahes: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  companyId: PropTypes.string
};

export default connect(state => ({
  teamNameOptions: selectors.getTeamNameOptions(state),
  complianceCoahes: selectors.getComplianceCoaches(state)
}))(ComplianceCoachScorecardFilters);
