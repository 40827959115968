import React from "react";
import { OrderProfileContext } from "../context";
import PropTypes from "prop-types";
import {
  MdWarning,
  MdPerson,
  MdPlusOne,
  MdErrorOutline,
  MdLocalShipping,
  MdDoNotDisturb
} from "react-icons/lib/md";

const VerifyOrderProductCompatibility = ({
  product_type,
  inventory_guid,
  back_ordered,
  hcpc,
  item_name
}) => {
  const { fetchOrderLineItemsApi, fetchOrderEquipmentApi, order } =
    React.useContext(OrderProfileContext);

  const orderLineItems = fetchOrderLineItemsApi.response;

  const {
    compatibleItems: {
      orderCompatibleItems,
      patientCompatibleItems,
      patientExcludedHCPCs
    }
  } = fetchOrderEquipmentApi.response;

  if (item_name.includes("billing")) {
    return null;
  }

  const hasGenericItem =
    order.flag_generic_items && item_name?.includes("Generic");

  // Glag seals if there is mask on order that is not compatible with the seal
  const incompatibleWithOrderEq =
    orderCompatibleItems.length > 0 &&
    ["Seals"].includes(product_type) &&
    !orderCompatibleItems.some(x => x.item_id == inventory_guid);


  const incompatibleWithPatientEq = !patientCompatibleItems.some(
    x => x.item_id == inventory_guid
  );

  // flag multiple item types on same order handle multiple mask/mask with headgear or heated/standard tubing  on same order
  const getProductStyle = product_type => {
    switch (product_type) {
      case "Mask":
        return "Mask";
      case "Mask with Headgear":
        return "Mask";
      case "Standard Tubing":
        return "Tubing";
      case "Heated Tubing":
        return "Tubing";
      default:
        return product_type;
    }
  };

  const equipmentTypeFlagged = orderLineItems
    .filter(
      x =>
        !x.item_name.includes("billing") && x.inventory_guid != inventory_guid
    )
    .some(
      x =>
        x.inventory_guid != inventory_guid &&
        getProductStyle(x.product_type) == getProductStyle(product_type)
    );

  const hasExcludedItem = patientExcludedHCPCs?.some(x => x == hcpc);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: 3,
        color: "rgb(232, 145, 12)",
        height: 15
      }}
    >
      {hasGenericItem && (
        <i
          title="Generic item loaded as a placeholder"
          className="no-print"
          style={{
            lineHeight: "1.15em",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <MdErrorOutline size={18} />
        </i>
      )}
      {back_ordered && (
        <i
          title="Item is on back order"
          className="no-print"
          style={{
            lineHeight: "1.15em",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <MdLocalShipping size={16} />
        </i>
      )}
      {incompatibleWithPatientEq && (
        <i
          title="Item is not compatible with patients current equipment"
          className="no-print"
          style={{
            lineHeight: "1.15em",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <MdPerson size={16} />
        </i>
      )}
      {incompatibleWithOrderEq && (
        <i
          title="Selected equipment is not compatible with other items included in order."
          className="no-print"
          style={{
            lineHeight: "1.15em",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <MdWarning size={16} />
        </i>
      )}
      {equipmentTypeFlagged && (
        <i
          title="Duplicate equipment types"
          className="no-print"
          style={{
            lineHeight: "1.15em",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <MdPlusOne size={16} />
        </i>
      )}
      {hasExcludedItem && (
        <i
          title="HCPC is excluded for this patient"
          className="no-print"
          style={{
            lineHeight: "1.15em",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <MdDoNotDisturb size={16} />
        </i>
      )}
    </div>
  );
};

export default VerifyOrderProductCompatibility;

VerifyOrderProductCompatibility.propTypes = {
  product_type: PropTypes.string.isRequired,
  inventory_guid: PropTypes.string.isRequired,
  back_ordered: PropTypes.bool,
  hcpc: PropTypes.string.isRequired,
  item_name: PropTypes.string.isRequired
};
