import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CoachContactsTable from "./table";
import MdArrowDropdown from "react-icons/lib/md/arrow-drop-down";
import MdArrowUp from "react-icons/lib/md/arrow-drop-up";

const ComplianceTeamCoachContacts = ({ minimizeAll, requestedDates, data }) => {
  const [isMinimized, setMinimized] = useState(false);

  useEffect(() => {
    setMinimized(minimizeAll);
  }, [minimizeAll, setMinimized]);

  return (
    <div className="compliance-block compliance-coach-contacts">
      <div className="compliance-block-header">
        <span
          style={{ cursor: "pointer" }}
          onClick={() => setMinimized(!isMinimized)}
        >
          Coach Contacts
          <i className="flat-icon-button">
            {isMinimized ? <MdArrowDropdown /> : <MdArrowUp />}
          </i>
        </span>
      </div>
      {!isMinimized && (
        <div className="block-content">
          <CoachContactsTable data={data} dates={requestedDates} />
        </div>
      )}
    </div>
  );
};

ComplianceTeamCoachContacts.propTypes = {
  requestedDates: PropTypes.object.isRequired,
  minimizeAll: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ComplianceTeamCoachContacts;
