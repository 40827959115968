import React from "react";
import PropTypes from "prop-types";
import { selectors } from "reducers";
import { connect } from "react-redux";

export const HasRolePermission = React.memo(
  ({ userRole, allowedRoles, children, renderElse, contractorCheck, complianceUser}) => {
    //Since being a constractor means you can't be another role, do not require roles to be passed when true is passed on contractor check
    if(contractorCheck)
    {
      //this needs to be nested so that roles are not required when contractor check is true
      if(userRole === "ContractorAdministrator") return renderElse ? renderElse : null;
    }
    // Since being a compliance user means some views do not care about the user role, 
    // do not require roles to be passed when true is passed for complianceUser
    // render the content regardless of the user role
    else if (typeof complianceUser === "boolean" && complianceUser)
    {
      return <React.Fragment>{children}</React.Fragment>;
    }
    else
    {
      if (!userRole) {
        return renderElse ? renderElse : null;
      }
      if (!allowedRoles.includes(userRole)) {
        return renderElse ? renderElse : null;
      }
    }
    return <React.Fragment>{children}</React.Fragment>;
  }
);

HasRolePermission.defaultProps = {
  allowedRoles: [],
  contractorCheck:false,
  complianceUser: false
};

HasRolePermission.propTypes = {
  userRole: PropTypes.string,
  allowedRoles: PropTypes.arrayOf(
    PropTypes.oneOf([
      "Administrator",
      "SleepCoach",
      "OfficeAdministrator",
      "CompanyAdministrator",
      "ServiceAdmin",
      "ServiceCoach",
      "Therapist",
      "ComplianceCoach",
      'ContractorAdministrator'
    ])
  ).isRequired,
  renderElse: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  contractorCheck: PropTypes.bool,
  complianceUser: PropTypes.bool
};

export default connect(state => ({ userRole: selectors.getUserRole(state) }))(
  HasRolePermission
);
