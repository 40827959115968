import React, { useEffect, useState } from 'react'
import ComplianceMetricsPatientsOverview from "./compliance-metrics-patients-overview";
import './styles.scss'
import { useFetch } from 'hooks';
import { path } from "ramda";
import { get } from '../../../../../utils/api';
import { getApiErrorMessage } from "utils/misc";
import PatientDataByCategory from 'components/screens/dashboard/compliance-dashboard/patient-data-by-category';
import { Spinner, PureModal } from 'components/ui';
import useDateRange from 'hooks/useDateRange';
import ComplianceDashboardBarChart from './compliance-dashboard-bar-chart';
import { useDispatch } from 'react-redux';
import * as msg from "actions/message";
import { useHistory } from "react-router-dom";
import ComplianceDashboardDoubleBarChart from './compliance-dashboard-double-bar-chart';
import PropTypes from "prop-types";


const ComplianceMetricsTab = ({ companyId }) => {

  const history = useHistory()
  const dispatch = useDispatch()

  const [complianceMetricsData, setComplianceMetricsData] = useState({})
  const [selectedChartData, setSelectedChartData] = useState({});
  const [infoAcquired, setInfoAcquired] = useState(false)
  const [getTasksChartPatients, setGetTasksChartPatients] = useState(false);

  // const { startDate, endDate, setStartDate, setEndDate } = useDateRange({
  const { startDate, endDate } = useDateRange({
    startDate: null,
    endDate: null
  });

  const [chosenStartDate, setChosenStartDate] = useState(null)
  const [chosenEndDate, setChosenEndDate] = useState(null)

  const { fetchData: fetchComplianceMetricsData, isFetching: fetchingComplianceMetricData } = useFetch({
    apiFn: (filter) => get(`compliance-dashboard/percentage-categories`, filter),
    defaultValue: {},
    transformError: path(["response", "body", "status"]),
    onError: error => {
      dispatch(msg.errorMessage(`Failed to get compliance dashboard metrics data: ${getApiErrorMessage(error)}`))
    }
  });

  const {
    response: userAppPermissions, fetchData: fetchUserAppPermissions
  } = useFetch({
    apiFn: () => get(`users/app_permissions`),
    defaultValue: {},
  });

  const {
    response: companyAppPermissions, fetchData: fetchCompanyAppPermissions
  } = useFetch({
    apiFn: () => get(`companies/${companyId}/app_permissions`),
    defaultValue: {},
  });

  useEffect(() => {
    history.replace(`${history.location.pathname}`);
    getComplianceMetricsData(startDate, endDate);

    if (companyId)
      fetchCompanyAppPermissions();
    else
      fetchUserAppPermissions();
  }, [])


  const getComplianceMetricsData = async (startDate, endDate) => {
    await setChosenStartDate(startDate)
    await setChosenEndDate(endDate)
    const complianceMetricsDataResponse = await fetchComplianceMetricsData({ dateFrom: startDate ? startDate : null, dateTo: endDate ? endDate : null, companyId: companyId ? companyId : "" });
    if (complianceMetricsDataResponse) {
      setInfoAcquired(true)
      setComplianceMetricsData(complianceMetricsDataResponse);
    }
  }

  const onBarChartSelected = (data) => {
    document.getElementById("chartItemClicked").click();
    setSelectedChartData(data);
    setGetTasksChartPatients(false);
  };

  const onTasksBarChartSelected = (data) => {
    document.getElementById("chartItemClicked").click();
    setSelectedChartData(data);
    setGetTasksChartPatients(true);
  };

  const onProgressBarClicked = (isComplianceMet) => {

    document.getElementById("chartItemClicked").click();
    if (isComplianceMet) {
      setSelectedChartData({ category: '28', categoryName: 'Compliance Met true', filterName: "Compliant patients" }); //isCompliant=true

    } else {
      setSelectedChartData({ category: '29', categoryName: 'Not Compliance', filterName: "Not Compliant patients" }); //isCompliant=false
    }
  }

  const currentUnalteredRoute = history.location.pathname

  return (
    <>
      {/*Date filter, commented to be hidden, according to S3 request*/}
      {/*<div style={{display: "flex", marginBottom: "25px", padding: "0 5px", width: "100%"}}>
          <DateRange
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              handleSubmit={getComplianceMetricsData}
              isValid={!fetchingComplianceMetricData}
          />
      </div>*/}

      <div style={{ display: "flex", justifyContent: "center" }}>
        {
          fetchingComplianceMetricData && <div><Spinner /></div>
        }
      </div>

      <div style={fetchingComplianceMetricData ? { pointerEvents: "none", opacity: "0.5" } : undefined}>
        <div className="compliance-metrics-tab">
          {
            infoAcquired ?
              <ComplianceMetricsPatientsOverview
                complianceMetricsData={complianceMetricsData}
                onProgressBarClicked={onProgressBarClicked}

              />
              :
              null
          }
        </div>
        {
          infoAcquired ?
            <div className='bar-charts-container'>
              {complianceMetricsData.Charts?.map((chart) => (
                <div key={chart.category} className='bar-charts' >
                  <ComplianceDashboardBarChart
                    data={chart}
                    onItemSelected={onBarChartSelected}
                    startDate={chosenStartDate}
                    endDate={chosenEndDate}
                    companyId={companyId}
                    isFetching={fetchingComplianceMetricData}
                  />
                </div>
              ))}
            </div>
            :
            null
        }
        {
          infoAcquired && (userAppPermissions?.patientTasks || companyAppPermissions?.patientTasks) ?
            <div >
              <h4 style={{ textAlign: "center", color: "rgb(49, 130, 189)", marginBottom: '10px', margin: ' 15px 0 15px 0px' }} >
                Pending and Completed Tasks - Last 30 Days
              </h4>

              <div className='bar-charts-container' style={{ justifyContent: 'space-evenly' }}>
                {complianceMetricsData.TaskCharts?.map((chart) => (
                  <div key={chart.category}>
                    <ComplianceDashboardDoubleBarChart
                      data={chart}
                      onItemSelected={onTasksBarChartSelected}
                      startDate={chosenStartDate}
                      endDate={chosenEndDate}
                      companyId={companyId}
                      isFetching={fetchingComplianceMetricData}
                    />
                  </div>
                ))}
              </div>
            </div>
            :
            null
        }
      </div>
      <PureModal
        windowProps={{ large: true, modalZIndex: 6, modalBehindModal: true }}
        renderTrigger={({ openModal }) => (<div id="chartItemClicked" onClick={openModal}></div>)}
        renderContent={({ closeModal }) => (
          <PatientDataByCategory
            dateFrom={chosenStartDate}
            dateTo={chosenEndDate}
            companyId={companyId}
            category={selectedChartData.category}
            value={selectedChartData.value}
            filterName={selectedChartData.filterName}
            categoryName={selectedChartData.categoryName}
            closeModal={closeModal}
            inActionView={false}
            fromTasksChart={getTasksChartPatients}
          />
        )}
        onClose={() => history.replace(currentUnalteredRoute)}
      />
    </>
  )
};

export default ComplianceMetricsTab;
ComplianceMetricsTab.propTypes = {
  companyId: PropTypes.string,
}