import React from "react";
import PropTypes from "prop-types";
import { OrderProfileContext } from "../context";
import { selectors } from "reducers";
import { useSelector } from "react-redux";

const ReasonRejectedInfo = ({ value }) => {
  const { updateOrder } = React.useContext(OrderProfileContext);

  const orderRejectedReasons = useSelector(
    selectors.getEnumOrderRejectedReasons
  );

  const currentValue = orderRejectedReasons.find(
    reason => reason.text === value || reason.value === value
  );

  return (
    <select
      value={currentValue ? currentValue.value : value}
      onChange={({ target }) => {
        if (target.value && value !== target.value) {
          updateOrder({ rejected_reason: target.value });
        }
      }}
    >
      {!currentValue && (
        <option readOnly disabled value={value}>
          {value}
        </option>
      )}
      {orderRejectedReasons.map(({ text, value }) => (
        <option key={value} value={value}>
          {text}
        </option>
      ))}
    </select>
  );
};

ReasonRejectedInfo.propTypes = {
  value: PropTypes.string,
  orderId: PropTypes.string.isRequired,
  requestUpdateOrderReasonRejectedInfo: PropTypes.func,
  orderRejectedReasons: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ReasonRejectedInfo;
