import React from "react";
import PropTypes from "prop-types";
import Header from "components/order-profile/header";
import { InlineWaiting } from "components/ui";
import { OrderProfileContext } from "./context";
import { get } from "utils/api";
import { useFetch } from "hooks";
import AddInventoryItemByIdForm from "./add-inventory-item-by-id-form";
import AddInventoryItemForm from "./add-inventory-item-form";

const AddOrderLineItems = () => {
  const {
    order,
    addOrderLineItemApi,
    addOrderLineItemByIdApi,
    fetchOrderEquipmentApi
  } = React.useContext(OrderProfileContext);

  const companyInventoryItemsApi = useFetch({
    apiFn: ({ patient_id, product_line }) =>
      get(
        `patients/${patient_id}/company_inventory?product_line=${product_line}`
      ),
    defaultValue: [],
    transformResponse: x => x.inventory
  });

  React.useEffect(() => {
    if (order.patient_id) companyInventoryItemsApi.fetchData(order);
  }, [order.patient_id]);
  // Note: Billed is not a real status. However, business has asked that this be added to the array of order statuses that disable the add equipment forms.
  const _disabled = ["completed", "rejected", "voided", "accepted", "pending shipment", "pending fulfillment", "billed" ].includes(order?.order_status_raw);
  const companyInventoryItems = companyInventoryItemsApi.response;

  return (
    <div className="no-print">
      <Header
        showSpinner={
          addOrderLineItemByIdApi.isFetching || addOrderLineItemApi.isFetching
        }
      >
        Add New Line Item
      </Header>
      {!!order.patient_id && (
        <div className="add-order-equipment-container">
          {companyInventoryItemsApi.isFetching ? (
            <div
              style={{
                height: 200,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <InlineWaiting />
            </div>
          ) : (
            <>
              <AddInventoryItemByIdForm
                withQuantity={true}
                disabled={_disabled}
                addItemByID={addOrderLineItemByIdApi.fetchData}
              />
              <AddInventoryItemForm
                showQuantity={true}
                disabled={_disabled}
                inventory_items={companyInventoryItems}
                addInventoryItem={addOrderLineItemApi.fetchData}
                patientCompatibleItems={
                  fetchOrderEquipmentApi.response.compatibleItems
                    ?.patientCompatibleItems ?? []
                }
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

AddOrderLineItems.propTypes = {
  orderId: PropTypes.string.isRequired
};

export default AddOrderLineItems;
