import React, { useEffect, useState } from "react";
import SelectDropdown from "components/forms/select-dropdown";
import DateRange from "components/ui/date-range";
import { Spinner } from "components/ui";
import PropTypes from "prop-types";

const ComplianceCoachScorecardByRangeFilters = ({
  fetchData,
  isFetching,
  companyId,
  selectedCoach,
  selectedTeam
}) => {
  const [dateType, setDateType] = useState("SetupDate");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    resetDates();
  }, []);

  const resetDates = () => {
    const today = new Date();
    const priorDate = new Date(new Date().setDate(today.getDate() - 120));
    setFromDate(priorDate);
    setToDate(today);
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "5px",
        marginRight: "10px"
      }}
    >      
      <span style={{ marginRight: "10px", fontSize: "15px" }}>
        Basis {dateType && `(${dateType})`}:
        <SelectDropdown
          options={[
            { text: "Setup Date", value: "SetupDate" },
            { text: "Creation Date", value: "CreationDate" }
          ]}
          value={dateType}
          onChange={dateType => {
            setDateType(dateType);
            if(dateType == "")
              resetDates();
          }}
        />
      </span>
      {dateType && (
        <span style={{ marginRight: "10px", fontSize: "15px" }}>
          Date Range:
          <DateRange
            startDate={fromDate}
            endDate={toDate}
            setStartDate={setFromDate}
            setEndDate={setToDate}
            handleSubmit={() => {}}
            disabled={!dateType}
            showSubmit={false}
          />
        </span>
      )}
      <button
        type="buttpm"
        style={{ padding: "0.5rem 2rem", marginTop:'35px', borderRadius: "3px" }}
        onClick={() => {
          const body = {
            TeamId: selectedTeam,
            CoachId: selectedCoach,
            FromDate: fromDate,
            ToDate: toDate,
            DateType: dateType,
            CompanyId: companyId
          };
          fetchData(body, 'by-range');
        }}
        disabled={isFetching}
      >
        Submit
      </button>
      {isFetching && <Spinner />}
    </div>
  );
};

ComplianceCoachScorecardByRangeFilters.propTypes = {
  fetchData: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  companyId: PropTypes.string,
  selectedCoach: PropTypes.string,
  selectedTeam: PropTypes.string
};

export default ComplianceCoachScorecardByRangeFilters;
