import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import HasRolePermission from "components/shared/has-role-permission";
import { formatPhone } from "utils/misc";
import useLocalQuery, {
  Table
  // NonSortableHeader
} from "hooks/useQuery/local-query";
import { MdCheck, MdClose } from "react-icons/lib/md";
import { path } from "ramda";

const CompanyBranches = ({ company }) => {
  const { rows, query, queryDispatcher } = useLocalQuery(
    company?.branches ?? [],
    {
      sort: {}
    }
  );
  return (
    <div className="company-branches">
      <div>
        <Table
          style={{ width: "100%" }}
          isFetching={false}
          containerClass="query-table dynamic-columns company-branches-table"
          TableRow={branch => (
            <tr key={branch.id}>
              <td>
                <HasRolePermission
                  allowedRoles={[
                    "Administrator",
                    "CompanyAdministrator",
                    "ServiceAdmin"
                    //,'ContractorAdministrator'
                  ]}
                  renderElse={<span>{branch.branch_name}</span>}
                >
                  <Link
                    to={`/companies/${company.id}/branch/${branch.id}/edit`}
                  >
                    {branch.branch_name}
                  </Link>
                </HasRolePermission>
              </td>
              <td>{branch.tag}</td>
              <td>{branch.account_number}</td>
              <td>{formatPhone(branch.contact_number)}</td>
              <td>
                {`${path(["address", "street_address"], branch)} 
                        ${path(["address", "city"], branch)}, 
                        ${path(["address", "state"], branch)} 
                        ${path(["address", "zip"], branch)}`}
              </td>
              <td>{formatPhone(branch.outbound_phone_number)}</td>
              <td>{branch.patients}</td>
              <td>
                {branch.active ? (
                  <MdCheck color="green" />
                ) : (
                  <MdClose color="red" />
                )}
              </td>
            </tr>
          )}
          columns={[
            {
              header: "Branch Name",
              field: "branch_name"
            },
            {
              header: "Branch Tag",
              field: "tag",
              style: { width: 160 }
            },
            {
              header: "Nonfulfillment Account #",
              field: "",
              style: { width: 160 }
            },
            {
              header: "Contact Number",
              field: "contact_number",
              style: { width: 160 }
            },
            {
              header: "Address",
              field: "street_address"
            },
            {
              header: "Outbound Phone Number",
              field: "outbound_phone_number"
            },
            {
              header: "Patients",
              field: "patients"
            },
            {
              header: "Active",
              field: "active"
            }
          ]}
          query={query}
          queryDispatcher={queryDispatcher}
          rows={rows}
          rowKey="id"
        />
      </div>
      <HasRolePermission
        // allowedRoles={["Administrator", "CompanyAdministrator", "ServiceAdmin", "ContractorAdministrator"]}
        allowedRoles={["Administrator", "CompanyAdministrator", "ServiceAdmin"]}
      >
        <Link to={`/companies/${company.id}/branch/add`}>
          <button className="button secondary" style={{ marginTop: 20 }}>
            Add Branch
          </button>
        </Link>
        <Link to={`/companies/${company.id}/branch-logs`}>
          <button className="button secondary" style={{ marginTop: 20, marginLeft: 20 }}>
            Branch Logs
          </button>
        </Link>
      </HasRolePermission>
    </div>
  );
};

CompanyBranches.propTypes = {
  company: PropTypes.object.isRequired
};

export default CompanyBranches;
