import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectors } from "reducers";
import Icons from "./icons";
import HasRolePermission from "components/shared/has-role-permission";
import { browserHistory } from "browser-history";
import { MdEventNote } from "react-icons/lib/md";

const Footer = ({ openPhone }) => {
  const user = useSelector(selectors.getUser);
  return (
    <div className="user-actions">
      <div className="user-action" title="Open Phone" onClick={openPhone}>
        <Icons.Call />
      </div>
      <HasRolePermission
        allowedRoles={[
          "Administrator",
          "SleepCoach",
          "ServiceCoach",
          "ServiceAdmin",
          "ContractorAdministrator"
        ]}
        renderElse={
          user.compliance_coach ? (
            <div
              className="user-action"
              title="Go To Voicemails"
              onClick={() => {
                browserHistory.push("/voicemails");
              }}
            >
              <Icons.Recording />
            </div>
          ) : null
        }
      >
        <div
          className="user-action"
          title="Go To Voicemails"
          onClick={() => {
            browserHistory.push("/voicemails");
          }}
        >
          <Icons.Recording />
        </div>
      </HasRolePermission>
      <HasRolePermission allowedRoles={["Administrator"]}>
        <div
          className="user-action"
          title="Go To Emails"
          onClick={() => {
            browserHistory.push("/emails");
          }}
        >
          <Icons.MdMail />
        </div>
      </HasRolePermission>
      <HasRolePermission
        allowedRoles={["Administrator", "ServiceAdmin"]}
        renderElse={
          user.compliance_coach ? (
            <div
              className="user-action"
              title="Go To First Time Registrations"
              onClick={() => {
                browserHistory.push("/first-time-registration-dashboard");
              }}
            >
              <MdEventNote />
            </div>
          ) : null
        }
      >
        <div
          className="user-action"
          title="Go To First Time Registrations"
          onClick={() => {
            browserHistory.push("/first-time-registration-dashboard");
          }}
        >
          <MdEventNote />
        </div>
      </HasRolePermission>
    </div>
  );
};

export default Footer;

Footer.propTypes = {
  openPhone: PropTypes.func.isRequired,
  vmCountSinceLastPageVisit: PropTypes.number
};
Footer.contextTypes = {
  router: PropTypes.object
};
