import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import OrderProfileNewProvider from "./context";
import OrderPanel from "./order-panel";
import OrderHeader from "./order-header";
import OrderInformation from "./order-information";
import OrderAnswers from "./order-answers";
import OrderAccessLogs from "./access-logs";
import OrderChangeLogs from "./change-logs";
import PackingSlipHeader from "./packing-slip-header";
import ShippingDetails from "./shipping-details";
import ReplacementOrder from "./replacement-order";
import OrderLineItems from "./order-line-items";
import AddOrderLineItems from "./add-order-line-items";
import SendToFulfillment from "./send-to-fulfillment";
import BarcodeDisplay from "./barcode-display";
import Tracking from "./tracking";

const OrderProfileNew = props => {
  const params = useParams();
  const orderId = props.orderId ?? params.orderId;
  const orderRef = React.useRef();
  const [showNotes, setShowNotes] = React.useState(false);
  return (
    <OrderProfileNewProvider orderId={orderId}>
      <div className="order-profile-container" ref={orderRef}>
        <OrderPanel orderId={orderId} />
        <div className="order-profile">
          <div className="order-profile-page">
            <OrderHeader
              orderId={orderId}
              orderRef={orderRef}
              setShowNotes={setShowNotes}
            />
            <OrderInformation orderId={orderId} />
            <OrderAnswers orderId={orderId} />
          </div>
          <footer style={{ padding: "50px" }}>
            1/{showNotes ? 3 : 2} pages
          </footer>
          <div className="order-profile-page">
            <PackingSlipHeader orderId={orderId} />
            <ShippingDetails orderId={orderId} />
            <ReplacementOrder orderId={orderId} />
            <SendToFulfillment orderId={orderId} />
            <OrderLineItems orderId={orderId} />
            <AddOrderLineItems orderId={orderId} />
            <Tracking orderId={orderId} />
            <BarcodeDisplay orderId={orderId} />
            {/* {canChangeQuantities && <AddLineItem orderId={orderId} />} */}
          </div>
          <footer style={{ padding: "50px" }}>
            2/{showNotes ? 3 : 2} pages
          </footer>
          <div style={{ display: showNotes ? "block" : "none" }}>
            <div className="order-profile-page">
              {/* <OrderNotes orderId={orderId} readOnly /> */}
            </div>
            <footer style={{ padding: "50px" }}> 3/3 pages</footer>
          </div>
          <div className="no-print">
            <div className="order-profile-page">
              <h1>Logs</h1>
              <OrderChangeLogs orderId={orderId} />
              <OrderAccessLogs orderId={orderId} />
            </div>
          </div>
        </div>
      </div>
    </OrderProfileNewProvider>
  );
};

export default OrderProfileNew;

OrderProfileNew.propTypes = {
  orderId: PropTypes.string
};
