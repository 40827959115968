import React, { useEffect } from "react";
import { useFetch } from "hooks";
import { get } from "utils/api";
import { Link, useParams } from "react-router-dom";
import { path } from "ramda";
import InlineWaiting from "../../ui/inline-waiting";
import moment from "moment";

export const CompanyBranchChangeLog = () => {
  const { id } = useParams();

  const {
    response: changeLogs,
    fetchData: getChangeLogs,
    isFetching: isFetching
  } = useFetch({
    defaultValue: [],
    apiFn: () => get(`companies/${id}/branch-logs`),
    transformError: path(["response", "body", "status"])
  });

  useEffect(() => {
    getChangeLogs(id);
  }, []);



  return (
    <>
      {!isFetching ? (
        <>
          <div className="company-logs-page">
            <header className="company-logs-header">
              <div className="log-header-title">
                <h1>
                  <span className="page-title">
                    {changeLogs && changeLogs[0] && changeLogs[0].company_name} Branch Change Logs
                  </span>
                </h1>
            <Link to={`/companies/${id}`}>
              <button className="button" style={{ marginLeft: "10px" }}>
                Back to Company Profile
              </button>
            </Link>
              </div>
            </header>
            <div className="company-log-container">
              <table style={{width: "80%"}}>
                <thead>
                  <tr>
                    <th>Changed By</th>
                    <th>Changed Date</th>
                    <th>Branch Office</th>
                    <th>Column</th>
                    <th>Changed From</th>
                    <th>Changed To</th>
                  </tr>
                </thead>
                <tbody>
                  {/* TODO use unique key */}
                  {changeLogs.map((log, index) => (
                    <tr key={index}>
                      <td>{log.changer_name}</td>
                      <td>
                        {moment(log.time_stamp).format("MMM DD, YYYY hh:mm A")}
                      </td>
                      <td>{log.branch_office}</td>
                      <td>{log.column_name}</td>
                      <td>{log.old_value}</td>
                      <td>{log.new_value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <div className="logs-waiting-container">
          <InlineWaiting />
        </div>
      )}
    </>
  );
};
