import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { selectors } from "reducers";
import {
  InlineWaiting,
  withModal,
  ConfirmationDialog,
  PureModal
} from "components/ui";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import PhoneNumberField from "components/forms/phone-number-field";
import MultiSelectDropdownCompanies from "components/forms/multi-select-dropdown-admin-companies";
import MultiSelectDropdown from "components/forms/multi-select-dropdown";
import HasRolePermission from "components/shared/has-role-permission";
import * as R from "ramda";
import { isValidPhone, isValidEmail } from "utils/redux-form";
import {
  asyncValidateUniqueEmail,
  asyncUserHasAssignedPatients
} from "utils/form-validation";
import { browserHistory } from "browser-history";
import * as Yup from "yup";
import moment from "moment";
import { AvailableDays } from "./available-days/AvailableDays";

import { useFetch } from "hooks";
import { get } from "utils/api";
import { propOr } from "ramda";

const convertTimeTo24HourFormat = time => {
  if (time == "") return "";
  return moment(time, "hh:mm a").format("HH:mm");
};

export const transformUserFormValues = formValues => {
  const AvailableDaysToArray = availableDays => {
    let availableDaysArray = [];

    for (let key of Object.keys(availableDays)) {
      if (!availableDays[key].active) continue;

      let formattedDay = {
        day: availableDays[key].dayInNumber,
        start: convertTimeTo24HourFormat(availableDays[key].start),
        end: convertTimeTo24HourFormat(availableDays[key].end, "HH:mm"),
        lunchStart:
          availableDays[key].lunch_start == ""
            ? null
            : convertTimeTo24HourFormat(
                availableDays[key].lunch_start,
                "HH:mm"
              ),
        lunchEnd:
          availableDays[key].lunch_end == ""
            ? null
            : convertTimeTo24HourFormat(availableDays[key].lunch_end, "HH:mm"),
        active: true
      };

      availableDaysArray.push(formattedDay);
    }

    return availableDaysArray;
  };
  const isS3User = ["Administrator", "SleepCoach"].includes(formValues.role);
  const transformedValues = {
    accessible_companies: formValues.accessible_companies || [],
    require_order_review:
      formValues.role === "SleepCoach"
        ? formValues.require_order_review
        : undefined,
    hire_date: isS3User ? formValues.hire_date : undefined,
    employee_id: isS3User ? formValues.employee_id : undefined,
    appointment_config: {
      ...formValues.appointment_config,
      appointment_days: AvailableDaysToArray(formValues.appointment_config.days)
    }
  };
  return { ...formValues, ...transformedValues };
};

const UserForm = ({
  user,
  companies,
  teamOptions,
  handleSubmit,
  openModal,
  closeModal
}) => {
  const dispatch = useDispatch();
  const [topicToRemove, setTopicToRemove] = useState(null);
  const appointment_config_days = user?.appointment_config?.days ?? [];

  const dayValidationSchema = () =>
    Yup.object().shape({
      start: Yup.string().when("active", {
        is: true,
        then: schema =>
          schema
            .required("If active, start value is required")
            .test(
              "checkStartBeforeEnd",
              "Start time must be previous to end ",
              function (value) {
                return value < this.parent.end;
              }
            ),
        otherwise: schema => schema.notRequired()
      }),
      end: Yup.string().when("active", {
        is: true,
        then: schema => schema.required("If active, end value is required"),
        otherwise: schema => schema.notRequired()
      })
    });

  const validationSchema = Yup.lazy(values => {
    return Yup.object().shape({
      email: Yup.string()
        .required("Required")
        .test("checkValidEmail", "Invalid email", email => !isValidEmail(email))
        .test("checkDuplEmail", "Email already used", async email => {
          return !(await asyncValidateUniqueEmail(email, user.id));
        }),
      role: Yup.string().required("Required"),
      first_name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      company_id: Yup.string().test("company_required", "Required", val => {
        if (["Administrator", "SleepCoach"].includes(values.role)) {
          return true;
        } else return !!val;
      }),
      phone_number: Yup.string().test(
        "valid-number",
        "Invalid phone number",
        val => !isValidPhone(val)
      ),
      can_schedule_appointments: Yup.boolean(),
      can_be_scheduled: Yup.boolean(),
      appointment_config: Yup.object().shape({
        appointment_topics: Yup.array().notRequired(),
        days: Yup.object().shape({
          Monday: dayValidationSchema("Monday"),
          Tuesday: dayValidationSchema("Tuesday"),
          Wednesday: dayValidationSchema("Wednesday"),
          Thursday: dayValidationSchema("Thursday"),
          Friday: dayValidationSchema("Friday"),
          Saturday: dayValidationSchema("Saturday"),
          Sunday: dayValidationSchema("Sunday")
        })
      })
    });
  });

  const getInitialLunchEnd = dayValue => {
    let initialLunchEnd = "";
    const start = appointment_config_days.find(
      d => d.day === dayValue
    )?.lunch_start;

    if (start) {
      const startTime = moment(start, "hh:mm a");
      const plus30 = startTime.clone().add(30, "minutes").format("HH:mm");
      const plus60 = startTime.clone().add(60, "minutes").format("HH:mm");
      const end = convertTimeTo24HourFormat(
        appointment_config_days.find(d => d.day === dayValue)?.lunch_end ?? ""
      );
      if (end !== plus30 && end !== plus60) initialLunchEnd = plus30;
      else initialLunchEnd = end;
    }
    return initialLunchEnd;
  };

  const initialValues = {
    role: R.propOr("", "role", user),
    first_name: R.propOr("", "first_name", user),
    last_name: R.propOr("", "last_name", user),
    email: R.propOr("", "email", user),
    active: R.propOr(true, "active", user),
    mfa_enabled: R.propOr(false, "mfa_enabled", user),
    call_group: R.propOr(R.propOr("", "team_name", user), "call_group", user),
    extension: R.propOr("", "extension", user),
    phone_number: R.propOr("", "phone_number", user),
    can_make_calls: R.propOr(false, "can_make_calls", user),
    can_be_emailed_from_patient_profile: R.propOr(
      false,
      "can_be_emailed_from_patient_profile",
      user
    ),
    contracted_employee: R.propOr(false, "contracted_employee", user),
    compliance_coach: user?.compliance_coach ?? false,
    chat_capable: R.propOr("", "chat_capable", user),
    spanish_speaker: R.propOr("", "speaks_spanish", user),
    require_order_review: R.propOr(false, "require_order_review", user),
    hire_date: R.pipe(R.propOr("", "hire_date"), hire_date =>
      hire_date ? moment(hire_date).format("YYYY-MM-DD") : ""
    )(user),
    employee_id: R.propOr("", "employee_id", user),
    company_id: R.pathOr("", ["company", "key"], user),
    require_ip_approval: user?.require_ip_approval,
    accessible_companies: R.pipe(
      R.pathOr([], ["accessible_companies"]),
      R.reject(({ value }) => R.isNil(value)),
      R.map(R.prop("key"))
    )(user),
    password: "",
    password_confirmation: "",
    topic_meeting: R.pathOr("", ["company", "topic_meeting"], user),
    appointment_config: {
      allow_weekend_appointment:
        user?.appointment_config?.allow_weekend_appointment ?? false,
      can_schedule_appointments:
        user?.appointment_config?.can_schedule_appointments ?? false,
      can_be_scheduled: user?.appointment_config?.can_be_scheduled ?? false,
      appointment_topics: R.pipe(
        R.pathOr([], ["appointment_topics"]),
        R.reject(({ value }) => R.isNil(value)),
        R.map(R.prop("value"))
      )(user.appointment_config),
      days: {
        Monday: {
          day: "Monday",
          dayInNumber: 1,
          active:
            appointment_config_days.find(d => d.day === 1)?.active ?? false,
          start: convertTimeTo24HourFormat(
            appointment_config_days.find(d => d.day === 1)?.start ?? ""
          ),
          end: convertTimeTo24HourFormat(
            appointment_config_days.find(d => d.day === 1)?.end ?? ""
          ),
          lunch_start: convertTimeTo24HourFormat(
            appointment_config_days.find(d => d.day === 1)?.lunch_start ?? ""
          ),
          lunch_end: getInitialLunchEnd(1)
        },
        Tuesday: {
          day: "Tuesday",
          dayInNumber: 2,
          active:
            appointment_config_days.find(d => d.day === 2)?.active ?? false,
          start: convertTimeTo24HourFormat(
            appointment_config_days.find(d => d.day === 2)?.start ?? ""
          ),
          end: convertTimeTo24HourFormat(
            appointment_config_days.find(d => d.day === 2)?.end ?? ""
          ),
          lunch_start: convertTimeTo24HourFormat(
            appointment_config_days.find(d => d.day === 2)?.lunch_start ?? ""
          ),
          lunch_end: getInitialLunchEnd(2)
        },
        Wednesday: {
          day: "Wednesday",
          dayInNumber: 3,
          active:
            appointment_config_days.find(d => d.day === 3)?.active ?? false,
          start: convertTimeTo24HourFormat(
            appointment_config_days.find(d => d.day === 3)?.start ?? ""
          ),
          end: convertTimeTo24HourFormat(
            appointment_config_days.find(d => d.day === 3)?.end ?? ""
          ),
          lunch_start: convertTimeTo24HourFormat(
            appointment_config_days.find(d => d.day === 3)?.lunch_start ?? ""
          ),
          lunch_end: getInitialLunchEnd(3)
        },
        Thursday: {
          day: "Thursday",
          dayInNumber: 4,
          active:
            appointment_config_days.find(d => d.day === 4)?.active ?? false,
          start: convertTimeTo24HourFormat(
            appointment_config_days.find(d => d.day === 4)?.start ?? ""
          ),
          end: convertTimeTo24HourFormat(
            appointment_config_days.find(d => d.day === 4)?.end ?? ""
          ),
          lunch_start: convertTimeTo24HourFormat(
            appointment_config_days.find(d => d.day === 4)?.lunch_start ?? ""
          ),
          lunch_end: getInitialLunchEnd(4)
        },
        Friday: {
          day: "Friday",
          dayInNumber: 5,
          active:
            appointment_config_days.find(d => d.day === 5)?.active ?? false,
          start: convertTimeTo24HourFormat(
            appointment_config_days.find(d => d.day === 5)?.start ?? ""
          ),
          end: convertTimeTo24HourFormat(
            appointment_config_days.find(d => d.day === 5)?.end ?? ""
          ),
          lunch_start: convertTimeTo24HourFormat(
            appointment_config_days.find(d => d.day === 5)?.lunch_start ?? ""
          ),
          lunch_end: getInitialLunchEnd(5)
        },
        Saturday: {
          day: "Saturday",
          dayInNumber: 6,
          active:
            (user?.appointment_config?.allow_weekend_appointment &&
              appointment_config_days.find(d => d.day === 6)?.active) ??
            false,
          start: convertTimeTo24HourFormat(
            appointment_config_days.find(d => d.day === 6)?.start ?? ""
          ),
          end: convertTimeTo24HourFormat(
            appointment_config_days.find(d => d.day === 6)?.end ?? ""
          ),
          lunch_start: convertTimeTo24HourFormat(
            appointment_config_days.find(d => d.day === 6)?.lunch_start ?? ""
          ),
          lunch_end: getInitialLunchEnd(6)
        },
        Sunday: {
          day: "Sunday",
          dayInNumber: 0,
          active:
            (user?.appointment_config?.allow_weekend_appointment &&
              appointment_config_days.find(d => d.day === 0)?.active) ??
            false,
          start: convertTimeTo24HourFormat(
            appointment_config_days.find(d => d.day === 0)?.start ?? ""
          ),
          end: convertTimeTo24HourFormat(
            appointment_config_days.find(d => d.day === 0)?.end ?? ""
          ),
          lunch_start: convertTimeTo24HourFormat(
            appointment_config_days.find(d => d.day === 0)?.lunch_start ?? ""
          ),
          lunch_end: getInitialLunchEnd(0)
        }
      }
    }
  };

  const [isSAASCompany, setIsSAASCompany] = React.useState();
  const { response: topic_categories, fetchData: fetchTopicCategories } =
    useFetch({
      apiFn: () => get("dme_portal/appointment_topic_categories"),
      defaultValue: [],
      transformResponse: propOr([], "topic_categories")
    });

  useEffect(() => {
    fetchTopicCategories();
  }, []);

  const {
    response: companyAllowsWeekends,
    fetchData: fetchCompanyAllowsWeekends
  } = useFetch({
    apiFn: companyId => get(`companies/${companyId}/appointments-config`),
    defaultValue: null
  });

  const shouldNotifyUserBeforeRemovingTopic = value => {
    const hasActiveAppointments =
      user.appointment_config.appointment_topics.find(x => x.value == value);

    if (hasActiveAppointments?.HasActiveAppointments)
      document.getElementById("confirm-topic-change").click();
    else document.getElementById("remove-topic").click();
  };

  useEffect(() => {
    if (topicToRemove) shouldNotifyUserBeforeRemovingTopic(topicToRemove);
  }, [topicToRemove]);

  return (
    <Formik
      initialValues={initialValues}
      initialTouched={{
        role: true,
        first_name: true,
        last_name: true,
        phone_number: true,
        company_id: true,
        email: true
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        /* edit user and deactivating user */
        const transformedValues = transformUserFormValues(values);
        if (user.id && user.active && !values.active) {
          const currentlyAssignedPatients = await asyncUserHasAssignedPatients(
            user.id
          );
          if (currentlyAssignedPatients > 0) {
            setSubmitting(false);
            openModal(
              <ConfirmationDialog
                onCancel={() => {
                  closeModal();
                }}
                warningMsg={`Coach currently has ${currentlyAssignedPatients} assigned patients`}
                continueMsg="Unassign all patients and continue?"
                onConfirm={() => {
                  closeModal();
                  setSubmitting(true);
                  handleSubmit({ ...values, assign_patients_to: null });
                }}
              />
            );
          } else {
            await handleSubmit(transformedValues);
          }
        } else {
          await handleSubmit(transformedValues);
        }

        dispatch({
          type: "SET_USER_SCHEDULER_DATA",
          payload: {
            can_schedule:
              transformedValues.appointment_config.can_schedule_appointments,
            can_be_scheduled:
              transformedValues.appointment_config.can_be_scheduled
          }
        });
      }}
      enableReinitialize={true}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        isValid,
        isSubmitting,
        errors
      }) => {
        const handleLunchStartChanged = (value, dayKey) => {
          setFieldValue(
            `appointment_config.days[${dayKey}].lunch_start`,
            value
          );
          setFieldValue(
            `appointment_config.days[${dayKey}].lunch_end`,
            value
              ? moment(value, "HH:mm").add(30, "minutes").format("HH:mm")
              : ""
          );
        };

        const defaultAvailableDays = async () => {
          let days = values.appointment_config.days;
          Object.keys(days).map(dayKey => {
            if (
              days[dayKey].dayInNumber !== 6 &&
              days[dayKey].dayInNumber !== 0
            ) {
              setFieldValue(`appointment_config.days[${dayKey}].active`, true);

              setFieldValue(
                `appointment_config.days[${dayKey}].start`,
                "08:00"
              );
              setFieldValue(`appointment_config.days[${dayKey}].end`, "17:00");
              setFieldValue(
                `appointment_config.days[${dayKey}].lunch_start`,
                "12:00"
              );
              setFieldValue(
                `appointment_config.days[${dayKey}].lunch_end`,
                "12:30"
              );
            }
          });
        };

        useEffect(() => {
          if (values.appointment_config.can_be_scheduled) {
            let shouldDefaultAvailableDays = true;
            Object.values(values.appointment_config.days).forEach(day => {
              if (day.active) {
                shouldDefaultAvailableDays = false;
              }
            });
            if (shouldDefaultAvailableDays) {
              defaultAvailableDays();
            }
          }
        }, [values.appointment_config.can_be_scheduled]);

        return (
          <Form>
            <HasRolePermission allowedRoles={["Administrator"]}>
              <fieldset>
                <legend>Type</legend>
                <FormikField
                  name="role"
                  component="select"
                  onChange={e => {
                    handleChange(e);
                    if (
                      //  dme to s3 user
                      ["Administrator", "SleepCoach"].includes(
                        e.target.value
                      ) &&
                      !["Administrator", "SleepCoach"].includes(values.role)
                    ) {
                      setFieldValue("call_group", "");
                      setFieldValue("company_id", "");
                      setFieldValue("accessible_companies", []);
                    }
                  }}
                >
                  <option />
                  <option value="SleepCoach">Sleep Coach</option>
                  <option value="Administrator">Administrator</option>
                  <option value="CompanyAdministrator">
                    Company Administrator
                  </option>
                  <option value="OfficeAdministrator">
                    Office Administrator
                  </option>
                  {/* <option value="ComplianceCoach">Compliance Coach</option> */}
                </FormikField>
              </fieldset>
            </HasRolePermission>
            <HasRolePermission allowedRoles={["ServiceAdmin", "Constractor"]}>
              <fieldset>
                <legend>Type</legend>
                <FormikField
                  name="role"
                  component="select"
                  onChange={e => {
                    handleChange(e);
                    if (
                      e.target.value === "OfficeAdministrator" ||
                      e.target.value === "ContractorAdministrator"
                    )
                      setFieldValue("can_make_calls", true);
                    else setFieldValue("can_make_calls", false);
                  }}
                >
                  <option />
                  <option value="CompanyAdministrator">
                    Company Administrator
                  </option>
                  <option value="OfficeAdministrator">SleepCoach</option>
                </FormikField>
              </fieldset>
            </HasRolePermission>
            <fieldset>
              <legend>Details</legend>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gridGap: 30
                }}
              >
                <FormikField
                  name="first_name"
                  label="First Name *"
                  type="text"
                />
                <FormikField name="last_name" label="Last Name *" type="text" />
              </div>
              <React.Fragment>
                <FormikField
                  name="company_id"
                  label="Company *"
                  component="select"
                  onChange={({ target }) => {
                    if (target.value !== values.company_id) {
                      const company = R.find(
                        ({ GUID }) => GUID === target.value
                      )(companies);
                      const software_as_service = R.propOr(
                        false,
                        "software_as_service",
                        company
                      );
                      // remove assigned team when moving user from software_as_service company to non software_as_service company
                      if (!software_as_service && isSAASCompany) {
                        setFieldValue("call_group", "");
                        setFieldValue("chat_capable", false);
                      }
                      setIsSAASCompany(software_as_service);

                      const res = fetchCompanyAllowsWeekends(target.value);
                      setFieldValue(
                        "appointment_config.allow_weekend_appointment",
                        res
                      );
                    }
                    setFieldValue("company_id", target.value);
                  }}
                >
                  <option />
                  {companies.map(({ text, value }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
                </FormikField>
                <label style={{ marginBottom: 10 }}>
                  <div style={{ display: "flex", flex: 1 }}>
                    <span style={{ flex: 1 }}>Accessible Companies</span>
                    {errors.accessible_companies && (
                      <span
                        style={{ justifyContent: "flex-end", color: "red" }}
                        className="form-field-error"
                      >
                        {errors.accessible_companies}
                      </span>
                    )}
                  </div>
                  <MultiSelectDropdownCompanies
                    searchable
                    onChange={value => {
                      setFieldValue("accessible_companies", value);
                    }}
                    onClear={() => setFieldValue("accessible_companies", [])}
                    values={values.accessible_companies}
                    options={companies}
                  />
                </label>
              </React.Fragment>
              <FormikField name="email" label="Email Address *" type="email" />
              <FormikField
                name="phone_number"
                label="Phone Number"
                type="tel"
                component={PhoneNumberField}
              />
              <HasRolePermission
                allowedRoles={[
                  "Administrator",
                  "ServiceAdmin",
                  "ContractorAdministrator"
                ]}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridGap: 20
                  }}
                >
                  <FormikField
                    label="Password"
                    name="password"
                    maxLength="128"
                    size="50"
                    type="password"
                  />
                  <FormikField
                    label="Password Confirmation"
                    name="password_confirmation"
                    size="50"
                    type="password"
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridGap: 10,
                    gridTemplateColumns: "100px 150px 200px 1fr 1fr"
                  }}
                >
                  <FormikField
                    label="Active"
                    name="active"
                    type="checkbox"
                    checked={values.active}
                  />
                  <FormikField
                    label="Two-Factor Auth"
                    name="mfa_enabled"
                    type="checkbox"
                    checked={values.mfa_enabled}
                  />
                  {["SleepCoach"].includes(values.role) &&
                    !user.admin_elevated && (
                      <FormikField
                        label="Require Order Review"
                        name="require_order_review"
                        type="checkbox"
                        checked={values.require_order_review}
                      />
                    )}
                  {["SleepCoach", "Administrator"].includes(values.role) && (
                    <FormikField
                      label="Hire Date"
                      name="hire_date"
                      type="date"
                    />
                  )}
                  {["SleepCoach", "Administrator"].includes(values.role) && (
                    <FormikField
                      label="Employee Id"
                      name="employee_id"
                      type="text"
                    />
                  )}
                </div>
              </HasRolePermission>
            </fieldset>
            <HasRolePermission
              allowedRoles={[
                "Administrator",
                "ServiceAdmin",
                "ContractorAdministrator"
              ]}
            >
              <fieldset>
                <legend>Voip Settings</legend>
                {(["Administrator", "SleepCoach"].includes(values.role) ||
                  user.admin_elevated ||
                  isSAASCompany) && (
                  <FormikField
                    label="Team"
                    name="call_group"
                    component="select"
                  >
                    <option />
                    {teamOptions.map(({ text, value }) => (
                      <option key={value} value={value}>
                        {text}
                      </option>
                    ))}
                  </FormikField>
                )}
                <FormikField name="extension" label="Extension" type="number" />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <FormikField
                    label="Access To S3 Phone"
                    name="can_make_calls"
                    type="checkbox"
                    checked={values.can_make_calls}
                  />
                  <FormikField
                    label="Can Be Emailed From Patient Profile"
                    name="can_be_emailed_from_patient_profile"
                    type="checkbox"
                    checked={values.can_be_emailed_from_patient_profile}
                  />
                  <FormikField
                    label="Contracted Employee"
                    name="contracted_employee"
                    type="checkbox"
                    checked={values.contracted_employee}
                    style={{ marginLeft: 50 }}
                  />
                  <FormikField
                    label="Compliance Coach"
                    name="compliance_coach"
                    type="checkbox"
                    checked={values.compliance_coach}
                    style={{ marginLeft: 50 }}
                  />
                  {(["Administrator", "SleepCoach"].includes(values.role) ||
                    user.admin_elevated ||
                    isSAASCompany) && (
                    <FormikField
                      label="Text Chat Enabled"
                      name="chat_capable"
                      type="checkbox"
                      checked={values.chat_capable}
                      style={{ marginLeft: 50 }}
                    />
                  )}
                  {(["Administrator", "SleepCoach"].includes(values.role) ||
                    user.admin_elevated ||
                    isSAASCompany) && (
                    <FormikField
                      label="Spanish Speakeer"
                      name="spanish_speaker"
                      type="checkbox"
                      checked={values.spanish_speaker}
                      style={{ marginLeft: 50 }}
                    />
                  )}
                  {["SleepCoach"].includes(values.role) && (
                    <FormikField
                      label="Require Ip Approval"
                      name="require_ip_approval"
                      type="checkbox"
                      checked={values.require_ip_approval}
                      style={{ marginLeft: 50 }}
                    />
                  )}
                </div>
              </fieldset>
            </HasRolePermission>
            <fieldset>
              <legend>Appointment Settings</legend>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  marginBottom: "16px"
                }}
              >
                <label>Can schedule Appointments:</label>
                <FormikField
                  name="appointment_config.can_schedule_appointments"
                  type="checkbox"
                  checked={values.appointment_config.can_schedule_appointments}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  marginBottom: "16px"
                }}
              >
                <label>Can Be Scheduled:</label>
                <FormikField
                  name="appointment_config.can_be_scheduled"
                  type="checkbox"
                  checked={values.appointment_config.can_be_scheduled}
                />
              </div>
              <label style={{ marginBottom: 10 }}>
                <div style={{ display: "flex", flex: 1 }}>
                  <span style={{ flex: 1 }}>Topics Meeting</span>
                  {errors?.appointment_config?.appointment_topics && (
                    <span
                      style={{ justifyContent: "flex-end", color: "red" }}
                      className="form-field-error"
                    >
                      {errors?.appointment_config?.appointment_topics}
                    </span>
                  )}
                </div>
                <MultiSelectDropdown
                  searchable
                  name={"appointment_config.appointment_topics"}
                  onChange={value =>
                    setFieldValue(
                      "appointment_config.appointment_topics",
                      value
                    )
                  }
                  onClear={() =>
                    setFieldValue("appointment_config.appointment_topics", [])
                  }
                  values={values.appointment_config.appointment_topics}
                  options={topic_categories.map(({ topic_name, topic_id }) => ({
                    text: topic_name,
                    value: topic_id
                  }))}
                  askBeforeRemoving={true}
                  confirmationAction={value => {
                    setTopicToRemove(value);
                  }}
                />
                <PureModal
                  maxWidth="sm"
                  fullWidth
                  renderTrigger={({ openModal }) => (
                    <div
                      id="confirm-topic-change"
                      onClick={() => {
                        openModal();
                      }}
                    >
                      {" "}
                    </div>
                  )}
                  renderContent={({ closeModal }) => (
                    <ConfirmationDialog
                      cancelButtonStyles="appointment-action-button cancel-action-button"
                      confirmButtonStyles="appointment-action-button reschedule-button"
                      continueMsg="Yes"
                      cancelMsg="No"
                      onCancel={() => {
                        setTopicToRemove(null);
                        closeModal();
                      }}
                      onConfirm={() => {
                        setFieldValue(
                          "appointment_config.appointment_topics",
                          values.appointment_config.appointment_topics.filter(
                            x => x != topicToRemove
                          )
                        );
                        setTopicToRemove(null);
                        closeModal();
                      }}
                    >
                      <div>
                        <h3>
                          You have appointments in the future with the selected
                          topic.
                        </h3>
                        <br />
                        <h4>Are you sure you want to remove the topic?</h4>
                      </div>
                    </ConfirmationDialog>
                  )}
                />
                <div
                  id="remove-topic"
                  onClick={() => {
                    setFieldValue(
                      "appointment_config.appointment_topics",
                      values.appointment_config.appointment_topics.filter(
                        x => x != topicToRemove
                      )
                    );
                    setTopicToRemove(null);
                  }}
                ></div>
              </label>
              <div>
                <AvailableDays
                  appointment_config={values.appointment_config}
                  companyAllowsWeekends={companyAllowsWeekends}
                  errors={errors}
                  onLunchStartChanged={(value, dayKey) =>
                    handleLunchStartChanged(value, dayKey)
                  }
                />
              </div>
            </fieldset>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                type="button"
                style={{ marginRight: 30 }}
                className="button warning"
                onClick={e => {
                  e.preventDefault();
                  browserHistory.goBack();
                }}
              >
                Cancel
              </button>
              {!isSubmitting ? (
                <button
                  type="submit"
                  className="button"
                  disabled={!isValid}
                  title={R.values(errors)}
                >
                  Submit
                </button>
              ) : (
                <InlineWaiting />
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

UserForm.defaultProps = {
  user: {
    role: "",
    first_name: "",
    last_name: "",
    email: "",
    active: true,
    mfa_enabled: true,
    call_group: "",
    phone_number: "",
    extension: "",
    can_make_calls: false,
    can_be_emailed_from_patient_profile: false,
    contracted_employee: false,
    compliance_coach: false
  }
};

UserForm.propTypes = {
  user: PropTypes.object,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  teamOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default withModal(
  connect(state => ({
    companies: selectors.getFormOptionsCompany(state),
    teamOptions: selectors.getTeamNameOptions(state)
  }))(UserForm)
);

{
  /*
  
  <div style={{ display: "flex" }}>
          <DatePickerInput
            placeholder={"MM/DD/YYYY"}
            value={newStartDate}
            handleDayChange={e => {
              if (canEdit) {
                setNewStartDate(moment(e).format("MM/DD/YYYY"));
                setIsValidDateRange(
                  moment(
                    `${moment(e).format("MM/DD/YYYY")} ${newStartTime}`
                  ).isBefore(moment(`${newEndDate} ${newEndTime}`))
                );
              }
            }}
            fromDate={moment().toDate()}
            //inputProps={(isExpired || !canEdit) ? { readOnly: true, disabled: true, style:{width: "90%"} } : {style:{width: "90%"}}}
          />
          <select
            style={{ width: "7rem" }}
            value={newStartTime}
            onChange={e => {
              handleNewStartTime(e.target.value);
            }}
            //disabled={isExpired || !canEdit}
          >
            {generateTimeOptions(true)}
          </select>
        </div>
        <div style={{ display: "flex" }}>
          <DatePickerInput
            placeholder={"MM/DD/YYYY"}
            value={newEndDate}
            handleDayChange={e => {
              setNewEndDate(moment(e).format("MM/DD/YYYY"));
              setIsValidDateRange(
                moment(`${newStartDate} ${newStartTime}`).isBefore(
                  moment(`${moment(e).format("MM/DD/YYYY")} ${newEndTime}`)
                )
              );
            }}
            fromDate={moment().toDate()}
            // inputProps={
            //   isExpired || !canEdit
            //     ? { readOnly: true, disabled: true, style: { width: "90%" } }
            //     : { style: { width: "90%" } }
            // }
          />
          <select
            style={{ width: "7rem" }}
            value={newEndTime}
            onChange={e => {
              setNewEndTime(e.target.value);
              setIsValidDateRange(
                moment(`${newStartDate} ${newStartTime}`).isBefore(
                  moment(`${newEndDate} ${e.target.value}`)
                )
              );
            }}
            // disabled={isExpired || !canEdit}
          >
            {generateTimeOptions()}
          </select>
        </div>
  
  
  */
}
