import React from "react";
import PropTypes from "prop-types";
import { OrderProfileContext } from "../context";
import HasRolePermission from "components/shared/has-role-permission";
import ModalLink from "components/shared/modal-link";
import { SendOrderToNHButton, UpdateOrderFromNHButton } from "../niko-health";
import { InlineWaiting } from "components/ui";
import OrderStatus from "./order-status";
import OrderActions from "./order-actions";
import PendingInfo from "./pending-info";
import ReasonRejected from "./reason-rejected";
import DelayedPendingField from "./delayed-pending-field";
import FollowUpDateField from "./follow-up-date-field";
import CopyToClipboard from "./copy-to-clipboard";
import PrintOrderBtn from "./print-order-button";
import PriorityBtn from "./order-priority-btn";
import { MdRefresh } from "react-icons/lib/md";
import InsuranceVerification from "./insurance-verification";
import TaskNotifiction from "./task-notification";
// import OrderNotes from "./order-notes";

const OrderHeader = ({ orderId, orderRef, setShowNotes }) => {
  const { order, isFetchingOrder, fetchOrderProfile, updateOrder } =
    React.useContext(OrderProfileContext);

  return (
    <div className="profile-header">
      {order?.supply_period && (
        <div className="thirty-day">{`Patient requested ${order?.supply_period}-day supplies.`}</div>
      )}
      <div className="main-header">
        <h1>
          <ModalLink to={`/patients/${order.patient_id}`}>
            {order?.patient_name}
          </ModalLink>
          {/* <OrderNotes orderId={orderId} /> */}
        </h1>
        <div className="order-action-buttons">
          <div className="no-print order-refresh-button">
            <MdRefresh
              color="grey"
              size="28px"
              onClick={() => {
                if (!isFetchingOrder) fetchOrderProfile(orderId);
              }}
            />
          </div>
          <PrintOrderBtn orderRef={orderRef} setShowNotes={setShowNotes} />
          <CopyToClipboard orderId={orderId} />
          <PriorityBtn
            high_priority={order.high_priority}
            togglePriority={() => {
              updateOrder({ high_priority: !order.high_priority });
            }}
          />
          <HasRolePermission
            allowedRoles={[
              "Administrator",
              "CompanyAdministrator",
              "OfficeAdministrator",
              "ServiceAdmin",
              "ServiceCoach",
              "ContractorAdministrator"
            ]}
            renderElse={<div className="small-3 columns" />}
          >
            {order.uses_niko_api && !isFetchingOrder && (
              <>
                {order?.niko_info?.niko_order_id === "" ? (
                  <SendOrderToNHButton
                    orderId={orderId}
                    isFetching={isFetchingOrder}
                  />
                ) : (
                  <UpdateOrderFromNHButton
                    orderId={orderId}
                    isFetching={isFetchingOrder}
                  />
                )}
              </>
            )}
          </HasRolePermission>
        </div>
      </div>
      <div className="order-status-container">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            alignItems: "center"
          }}
        >
          <OrderStatus orderId={orderId} />
          <HasRolePermission contractorCheck={true}>
            <OrderActions orderId={orderId} order={order} />
          </HasRolePermission>
          <HasRolePermission contractorCheck={true}>
            <InsuranceVerification orderId={orderId} order={order} />
            <TaskNotifiction orderId={orderId} />
          </HasRolePermission>
        </div>
        <div
          className="no-print"
          style={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            alignItems: "flex-end"
          }}
        >
          {isFetchingOrder ? (
            <InlineWaiting />
          ) : (
            <React.Fragment>
              {order.order_status === "Rejected" ||
              order.order_status === "Voided" ? (
                <div className="pending-info-container no-print">
                  <span className="subheader-text">Reason Rejected:</span>
                  <ReasonRejected
                    orderId={orderId}
                    value={order.reason_not_accepted}
                  />
                </div>
              ) : (
                <div className="pending-info-container no-print">
                  <span className="subheader-text">Pending Info:</span>
                  <PendingInfo
                    orderId={orderId}
                    pending_info={order.pending_info}
                    disabled={order.order_status === "IVR Pending Work"}
                  />
                </div>
              )}
              <DelayedPendingField />
              {!["Completed", "Rejected", "Voided"].includes(
                order.order_status
              ) && <FollowUpDateField follow_up_date={order.follow_up_date} />}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

OrderHeader.propTypes = {
  orderId: PropTypes.string,
  closeModal: PropTypes.func,
  setShowNotes: PropTypes.func.isRequired,
  orderRef: PropTypes.shape({ current: PropTypes.instanceOf(HTMLDivElement) })
    .isRequired
};

export default OrderHeader;
