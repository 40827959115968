import React from "react";
import PropTypes from "prop-types";
import { OrderProfileContext } from "./context";
import DayPicker from "react-day-picker/DayPicker";
import { Formik, Form, ErrorMessage } from "formik";
import FormikField from "components/forms/formik-field";
import { InlineWaiting } from "components/ui";
import * as Yup from "yup";
import { carrierOptions } from "utils/enum";

const UpdateTrackingForm = ({ seletedLineItems, closeModal, clearSelectedRows }) => {
  const { updateTrackingApi } = React.useContext(OrderProfileContext);

  return (
    <div style={{ padding: 5, margin: 5 }}>
      <Formik
        initialValues={{
          ship_date: "",
          carrier: "",
          tracking_number: ""
        }}
        validationSchema={Yup.object().shape({
          ship_date: Yup.string().required("Please select a ship date"),
          carrier: Yup.string().required("Please select a carrier"),
          tracking_number: Yup.string().required(
            "Please select a tracking number"
          )
        })}
        onSubmit={async values => {
          await updateTrackingApi.fetchData(
            seletedLineItems.map(x => ({
              line_item_id: x,
              ...values
            }))
          );
          clearSelectedRows();
          closeModal();
        }}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <h3>Bulk Tracking Update</h3>
            <div>
              <ErrorMessage name="ship_date" id="ship_date">
                {msg => (
                  <div
                    style={{
                      color: "red",
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end"
                    }}
                  >
                    {msg}
                  </div>
                )}
              </ErrorMessage>
              <DayPicker
                showOutsideDays
                selectedDays={values.ship_date}
                onDayClick={value => setFieldValue("ship_date", value)}
              />
            </div>
            <FormikField name="carrier" label="Carrier" component="select">
              <option />
              {carrierOptions.map(x => (
                <option key={x}>{x}</option>
              ))}
            </FormikField>

            <div>
              <FormikField
                name="tracking_number"
                label="Tracking Number"
                type="text"
              />
              <div
                style={{
                  height: 20,
                  fontWeight: "700",
                  color: "#00aa9e"
                }}
              >
                {guessCarrierCompany(values.tracking_number)}
              </div>
            </div>
            <div className="submit-container">
              {isSubmitting ? (
                <InlineWaiting />
              ) : (
                <button type="submit">Submit</button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

UpdateTrackingForm.propTypes = {
  seletedLineItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  closeModal: PropTypes.func.isRequired,
  clearSelectedRows: PropTypes.func.isRequired
};

export default UpdateTrackingForm;

const guessCarrierCompany = tracking_number => {
  if (!tracking_number) {
    return "";
  }
  let input = tracking_number.trim();
  let carrierGuess = "Undetermined Carrier";
  //FEDEX
  let fedEx1 = new RegExp("^[0-9]{20}$");
  let fedEx2 = new RegExp("^[0-9]{15}$");
  let fedEx3 = new RegExp("^[0-9]{12}$"); //might overlap with ups2
  let fedEx4 = new RegExp("^[0-9]{22}$"); //might overlap with usps1
  //UPS mine
  let ups1 = new RegExp("b(1Z)[0-9]{16}");
  let ups2 = new RegExp("b([0-9]{12})"); //might overlap with fedex3
  let ups3 = new RegExp("b[T]{1}[0-9]{10}");
  let ups4 = new RegExp("b[0-9]{9}"); //might overlap with dhl1
  let ups5 = new RegExp("b(MI)[0-9]{6}([A-z]|[0-9]){0,22}"); //Mail innovation
  //Their UPS
  let ups1b = new RegExp("^(1Z)[0-9A-Z]{16}$");
  let ups2b = new RegExp("^(T)+[0-9A-Z]{10}$");
  let ups3b = new RegExp("^[0-9]{9}$"); //Might overlap with dhl1
  let ups4b = new RegExp("^[0-9]{26}$"); //Might overlap with
  //ONTRAC
  let ontrac = new RegExp("(C|c){1}([0-9]{14})$");
  //USPS
  let usps1 = new RegExp("^(94|93|92|94|95)[0-9]{20}$"); //might overlap with fedEx4
  let usps2 = new RegExp("^(94|93|92|94|95)[0-9]{22}$");
  let usps6 = new RegExp("^(94|93|92|94|95)[0-9]{24}$"); //Might overlap with ups4b
  let usps3 = new RegExp("^(70|14|23|03)[0-9]{14}$");
  let usps4 = new RegExp("^(M0|82)[0-9]{8}$");
  let usps5 = new RegExp("^([A-Z]{2})[0-9]{9}([A-Z]{2})$");
  //DHL
  let dhl1 = new RegExp("^[0-9]{9,10}$"); //Might overlap with ups3b

  if (
    fedEx1.test(input) ||
    fedEx2.test(input) ||
    fedEx3.test(input) ||
    fedEx4.test(input)
  ) {
    if (input.length === 22 && usps1.test(input)) {
      carrierGuess = "USPS is our guess at Carrier";
    } else if (input.length === 12 && ups2.test(input)) {
      carrierGuess = "FEDEX or UPS is our guess at Carrier";
    } else {
      carrierGuess = "FEDEX is our guess at Carrier";
    }
  } else if (
    ups1.test(input) ||
    ups2.test(input) ||
    ups3.test(input) ||
    ups4.test(input) ||
    ups5.test(input) ||
    ups1b.test(input) ||
    ups2b.test(input) ||
    ups3b.test(input) ||
    ups4b.test(input)
  ) {
    if (input.length === 9 && dhl1.test(input)) {
      carrierGuess = "DHL or UPS is our guess at Carrier";
    } else if (input.length === 12 && ups2.test(input)) {
      carrierGuess = "FEDEX or UPS is our guess at Carrier";
    } else if (input.length === 26 && usps6.test(input)) {
      carrierGuess = "USPS or UPS is our guess at Carrier";
    } else {
      carrierGuess = "UPS is our guess at Carrier";
    }
  } else if (ontrac.test(input)) {
    carrierGuess = "ONTRAC is our guess at Carrier";
  } else if (
    usps1.test(input) ||
    usps2.test(input) ||
    usps3.test(input) ||
    usps4.test(input) ||
    usps5.test(input)
  ) {
    carrierGuess = "USPS is our guess at Carrier";
  } else if (dhl1.test(input)) {
    carrierGuess = "DHL or UPS is our guess at Carrier";
  } else {
    carrierGuess = "Undetermined Carrier: Choose Other";
  }
  return carrierGuess;
};
